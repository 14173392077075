
.dropdown-textfield__label{
    width:100%;
}
.autcomplete-dropdown-textfield{
    &-wrapper{
        position:relative;
    }
    &__results{
        
        width:100%;
        position: absolute;
        z-index:1;
        text-align:left;
        background-color:$color-white;
        top:72px;

        .mdc-menu{
            position: absolute;
            margin-top: -1em;
            width:100%;
            max-height:300px;
           
            
            .mdc-list-item{
                color:$color-black;
                line-height:2.75rem;
                &.selected{
                    background:$color-beige;
                }
            }
        }
    }
}



