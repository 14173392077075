.rr-scenario-list-mobile {
    width: 100%;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }

    &__title {
        padding: 1rem;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        font-size: 17px;
        margin: 1rem 0 0 0;
        color: $color-white;
        border: none;
        background-color: $color-blue-dark;
    }


    &__list {
        display: flex;
        flex-direction: column;
        padding: 2.25rem 1rem;
        border-bottom: 1px solid $color-blue-dark;
        gap: 1rem;

        &-title {
            font-weight: bolder;
            font-size: 16px;
            margin: 0 0 0 0;
            text-transform: uppercase;
        }

        &-item {
            display: flex;
            flex-direction: column;
            list-style: none;
            align-items: flex-start;
            list-style: none;
            gap: 1rem;

            &--list-title {
                list-style: none;
            }

            &-title {
                font-weight: normal;
                font-size: 16px;
                margin: 0 0 0 0;
                line-height: 2rem;
                border-bottom: 1px solid lighten($color-grey-dark, 10%);
                width: 100%;
            }

            &-span {

                &--bold {
                    font-weight: bold;
                }

                &--uppercase {
                    text-transform: uppercase;
                }

                &-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;


                    &-inner {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 0;
                    }

                }

            }

        }

    }

    &__description {
        padding: 1rem;
        line-height: 1.75rem;
    }
}

.Sparinvest {
    .rr-scenario-list-mobile {

        &__title {
            background-color: $color-teal;
        }

        &__list {
            border-bottom: 1px solid $color-teal;
        }
    }
}