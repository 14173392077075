.tk-bank-branch-map {
    display: flex;
    height:auto;

    .find-map-and-list-map {
        width:100%;
        height:100%;
    }
    .detailed-image {
        display: block;
        margin-left: auto;
        margin-right: auto; 
        width: 100%;
        padding-bottom:10px;
    }
    .overwrite-standard-padding {
       padding-right: 0px;
    }
    .overwrite-standard-background-color {
        background-color: #f7f6f4;
    }

    .overwrite-standard-button-padding {
        padding:34.5px 55px;
        left:55px;
    }
}