//Colored box
$brand-box-desktop:54px;
$brand-box-mobile:28px;

$big-box-desktop:175px;
$big-box-mobile:91px;

$small-box-desktop:120px;
$small-box-mobile:64px;

.section--text-image{
    .text{
        &__meta{
            text-transform: uppercase;
            color:$color-blue;
            font-size:15px;
            font-weight: 600;
            
            padding:40px 0 20px 0;

            @include bp-above-desktop {
                padding:0 0 20px 0;
                font-size:14px;
                line-height: 1.71;
            }
        }
    }
     //Text right
    .image-wrap{
        @include bp-above-desktop { 
            display:flex;
            justify-content: flex-end;
        }
        
        .box-wrap{
            position: relative;

            &:before,
            .image:after,
            .image:before{
                content:"";
                position: absolute;
            }
            //this is the small box in brand color
            &:before{
                bottom:-$brand-box-mobile;
                left:calc(#{$big-box-mobile} - #{$brand-box-mobile});
                width:$brand-box-mobile;
                height:$brand-box-mobile;

                @include bp-above-desktop {
                    bottom:-$brand-box-desktop;
                    left:-$brand-box-desktop;
                    width:$brand-box-desktop;
                    height:$brand-box-desktop;
                }
            }
            
            .image{
                img{

                    @include bp-above-desktop { 
                        width:auto;
                    }
                }
                //this is the middle size box
                &:before{
                    background-color: $color-blue;
                    bottom:0;
                    left:$big-box-mobile;
    
                    width:$small-box-mobile;
                    height:$small-box-mobile;
    
                    @include bp-above-desktop {
                        left:0;
                        width:$small-box-desktop;
                        height:$small-box-desktop;
                    }
    
                }
                 //This is the large box
                &:after{
                    width:$big-box-mobile;
                    height:$big-box-mobile;
                    background-color: $color-blue-dark;
                    bottom:0;
                    left:0;

                    @include bp-above-desktop {
                        width:$big-box-desktop;
                        height:$big-box-desktop;
                        left:-175px;
                        right:auto;
                    }
                }
            }
        }
        &:not(.mdc-layout-grid__cell--order-3-desktop){
            @include bp-until-desktop { 
                .box-wrap .image{
                    display:flex;
                    justify-content: flex-end;
                    img{
                        width:calc(100% - #{$big-box-mobile});
                        height:100%;
                    }
                } 
            }
        }
        //Text left
        &.mdc-layout-grid__cell--order-3-desktop{
            justify-content: flex-start;
            
            .box-wrap{
            
                //this is the small box in brand color
                &:before{
                    right:calc(#{$big-box-mobile} - #{$brand-box-mobile});
                    left:auto;
                    @include bp-above-desktop { 
                        left:auto;
                        right:-$brand-box-desktop;
                    }
                }
                
                .image{
                    //this is the middle size box
                    &:before{
                        left:auto;
                        right:$big-box-mobile;
                        @include bp-above-desktop {
                            left:auto;
                            right:0;
                        }
                    }
                    //This is the large box
                    &:after{
                        left:auto;
                        right:0;
                        @include bp-above-desktop {
                            left:auto;
                            right:-$big-box-desktop;
                        }
                    }
                }
            }
        }

        + .mdc-layout-grid__cell--span-0-phone{
            @include bp-until-desktop{
                display:none;
            }
        }
    }
    .image{
        &-wrap{
            &--primary{
                .box-wrap:before{
                    background-color:$color-turquoise;
                }
            }
            &--secondary{
                .box-wrap:before{
                    background-color: $mdc-theme-secondary;
                }
            }
        }
    }
    .stack-spacer {
        margin-bottom: 1.5rem;
    }
}
