
$desktop-burger-menu-breakpoint : 1440px;
$tablet-burger-menu-breakpoint : 1080px;
$mobile-burger-menu-breakpoint : 720px;

.nyk-burger-menu-secondary {
    width: 100%;
    //max-width: 1920px;
    margin: 0 auto;
    background-color: $color-white; 

    &--fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 200;
        transform: translate(0px, -101%);
    }

    &--scroll-up {
        top: 0;
        transition: transform .5s ease-in-out;
        transform: translate(0, 0);        
    }

    button * {
        pointer-events: auto;
    }

    .toolbar-primary__nav-button:after {
        bottom: 1px;
        left: 0;
        width: 100%;

        @media (max-width:$mobile-burger-menu-breakpoint) {
            opacity: 0;
        }
    }

    &__outer-container {
        display: flex;
        position: relative;
        width: 100%;

        @media (max-width:$desktop-burger-menu-breakpoint) {
            flex-direction: row-reverse;
        }
    }

    &__burger-button-container{
            z-index: 1000;
            position: relative;

            @media (max-width:$tablet-burger-menu-breakpoint) {
                z-index: 1000;
            }
    }

    &__burger-button {
        height: 80px;
        width: 80px;
        background-color: $color-red; 
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:$tablet-burger-menu-breakpoint) {
            margin-top: 0;
            height: 60px;
            width: 60px;
        }

        &:hover {
            background-color: $color-red; 

            span {
                &:after {
                    width: 14px;
                }

                &:before {
                    width: 26px;
                }

                span {
                    width: 26px;
                }
            }
        }

        &--layer {
            background-color: $color-grey-white;

            svg.icon {
                color: $color-blue-dark;
                stroke-width: 4px;
            }
        }
    }

    &__button-container {
        position: relative;
        z-index: 900;
    }

    &__main-container {
        left: -40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        justify-content: center;
        z-index: 800;

        @media (max-width:$desktop-burger-menu-breakpoint) {
            left:5px;
        }
    }

    &__main-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        max-width: 1280px;
        width: 100%;

        @media (max-width:$mobile-burger-menu-breakpoint) {
            height: 110px;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }

    &__logo-container {
        padding: 0 40px;
        padding-left: 0;
        min-width: 150px;

        @media (max-width:$desktop-burger-menu-breakpoint) {
            padding: 0 30px;

            .toolbar__logo {
                left: 80px;

                @media (max-width:$desktop-burger-menu-breakpoint) {
                    left: 70px;
                }
            }

            .toolbar__logo--large {
                @media (max-width: 330px) {
                    
                    left: 60px;    
                    img {
                        width: 80px;
                    }
                }
            }
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            min-width: 100px;
        }
    }

    &__quick-links-container {
        padding: 0 20px;

        li {
            list-style-type: none;
        }

        @media (max-width:$tablet-burger-menu-breakpoint) {
            margin-top: -6px;
            padding: 0 20px 0 10px;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            margin-top: 0;
            padding: 10px 18px 0 10px;
        }
    }

    &__quick-links {
        display: flex;

        .toolbar-primary__nav-button div {
            margin-top: 0;
            min-width: unset;

            @media (max-width:$desktop-burger-menu-breakpoint) {
                display: block;
            }
        }

        .nyk-burger-menu-secondary__quick-link-item {
            padding: 10px 0 0 0;
            @media (max-width:$mobile-burger-menu-breakpoint) {
                padding: 14px 0 0 0;
            }
        }
    }

    &__quick-link-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin: 0 18px;

        @media (max-width:$tablet-burger-menu-breakpoint) {
            margin: 0 13px;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            display: none
        }

        &--show-mobile {
            @media (max-width:$mobile-burger-menu-breakpoint) {
                display: flex
            }
        }
    }

    &__quick-link-text {
        color: $color-blue-dark;
        font-size: 14px;
        padding-top: 10px;
        margin-bottom: 0;

        @media (max-width:$tablet-burger-menu-breakpoint) {
            display: none;
        }
    }

    &__quick-link-icon-container {
        height: 24px;
        width: 24px;

        svg{
            font-weight: 500;
        }

        @media (max-width:$tablet-burger-menu-breakpoint) {
            height: 18px;
            width: 18px;
            .icon--xs {
                height: 16px;
                width: 16px;
                border-radius: 0 !important;
            }
        }
    }

    &__search-bar-container {
        position: relative;
        flex-grow: 1;
        padding: 0 20px;

        @media (max-width:$mobile-burger-menu-breakpoint) {
            padding: 0 10px;
            width: calc(100% + 50px);
            bottom: 10px;
            position: absolute;
            top: 70px;
            left: 0px;
            z-index: 100;
        }
    }

    &__search-bar {
        width: 100%; 
        padding: 0 10px 0 50px;
        height: 50px;
        border-radius: 25px;
        background-color: $color-grey-white;
        border: none;
        transition: background-color 0.15s ease-in-out;

        &:after {
            display: none;
        }

        &:hover {
            background-color: $color-grey-light;
        }

        @media (max-width:$tablet-burger-menu-breakpoint) {
            height: 40px;
        }
    }

    &__search-word-container {
        position: absolute;
        top: 9px;
        left: 55px;
        color: rgba($color-dark, 0.5);

        @media (max-width:$tablet-burger-menu-breakpoint) {
            top: 4px;
            left: 45px;
        }
    }

    &__search-bar-icon-container {
        position: absolute;
        left: 20px;
        top: 7px;
        height: 23px;
        width: 23px;

        @media (max-width:$tablet-burger-menu-breakpoint) {
            left: 15px;
            top: 4px;
            height: 18px;
            width: 18px;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            left: 15px;
        }
    }

    &__dropdown-icon-container {
        height: 25px;
        width: 25px;
        transform: rotate(270deg);

        &--active {
            transform: rotate(90deg);
        }

        svg {
            stroke: $color-blue-dark;
        }
    }

    
    &__dropdown-button {
        font-size: 12px;
        padding: 5px 16px 4px;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        color: #000;
        float: right;
        margin-top: 5px;
        text-transform: uppercase;
        font-weight: 600;
        z-index: 801;
        line-height: 23px;
        letter-spacing: 1.11px;
        border-radius: 50px;
        
        &:hover {
            background-color: $color-blue-grey;
        }
        
        @media (max-width:$tablet-burger-menu-breakpoint) {
            margin-left: unset;
            float: left;
        }
        
        @media (max-width:$mobile-burger-menu-breakpoint) {
             max-height: 35px;
             top: 40px;
             position: relative;
            }
        }
    }
    
.nyk-burger-menu-aside__white-background {
    @media (max-width: $mobile-burger-menu-breakpoint){
        display: block;
        margin-top: -25px;
        height: 70px;
        background: white;    
    }
}

.nyk-burger-menu-breadcrumb-mobile {
    @media (max-width:$desktop-burger-menu-breakpoint) {
        padding-left: 30px;
        z-index: -1;
    }

    @media (max-width:$tablet-burger-menu-breakpoint) {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 0 10px 0 10px;
        width: calc(100% + 50px);
        height: max-content;
        padding-top: 50px;
        z-index: -1;
    }

    @media (max-width:$mobile-burger-menu-breakpoint) {
        padding-top: 110px;
    }    

    &__hide {
        visibility: hidden;
        height: 0;
    }
}

.nyk-burger-menu-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 400;
}

.nyk-burger-menu-breadcrumb a {
    color: #0f1e82;
    font-size: 14px;
    text-decoration: none;
}

.nyk-burger-menu-breadcrumb_arrow {
    width: 17px;
    height: 17px;
    transform: rotate(180deg);
    stroke: #0f1e82;
    margin: 0 10px 0 10px;
}