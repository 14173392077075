


.nyk-fund-card {
    margin: 12px 0;
    padding-top: 40px;
    background-color: #EDE8E6;
    box-shadow: 0px 1px 3px 0px #00000033;    

    &__container {
        position: relative;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__list-container {
        list-style-type: none;
        padding: 0;
    }

    &__title {
        margin: 10px 0 20px 0;
        text-align: center;
    }

    &__subtitle {
        margin: 10px 0 20px 0;
        text-align: center;
    }

    &__type {
        font-size: 16px;
        text-transform: uppercase;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
    }

    &__link {
        color: #68d2df;
    }

    &__button-container {
        margin: 15px 0;
        display: flex;
        justify-content: center;
    }

    &__favorites-container {
        position: absolute;
        left: 15px;
        top: 15px;
        color: #68d2df;
    }
}