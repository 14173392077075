color-scale {
	.container {
		width: min-content;
		margin: auto;
	}
	.pointer-container {
		display: relative;
		width: 100%;
		display: flex;
		justify-content: center;

		&__content {
			h3 {
				margin: 0px;
				font-size: 16px;
				color: #0f1e82;
				text-align: center;
			}

			.drop {
				position: relative;
				width: min-content;
				margin: 0 auto;

				span {
					top: 14px;
					width: 100%;
					text-align: center;
					font-size: 15px;
					color: #fff;
					font-weight: bolder;
					position: absolute;
				}

				svg {
					width: 30px;
					height: 40px;
					fill: #68d2df;
					stroke: #68d2df;
				}

				&--down {
					span {
						z-index: 1;
						top: 4px;
					}

					svg {
						transform: rotate(180deg);
						fill: #0f1e82;
						stroke: #0f1e82;
					}
				}
			}
		}
	}

	.scale-container {
		display: flex;
		margin: 5px 0px 20px 0px;

		&__box {
			position: relative;
			width: 50px;
			height: 41px;
			margin-left: 1px;

			@media (max-width: 640px) {
				width: 25px;
				height: 20px;
			}

			&__number {
				position: absolute;
				bottom: -20px;
				right: -5px;
			}

			&__first {
				position: absolute;
				bottom: -20px;
				left: -5px;
			}
		}
	}
}
