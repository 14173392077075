@import '../foundation-totalkredit/variables-totalkredit-theme';
$theme-facttext-li-bullet-color: $color-white !default;

.fact {
  padding: $gutter*4;
  position: relative;

  [class*="mdc-typography--title"] {
    color: $color-white;
  }

  @include bp-above-desktop {
    padding: $gutter*6;
  }

  ul,
  ol {    
    list-style: none;
	  margin-bottom: 1em;
  }

  li {
    align-items: flex-start;
    box-sizing: content-box;
    height: auto;
    position: relative;
    padding-left: 10px;

    &:not(:first-child) {
      margin-top: $gutter*3;
    }

    &::before {
      content: "";
      display: inline-block;
      background-color: $theme-facttext-li-bullet-color;
      height: 10px;
      width: 10px;
      position: absolute;
      left: -$gutter*2;
      top: $gutter;
    }
  }
  
  a {
    color: #fff;
  }
}

.fact__icon {
  position: absolute;
  right: $gutter*4;
  top: calc(#{$gutter*4} - .5rem);

  @include bp-above-tablet {
    right: $gutter*6;
    top: calc(#{$gutter*6} - .5rem);
  }
}

.Totalkredit {
  .fact {
    &--blue-box {
      background-color: $color-totalkredit-blue;
      color: white;

      ul,
      ol,
      li,
      p {    
        color: white !important;
      }
    }
  }
}
.NykreditDk, .Dotcom, .invest {
  .fact {
    padding: $gutter*4;
    position: relative;
    border-radius: 16px;
  
    [class*="mdc-typography--title"] {
      color: $color-white;
      font-weight: 500;
    }
  
    @include bp-above-desktop {
      padding: $gutter*6;
    }
  
    ul,
    ol {    
      list-style: none;
      margin-bottom: 1em;
    }
  
    li {
      align-items: flex-start;
      box-sizing: content-box;
      height: auto;
      position: relative;
      padding-left: 10px;
  
      &:not(:first-child) {
        margin-top: $gutter*3;
      }
  
      &::before {
        content: "";
        display: inline-block;
        background-color: $theme-facttext-li-bullet-color;
        height: 10px;
        width: 10px;
        position: absolute;
        left: -$gutter*2;
        top: $gutter;
      }
    }
  }
}
