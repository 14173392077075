.loancalculator {
  /* IE 10, 11 only */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .loancalculator__tabs > .loancalculator__tabs__wrapper .mdc-layout-grid > .mdc-layout-grid__inner {
      margin: 0;
    }

    .result-table .loan-result__grid {
      border-top: 1px solid rgba(0, 0, 0, 0);
    }
  }

  .mdc-layout-grid {
    height: auto;

    &--nested {
      margin: -16px;
    }
  }

  .mdc-text-field {
    font-size: 16px;
  }

  .mdc-slider {
    width: 90%;
  }

  .mdc-form-field {
    font-family: Roboto,sans-serif;
    font-size: 1rem;

    span, input, label {
      color: #151434;
    }

    .mdc-text-field__input {
      text-transform: none;
      width: 100%;
    }

    &--flex-mobile {

      @media screen and (max-width: 839px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__heading {
    &--remove-margin {
      margin: 0px;

      @media screen and (max-width: 839px) {
        margin-bottom: 20px;
      }
    }

    &--remove-margin-top {
      margin-top: 0px;
    }
  }

  button {
    margin: 0;
  }

  .accordion__content-inner {
    padding-top: 0px !important;
  }

  &__buttonwrapper {
    margin-top: 50px;
  }

  &__button {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &[disabled] {
      background: #eee !important;
      color: #c7c7c7 !important;
      cursor: not-allowed;

      &:hover {
        background: #eee !important;
        color: #c7c7c7 !important;
        cursor: not-allowed;

        &:after {
          display: none;
        }
      }
    }
  }

  &__percentages {
    @media screen and (max-width: 839px) {
      margin: 20px 0px;
    }

    p {
      overflow: hidden;
      color: #c7c7c7;
      font-weight: bold;
    }
  }

  &__action {
    padding: 20px 16px 20px 16px !important;
  }

  &__error {
    .error {
      color: red;
    }
  }

  &__container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 80px 0 80px;

    @media screen and (max-width: 839px) {
      padding: 0;
      margin: 20px 20px 0 20px;
    }
  }

  .control-card {
    padding: 20px;

    @media screen and (max-width: 760px) {
      padding: 0px;
    }

    .mdc-layout-grid {
      padding: 0 16px;
    }
  }

  input[type="radio"] {
    -webkit-appearance: radio;
  }

  input[type="text"] {
    -webkit-appearance: textfield;
    background: none;
    padding: 0 0 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.25em;
  }

  &__panel {
    padding: 30px;
    background: #eee;
  }

  &__row {
    &-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__textfields {
    margin: 20px 0px;
    width: 100%;

    @media screen and (max-width: 839px) {
      margin: 0px;
    }

    .mdc-form-field {
      display: block;
      margin: 30px 0px;

      @media screen and (max-width: 839px) {
        margin: 0px;
      }
    }

    label {
      width: 100%;
    }

    .mdc-text-field {
      display: block;
      width: 100%;
    }
  }

  & &__input_card {
    &_values {
      text-align: right;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 20px;

      @media screen and (max-width: 839px) {
        display: none;
      }

      .inner-container {
        background: #EAE3E3;
        padding: 26px;
        height: auto;

        p {
          color: #151434;
          font-weight: 500;
          margin: 1em 0;

          span {
            font-weight: 600;
          }

          .percentage {
            min-width: 30px;
            text-align: right;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .disclaimer {

    p {
      font-size: 14px !important;
      font-style: normal;
    }
  }

  &__content .disclaimer-container {
    .accordion {
      .accordion__item:first-child {
        border-top: none;
      }

      @media screen and (max-width: 839px) {
        .accordion__item-label {
          font-size: 16px;
          padding: 16px 0px 12px 0px;
        }

        .accordion__item-content-inner {
          padding: 0;
        }
      }
    }
  }

  & .visible-on-tablet &__collateral-dropdown {
    padding-top: 10px;

    select {
      width: 100%;
      max-width: 100%;
      -moz-appearance:none;
    }
  }

  &__tabs {
    &__wrapper {
      margin: 0px !important;
      display: block !important;
      height: auto !important;

      .navigation-tabs {
        padding: 40px 0 0 0;

        select {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .mdc-tab {
      &--active {
        border-color: #FA254E;

        @media screen and (max-width: 839px) {
          background: lighten(#0e188b, 10%) !important;
        }
      }

      &--loading {
        // class for graying out tabs, very subtle on desktop
        color: #c7c7c7 !important;
        position: relative;

        @media screen and (max-width: 839px) {
          background: #eee !important;
        }

        &:hover {
          color: #c7c7c7 !important;

          @media screen and (max-width: 839px) {
            background: #eee !important;
          }
        }
      }
    }

    &__tab {
      width: 100%;
      border-bottom: 2px solid transparent;
      background: none !important;
      transition: all 0.2s ease-in-out;
      padding:0;

      @media screen and (max-width: 839px) {
        display: block;
        width: 100%;
        max-width: 100%;
        background: #0e188b !important;
        color: #fff !important;
      }

      &:disabled {
        cursor: default;
      }
    }

    &__indicator {
      background: #0e188b !important;
    }
  }

  .accordion__item-content-inner {
    font-size: 16px;

    @media screen and (max-width: 600px) {
      padding: 0;
    }

    h3 {
      margin: 2.2em 0 0.6em;
      color: #0e188b;
      font-size: 24px;
      font-weight: 800;
      line-height: 1.25em;

      @media (max-width: 480px) {
        font-size: 19.2px;
      }
    }
  }

  &__sliders {
    padding: 0px 50px 0px 120px;
    position: relative;

    @media screen and (max-width: 839px) {
      padding: 0px;
      margin: 0 20px;
    }

    &__slider {
      font-size: 16px;

      @media screen and (max-width: 839px) {
        font-size: 14px;
        margin-top: 30px;
      }

      .mdc-slider {
        @media screen and (max-width: 839px) {
          width: 100%;
        }
      }

      &__label {
        line-height: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 839px) {
          top: 45px;
        }
      }

      &__value {
        line-height: 0;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        min-width: 30px;
        text-align: right;

        @media screen and (max-width: 839px) {
          top: 45px;
        }
      }
    }
  }

  .advanced-controls {
    margin-top: 26px;
    padding: 0 16px;

    .accordion__item {
      border-color: #F0F0F0;

      &:last-child {
        border-color: #F0F0F0;
      }

      .accordion__item-label {
        padding-left: 0;
      }
    }
  }

  .accordion__item-label {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    line-height: 1.75em;

    &:hover {
      color: #423fa3;
    }
  }

  &__content .share-panel {
    margin-top: 20px;

    .nk-cta-box__button {
      display: block;
      position: relative;
      color: white;
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-bottom: 10px;
      cursor: pointer;
      overflow: hidden;
      pointer-events: all;

      svg {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 24px;
        height: 24px;
        z-index: 2000;
        padding: 15px;
      }

      .horizontal & {
        margin-right: 10px;
        margin-bottom: 0;
      }

      transition: width 0.3s cubic-bezier(0.250, 0.250, 0.480, 0.875);

      @media (min-width: 480px) {
        &:hover {
          width: 230px;


          &.rotate {
            svg {
              transform: rotate(360deg);
              opacity: 0;

              &.icon-arrow_forward {
                opacity: 1;
                transform: rotate(270deg);
              }
            }
          }

          .label {
            transform: translateX(0px);
            opacity: 1;
          }
        }
      }

      &.notification {
        background-color: #fa254e;

        svg {
          background-color: #fa254e;
          padding: 0;
          right: 14px;
        }
      }

      &.rotate {
        svg {
          transition: all 0.3s ease;
          opacity: 1;


          &.icon-arrow_forward {
            transform: rotate(0deg);
            background-color: transparent;
            opacity: 0;
          }
        }
      }

      .label {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.25em;
        transition: all 0.4s cubic-bezier(0.250, 0.250, 0.480, 0.875) 0.2s;
        transform: translateX(-20px);
        opacity: 0;
        position: absolute;
        top: 16px;
        left: 25px;
        width: 200px;
        will-change: transform;
      }
    }
  }
  // mdc-layout-grid overrides
  @media (max-width: 479px) {
    .mdc-layout-grid__cell--span-1-phone {
      grid-column-end: span 1;
    }

    .mdc-layout-grid__cell--span-2-phone {
      grid-column-end: span 2;
    }
  }

  @media (max-width: 839px) and (min-width: 480px) {
    .mdc-layout-grid__cell--span-2-tablet {
      grid-column-end: span 2;
    }

    .mdc-layout-grid__cell--span-4-tablet {
      grid-column-end: span 4;
    }
  }

  .result-container {
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .loan-result.mortgage-only {
    min-width: 320px;
  }

  .hide-animation {
    display: none !important;
  }

  .loan-result {
    font-size: 12px;
    min-width: 400px;

    & + & {
      display: none;
    }

    &__header {
      font-weight: bold;
    }

    &__name--toggle {
      display: flex;
      align-items: center;
    }

    .extra-space {
      margin-bottom: 20px;
    }

    &__button-link {
      cursor: pointer;
      padding-right: 10px;

      @media screen and (max-width: 839px) {
        // display: block;
        // padding: 0;
      }
    }

    &__grid {
      padding: 4px 12px;

      &--has-bottom-border {
        border-bottom: 1px solid #eae3e3;
      }

      .mdc-layout-grid__cell:nth-child(2), .mdc-layout-grid__cell:nth-child(3) {
        text-align: right;
        align-self: center;
      }

      .mdc-layout-grid__cell--indented {
        padding-left: 34px;

        @media screen and (max-width: 839px) {
          // padding-left: 0px;
        }
      }

      &-headline.mdc-layout-grid {
        background-color: #c9c3ba;
        font-weight: 700;
        font-size: 12px;
        padding: 5px 12px;
        border: none;
        line-height: 28px;
        text-transform: uppercase;

        p {
          padding: 0;
          margin: 0;
        }

        .mdc-layout-grid__cell:nth-child(2), .mdc-layout-grid__cell:nth-child(3) {
          text-align: right;
        }
      }

      &.highlight {
        font-weight: 600;
      }
    }

    .result-table &__grid {
      line-height: 28px;
      padding: 0 20px;
      border-bottom: 1px solid #C9C3BA;

      &:hover {
        background: #f8f6f5;
      }
    }

    .ammortization-container {
      margin-top: 46px;
    }

    &__ammortization {
      font-size: 12px;

      .accordion__item {
        .accordion__item-label {
          @media screen and (max-width: 839px) {
            font-size: 16px;
            padding: 16px 0px 12px 0px;
          }
        }
      }

      .accordion__item {
        border-top: none;
      }

      .accordion__item-content-inner {
        font-size: 1em !important;
      }

      .mdc-layout-grid {
        line-height: 28px;
        padding: 0 20px;
        border-bottom: 1px solid #C9C3BA;
        text-align: right;

        .year {
          text-align: left;
        }
      }

      &--mobile {
        .accordion__item-content-inner {
          padding: 0;
        }

        .loan-result {
          &__header {
            background-color: #c9c3ba;
            font-weight: 700;
            font-size: 12px;
            padding: 5px 20px;
            border: none;
            line-height: 28px;
            text-transform: uppercase;
          }

          &__name {
            &--toggle {
              display: flex;
              align-items: center;
            }
          }
        }

        .mdc-layout-grid {

          .ammort_label {
            text-align: left;
          }
        }
      }
    }
  }


  .mdc-card {
    &.key-indicator {
      padding: 20px 20px;
      background: #f8f6f5;
      box-shadow: none;
      color: #0e188b;
      margin-bottom: 14px;

      .mdc-layout-grid {
        font-size: 18px;
        margin: 0;
      }

      .loan-result__header {
        font-size: 18px;

        @media screen and (max-width: 839px) {
          font-size: 18px
        }
      }

      .value {
        font-size: 18px;

        @media screen and (max-width: 839px) {
          font-size: 22px;
        }
      }

      .nk-cta-box__button {
        margin-right: 10px;
      }
    }
  }
}
