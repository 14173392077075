.table-scroll {
    max-height: 750px;

    table {
        background: $mdc-theme-primary;
        border-radius: 3px;
        display: flex;
        flex-flow: column;    
        width: 100%;
        height: 100%;
    }
    table thead {
        flex: 0 0 auto;
        width: calc(100% - 1.5em);
    }
    table tbody {
        max-height: 700px;
        flex: 1 1 auto;
        display: block;
        overflow-y: scroll;
        background: white;
    }
    table tbody tr {
        width: 100%;
    }
    table thead, table tbody tr {
        display: table;
        table-layout: fixed;
    }
    
    td:nth-child(1), th:nth-child(1) { 
        width: 17%; 
    }
    
    td:nth-child(2), th:nth-child(2) { 
        width: 35%; 
    }
    
    td:nth-child(3), th:nth-child(3) { 
        width: 7%; 
    }
    
    td:nth-child(4), th:nth-child(4) { 
        width: 7%; 
    }
}