.fab-nav {
  border-bottom: 1px solid $color-grey-light;
  height: 56px;
  position: relative;
  width: 100%;
  z-index: 1;

  &--hidden{
    display: none;
  }

  @include bp-only-phone {
    display: none;
  }
  
  .hero + & {
    height: 0;
  }
  div + & {
    height: 0;
  }
}

.fab-nav__scroll-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.NykreditDk, .Dotcom, .invest {
  .fab-nav__scroll-btn {
    border-radius: 50px;
  }
}
