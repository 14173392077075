.hidden-visually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}
.justify-text{
  text-align: justify;
}

.zero-span-override {
  @each $size in map-keys($mdc-layout-grid-columns) {
      @include mdc-layout-grid-media-query_($size) {
          $gutter: map-get($mdc-layout-grid-default-gutter, $size);
          .mdc-layout-grid__cell--span-0, .mdc-layout-grid__cell--span-0-#{$size} {
              @include mdc-layout-grid-cell-span_($size, 0, $gutter);
              display: none;
          }
      }
  }   
}

.stop-scroll {
  overflow: hidden
}