.mdc-epi-form,
.custom-form {
	.mdc-typography--subtitle-lg-alt {
		background-color: $mdc-theme-primary;
		color: $color-white;
		padding: $gutter * 4 $gutter * 3;
		margin: 0;
	}

	input[type="text"],
	input[type="number"],
	input[type="tel"],
	textarea {
		font-size: 14px;
		font-weight: 700;
		line-height: 1.25em;
	}

	.mdc-form-field {
		font-family: "Open Sans", Roboto, sans-serif;
		font-size: 1rem;
	}

	.mdc-text-field {
		width: 600px;
	}

	textarea.mdc-text-field__input {
		color: #07094a;
		overflow: auto;
	}
	.mdc-text-field__label--extra-margin{
		top: 30px
	}

	.mdc-text-field--multiline .mdc-text-field__label--float-above {
		-webkit-transform: translateY(-175%) scale(0.75, 0.75);
		transform: translateY(-175%) scale(0.75, 0.75);
	}

	.mdc-text-field--textarea,
	.mdc-text-field--multiline {
		.mdc-text-field__input {
		}
	}

	.mdc-text-field__NotResizeable
	{
		resize: none;
	}

	.mdc-text-field__extra-margin{
		padding-top: 64px;
	}

	.mdc-select {
		display: block;
		width: 600px;
		max-width: 100%;
	}

	.mdc-multi-select {
		display: block;
		width: 600px;
		max-width: 100%;
	}

	.mdc-select {
		border-bottom: none;
		overflow: inherit;
	}

	.mdc-form-select-container {
		margin-top: 16px;
	}

	.mdc-button {
		text-align: center;
		justify-content: center;

		span {
			margin: 0 auto;
		}
	}
	.mdc-radio {
		@include mdc-radio-checked-stroke-color($color-turquoise);
		@include mdc-radio-ink-color($color-turquoise);
		@include mdc-radio-focus-indicator-color($color-turquoise);
		@include mdc-states($color-turquoise, true);
	}

	.mdc-checkbox {
		@include mdc-checkbox-container-colors(rgba($color-dark, 0.54), transparent, $color-turquoise, true);
		@include mdc-states($color-blue, true);
	}

	.choice-container {
		&--singleline {
			display: inline-block;
			margin: 6px 16px 0 0;
		}
		&--multiline {
			display: block;
			margin: 6px 0 0 $gutter * 4;
		}
	}

	.EPiServerForms,
	.custom-form-container {
		.mdc-form-select-container {
			&.Form__Element {
				margin: 16px 0 0 0;
			}
		}

		.Form__Status .Form__Status__Message.hide {
			display: none;
		}

		.Form__Status .Form__Success__Message {
			background-color: #79b9a3;
			color: #fff;
		}

		.FormChoice {
			margin-top: 16px;

			.choice-fields {
			}
		}

		.FormStep.Form__Element.accordion__item-content {
			margin: 0;
			height: auto;
			overflow: unset;
		}

		.FormStep__inner {
			padding: 0 $gutter * 4 1.5rem;
		}
	}

	.accordion__item-label {
		cursor: default;
		/* match height of the open/close icon, since that icon is there in this accordian */
		min-height: 2rem;
		box-sizing: content-box;
	}

	.ValidationRequired .Form__Element__Caption::after {
		margin-left: 1px;
		content: "*";
	}

	.mdc-typography--title-sm,
	.mdc-typography--title-xs {
		margin: 26px 0 12px 0;
	}

	.mdc-typography--body {
		margin: 16px 0;
	}

	.mdc-text-field__input,
	.mdc-text-field__label {
		color: #151434;
	}

	.mdc-form-field label,
	.mdc-form-select-container select {
		color: #151434;
		font-size: 16px;
		font-family: "Open Sans", Roboto, sans-serif;
		line-height: 1.2rem;
	}

	.file-upload {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: center;
		max-width: 600px;
		margin-bottom: 8px;
		& > * {
			flex: 0 0 auto;
			margin-top: 8px;
			margin-bottom: 8px;
		}
		.Form__Element__Caption {
			flex-grow: 1;
			flex: 0 1 auto;
		}
		&__filenames-container {
			margin-left: 8px;
			margin-right: 8px;
			flex: 1 1 200px;
			text-align: right;
		}
		&__filenames {
			white-space: pre-wrap;
		}

		&__input {
			opacity: 0;
			overflow: hidden;
			height: 0;
			width: 0;
		}
	}

	.hide-when-disabled[disabled] {
		display: none;
	}

	.form-control-hide {
		display: none;
	}

	.permission-choice {
		&__label {
			margin-bottom: 0;
			margin-top: 0;
		}
		&__description {
			margin-left: 46px;
			margin-top: -10px;
			margin-right: 46px;
			line-height: 1rem;
			p {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	// Only textfields have helptexts in MDC so we make our own for other types of fields.
	.forms-helptext {
		@include mdc-theme-prop(color, text-disabled-on-light);
		font-size: 0.75rem;
		margin-bottom: 8px;
	}

	.Form__Element__ValidationError {
		//color: $mdc-text-field-error-on-light;
		font-size: 0.75rem;
		margin-bottom: 8px;
	}

	.international-phone-field {
		display: flex;
		.mdc-text-field {
			width: 400px - 8px;
		}
		&__countrycode {
			.mdc-text-field {
				width: 200px;
				margin-right: 8px;
			}
		}
	}

	@media (min-width: 480px) {
		.nextBtnContainer {
			text-align: right;
		}
	}

	.required-error-message {
		color: red;
		word-wrap: break-word;
		font-size: .75rem;
		margin-bottom: 8px;

		&__show{
			display: block;
		}

		&__hide{
			display: none;
		}
	}
}

.Totalkredit .ui-widget-overlay, .NykreditDk .ui-widget-overlay {
	background: #000 !important;
	opacity: 0.4 !important;
}

.Totalkredit .ui-dialog, .NykreditDk .ui-dialog {
	width: 700px !important;
	font-size: 18px !important;
	padding: 28px !important;
	

	.ui-dialog-titlebar {
		background: transparent;
		border: none;
	}

	.ui-dialog-titlebar-close {
		width: 30px !important;
		height: 30px !important;
		right: 1.4em !important;
    	top: 40% !important;
	}

	.ui-dialog-titlebar-close:hover {
		border: none;
    	background: none;
	}

	.ui-dialog-title {
		font-size: 32px;
	}

	.ui-dialog-buttonpane {
		border: none;
	}

	.ui-dialog-buttonset {
		display: flex;
		flex-direction: row-reverse;
	}

	.ui-button {
		background: none;
		border: none;
	}

	.ui-icon-closethick {
		width: 100%;
		height: 100%;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='100%25' height='100%25'%3E%3Cpath stroke='%23696057' stroke-width='3px' d='M9.9 9.9l28.3 28.3m-.1-28.3L9.9 38.1'%3E%3C/path%3E%3C/svg%3E") !important;
		background-position: 0 0;
		top: 30%!important;
		left: 30%!important;
	}
}

.Totalkredit {
	.ui-dialog {
		font-family: $mdc-typography-font-family !important;
	}

	.ui-dialog-title {
		color: #ff4d00; 
	}

	.ui-dialog-buttonset {
		.ui-button:nth-child(1) {
			@extend .mdc-button, .mdc-button--raised, .mdc-button--secondary, .mdc-button--outlined, .mdc-ripple-upgraded;
		}

		.ui-button:nth-child(2) {
			@extend .mdc-button, .mdc-button--raised, .mdc-button--primary;
		}

		.ui-button:not(.mdc-button--primary):nth-child(2) {
			height: 48px !important;
			padding: 0 24px !important;
		}
	}
}

.NykreditDk, .Dotcom, .invest {
	.ui-dialog {
		font-family: "NykreditSans-Medium", "Open Sans", Roboto, sans-serif !important;
	}

	.ui-dialog-title {
		color: #07094a; 
	}

	.ui-dialog-buttonset {
		.ui-button:nth-child(1) {
			@extend .mdc-button, .mdc-button--raised, .mdc-button--primary, .mdc-ripple-upgraded;
		}

		.ui-button:nth-child(2) {
			@extend .mdc-button, .mdc-button--raised, .mdc-button--white, .mdc-ripple-upgraded;
		}
	}
}