.back-btn{
    display:block;
    cursor:pointer;

    @include bp-above-phone {
        
        margin-top:55px;
        
        svg{
            width:37px;
            height:37px;
        }
    }

    svg{
        stroke:#444;
        stroke-width:1px;
        transform: rotate(90deg);
        
    }
}