@mixin quickpoll-break {
    @media (min-width: 840px) { //make sure to sync changes to this with offsetPoint in quickpoll.js
      @content;
    }
}

.quickpoll{
    // display:none;
    @include mdc-elevation(1);
    background:$color-qp-bg;
    padding:$gutter*3 $gutter*4 $gutter*3 $gutter*4;
    margin-top:$gutter*4;
    margin-bottom:$gutter*4;
    width:calc(100% + 16px);
    font-size:16px;
    line-height:24px;

    &-wrapper{
       display:flex;
       flex-direction: column-reverse;
        @include quickpoll-break {
            display: block;
        }

    }

    .section--text-image &{
        margin-top:$gutter*3;
        max-width: 534px;
        float:none;
        margin-left:auto;
        margin-right:auto;

        @include quickpoll-break {
            margin-top:$gutter*7;
        }
    }
    .mdc-layout-grid__cell--order-3-desktop &{
        float:left;
        .section--text-image &{
            float:none;
            margin-left:auto;
            margin-right:auto;

            @include quickpoll-break {
                float:left;
            }
        }
    }

    @include quickpoll-break {
        max-width:314px;
        margin-top:$gutter;

        .section--text-image &{
            max-width: 534px;
        }
    }
    h4{
        font-size:20px;
        margin:0 0 $gutter*2 0;
        color:#07094a;
        .section--text-image &{
            margin-bottom:-5px;
        }
    }
    p{
        margin-bottom:$gutter;
        color:#07094a;
    }

    .override--center{
        float:none !important;
        margin-left:0 !important;
        margin-right:0 !important;
        margin-bottom:$gutter*5;
    } 

    .mdc-layout-grid &{
        display:block;
        
        @include quickpoll-break {
            margin-right:$gutter*4;

            .section--text-image &{
                margin-right:0;
            }
        }
        
        &[data-position="left"]{
            @include quickpoll-break {
                float:left;
                margin-right:$gutter*4;
                .section--text-image &{
                    margin-right:0;
                }
                margin-left:-102px;
            }
        }
        &[data-position="right"]{
            @include quickpoll-break{
                float:right;
                margin-left:$gutter*4;
                margin-right:-102px;
            }
        }
    }

    .mdc-radio{
        @include mdc-radio-unchecked-stroke-color(rgba($color-black,0.5));	
        @include mdc-radio-checked-stroke-color($color-turquoise);
        @include mdc-radio-ink-color($color-turquoise);
        @include mdc-radio-focus-indicator-color(rgba($color-turquoise,0.5));
        margin-left:-$gutter;

        &:focus{
            border:solid 1px green;
            &:before{
                opacity:1;
            }
        }
        
        label{
            color:$color-qp-text;
            font-size:16px;
            width:100%;
        }
    }
    .mdc-checkbox{
        transform: scale(0.7);
        @include mdc-checkbox-focus-indicator-color($color-turquoise);
        --mdc-ripple-fg-opacity:0.16;
        --mdc-ripple-left:11px !important;
        --mdc-ripple-top:11px !important;
        --mdc-ripple-fg-scale:3 !important;
        margin-left:-11px;

        &__checkmark{
            height:100%;
        }
        
        &::before{
            display:none;
        }
        & + label{
            color:$color-qp-text;
            font-size:16px;
            width:100%;
        }
    }

    & + .richtext{
        
        @include quickpoll-break {
            *{
                word-wrap:break-word;
                overflow-wrap:break-word;
                
                &:first-child{
                    margin-top:$gutter;
                }
            }
        }
        
        @include bp-above-desktop-wide {
            *{
                margin-top:$gutter;
                word-wrap:normal;
                overflow-wrap:normal;
            }
        }
    }
   

    &__answers{
        padding:0 0 20px 0;
    }
    &__answer{
        margin:1px 0;
    }
    &__button{
        @include mdc-button-container-fill-color($color-white);
        opacity: 1;
    }
    &__result{
        //display:none;
        color:$color-qp-text;
        
        &__item{
            padding:10px 0 0 0;

            &__bar{
                margin:5px 0;
                //width:0;
                height:10px;
                background: $color-turquoise;
                transition: width .5s;
            }
        }
        &__text{
            padding-top:20px;
            font-size:12px;
            line-height: 18px;
            color:inherit;
            a{
                color:$color-turquoise;
            }
        }
    }
}
@media only screen and (max-width : 840px) 
{
    .mdc-layout-grid [data-position="left"], 
    .mdc-layout-grid [data-position="right"] {
        margin-left: auto;
        margin-right: auto;
        width:314px;
        float: none;
    }
}