@import '../../foundation/_variables';
$mobile-bond-info-breakpoint : 720px;

.nyk-select-dual-range {

    .nyk-select-custom-input__content-container--selected {
        width: 120%;
    }

    .mdc-form-field>label {
        cursor: pointer;
        padding: 5px 0;

    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 2rem auto 1rem auto;

        @media screen and (max-width: 480px) {
            margin: 1.5rem auto 1rem auto;  
            width: 85%;
        }

    }

    &__sliders-control {
        position: relative;
        min-height: 1rem;
        width: 100%;
    }

    &__input {
        height: 0.15rem;
        width: 100%;
        position: absolute;
        background-color: $color-grey-medium;
        -webkit-appearance: none;
        appearance: none;
        pointer-events: none;

        &-min {
            height: 0;
            z-index: 1;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            pointer-events: all;
            width: 0.85rem;
            height: 0.85rem;
            background-color: $color-blue;
            border-radius: 50%;
            box-shadow: 0 0 0 0.1rem $color-blue;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            -webkit-appearance: none;
            pointer-events: all;
            width: 5px;
            height: 5px;
            background-color: $color-grey-lighter;
            border-radius: 50%;
            box-shadow: 0 0 0 0.1rem $color-grey-medium;
            cursor: pointer;
        }

        &::-webkit-slider-thumb:hover {
            background: $color-grey-white;
        }

        &::-webkit-slider-thumb:active {
            box-shadow: inset 0 0 0.1rem $color-blue-light, 0 0 0.3rem $color-blue-light;
            -webkit-box-shadow: inset 0 0 0.1rem $color-blue-light, 0 0 0.3rem $color-blue-light;
        }

    }

    &__labels-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--steps {
            width: 100%;
        }
        
        &--last-label {
            text-align: right;
        }
    }

}