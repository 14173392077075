.error{
    &-section{

        text-align:center;
        @include bp-above-tablet {
            text-align:left;
        }

        &__link-list{
            margin:20px 0 0 0;
            list-style: none;
            text-align:left;
        }

        &__link{
            display:block;
            padding:5px 0;
            color:$color-turquoise;
            line-height:1.75;
        }

        &__image{
            background-size: contain;
            background-position: top center;
            background-repeat:no-repeat;
            height: 100%;
            width:100%;
    
            &--desktop{
                @include bp-until-tablet {
                    display:none;
                }
            }
            &--mobile{
                height:150px;
                margin-bottom:30px;
                @include bp-above-tablet {
                    display:none;
                }
            }
        }

        &__search{
            position: relative;
            margin:40px 0 0 0;
            &__input{
                @include mdc-elevation(1);
                //@include mdc-elevation-transition;
                appearance: none;
                background-color: $color-white;
                border-radius: $corner-radius;
                border: none;
                display: block;
                height: 70px;
                width: 100%;
                padding: 0 $gutter*6 0 $gutter*2;
                outline: none;
            
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            
                @include bp-until-desktop {
                padding-right: 65px;
                }
            
                &::placeholder {
                opacity: 0.5;
                }
                &:-ms-input-placeholder{
                    opacity:1;
                    color:$color-grey-lighter;
                }
            
                &:hover,
                &:focus {
                @include mdc-elevation(4);
                }
                &::-ms-clear {
                    display: none;
                }
                &:focus + button{
                    opacity:1;
                }
            }
            &__icon {
                position: absolute;
                top: 50%;
                right: $gutter*3;
                transform: translateY(-50%);
                transition: opacity 0.2s $mdc-animation-acceleration-curve-timing-function;
                opacity: 0.2;
              
                &:hover,
                &:focus,
                &--active {
                  opacity: 1;
                }
                .icon--sm{
                    width:2rem;
                    height:2rem;
                }
            }
        }    
        
        &--403 .error-section__image {
            min-height: 40vh;
        }
    }
}