.share {
  display: none;

  &.is-active {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 3;
  }
}

.share__backdrop {
  background-color: rgba(#000, .34);
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
}

.share__content {
  position: fixed;
  max-width: 470px;
  width: calc(100vw - #{$gutter} * 2);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.share__content-inner {
  background-color: $color-white;
  padding: $gutter*5;

  .is-active & {
    animation: enterFromBottom .3s $mdc-animation-deceleration-curve-timing-function;
    animation-fill-mode: forwards;
    //animation: exitToTop .3s $mdc-animation-acceleration-curve-timing-function;
  }
}

.share__items {
  display: flex;
  justify-content: space-around;
  margin-bottom: $gutter*7;
  flex-wrap: nowrap;
}

.share__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;

  svg,
  div {
    pointer-events: none;
  }

  svg {
    margin-bottom: $gutter*2;
    transition: color 0.3s $mdc-animation-standard-curve-timing-function;
  }
}

.share__item--facebook {
  svg {
    color: $color-facebook;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-facebook, 10%);
    }
  }
}

.share__item--twitter {
  svg {
    color: $color-twitter;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-twitter, 10%);
    }
  }
}

.share__item--linkedin {
  svg {
    color: $color-linkedin;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-linkedin, 10%);
    }
  }
}

.share__link {
}

.share__url {
  @include truncate;
  color: $color-turquoise;
  margin-bottom: $gutter;
}

.share__link-button {
  text-transform: uppercase;
  padding: 0 5px;
  margin-left: -5px;
  border-radius: 2px;
  -webkit-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
  
  &:active {
    background-color: #adadad;
	-webkit-transition: 125ms;
    transition: 125ms;
  }
  
  &.tooltipped {
    position: relative;
  }
  
  &.tooltipped:after {
      position: absolute;
      z-index: 1000000;
      display: none;
      padding: 5px 8px;
      font: normal normal 11px/1.5 Helvetica,arial,nimbussansl,liberationsans,freesans,clean,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
      color: #fff;
      text-align: center;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: break-word;
      white-space: pre;
      pointer-events: none;
      content: attr(data-copied);
      background: rgba(0,0,0,0.8);
      border-radius: 3px;
      -webkit-font-smoothing: subpixel-antialiased;
	  top: 100%;
      right: 50%;
      margin-top: 0px;
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
      transform: translateX(50%);
  }
  
  &.tooltipped:before {
      position: absolute;
      z-index: 1000001;
      display: none;
      width: 0;
      height: 0;
      color: rgba(0,0,0,0.8);
      pointer-events: none;
      content: "";
      border: 5px solid transparent;
	  top: auto;
      right: 50%;
      bottom: 0px;
      margin-right: -5px;
      border-bottom-color: rgba(0,0,0,0.8);
  }
  
  &.tooltipped[data-copied]:hover:before,
  &.tooltipped[data-copied]:hover:after,
  &.tooltipped[data-copied]:active:before,
  &.tooltipped[data-copied]:active:after {
      display: inline-block;
  }
  
  &.tooltipped[data-copied=""]:hover:before,
  &.tooltipped[data-copied=""]:hover:after,
  &.tooltipped[data-copied=""]:active:before,
  &.tooltipped[data-copied=""]:active:after {
      display: none;
  }
}

.jb-share-panel {
    .icon{
      margin-right: 8px;
      margin-left: 8px;
    }
}