
.si-prices{
    &__button{
        -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
    }
    
    &__paging {
        text-align: right;
    }
}