.rr-top-info-list {
    list-style: none;
    width: 100%;
    margin-bottom: 1rem;

    &__list-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-lighter;
        padding: 0 2rem;
        width: 100%;

        @media screen and (max-width: 870px) {
            padding: 0 1rem;
        }

        &-content {
            padding: 1rem 0;
            text-transform: uppercase;
            font-size: 1.3rem;
            margin: 1rem 0;
            width: 50%;

            @media screen and (max-width: 870px) {
                width: 100%;
                font-size: 1.15rem;
            }

            &-title {
                text-align: left;
                font-weight: 400;
            }

            &-value {
                text-align: right;
                display: block;
                width: 8rem;
                white-space: nowrap;
                font-weight: 600;

                &--lowercase {
                    text-transform: lowercase;
                }
                
                @media screen and (max-width: 870px) {
                    width: 100%;
                }
            }
        }

    }


}