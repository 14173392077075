.nyk-cell-type-eu-sustainability-sfdr {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: $color-strong-green;
    word-spacing: 0.5rem;
    font-weight: 700;

    &__article {
        margin: 0;
        word-spacing: 0.1rem;
        font-size: 14px;
    }

    &__subtitle {
        font-weight: 400;
        color: $color-strong-green-ligther;
        margin: 0;
        display: flex;
        gap: 3px;
        font-size: 12px;
        align-items: center;
        text-transform: capitalize;

        &-icon {
            svg {
                width: 10px;
                height: 10px;

                path {
                    stroke: $color-strong-green-ligther;
                }
            }
        }
    }
}