.customer-package {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    &__image{
        border: solid #e6e6e6;
        border-width: 1px 1px 0px 1px;
        position: relative;
        width: 100%;
        height: 206px;
        img{
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }

        svg {
            position: absolute;
            height: 80px;
            width: 80px;
            bottom: 16px;
            left: 16px;
            stroke:none;
        }
    }
    
    &__container {
        border: solid #e6e6e6;
        border-width: 0px 1px;
        overflow: hidden;
        max-height: 325px;
        padding: 16px 28px 0px;
        transition: 1s ease;
        display:block;

        &--open {
            max-height: 1000px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
         
        }
    }        
    
    &__price-unit {
        text-align: right;
        color: $color-dark;
        opacity: 0.6;
        width: 100%;
        display: block;
    }
    
    &__header {
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &__name{
            color: $color-blue;
            line-height: 30px;
            font-size: 30px;
            margin: 0px;
            padding: 13px 0px;
        }
        
        &__price {
            color: $color-turquoise;
            line-height: 40px;
            font-size: 40px;
            margin: 0px;
        }
    }
    
    &__description {
        color: $color-blue;
        padding: 30px 0px 30px;
        
        p{
            min-height: 50px;
            margin:0px;
        }
    }
    
    &__users {
        display: flex;
        align-items: center;
        padding: 0px 0px 32px;
        
        &__count {
            color: $color-turquoise; 
            font-weight: 400;
            font-size: 40px;
            margin: 0px;
            padding: 10px 0px;
        }
        
        &__things {
            border-left: 1px solid lightgray;
            margin-left: 15px;
            padding-left: 15px;
            height: 100%;
            
            p {
                font-size: 16px;
                line-height: 16px;
                margin: 0px;
                font-weight: 100;
                color: #131313;
            }
            
            p + p {
                padding-top: 10px;
            }
        }
        
    }
    &__arguments{
        margin-bottom: 30px;
        flex-grow: 1;

        &__argument{
            font-weight: 100;
            margin:10px 0px;

            b { 
                font-weight: bold;
            }

            svg {
                color: $color-turquoise;
                width: 12px;
                height: 12px;
            }

        }

    }

    &__arguments + &__arguments {
        flex-grow: 0.3;
    }
    
    &__expand {
        border: solid #e6e6e6;
        border-width: 0px 1px 1px;
        display: flex;
        height: 50px;
        justify-content: center;

        svg{
            transition: 1s ease;
        }

        &--open {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}