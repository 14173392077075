.richtext-container {
    margin: 0;

    .showmore {
        height: 200px;
        max-height: 100%;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: 0;
    }

    .rich-text-position {
        position: relative;
    }

    .limitcharactersshown {
        max-height: 200px;
        overflow: hidden;        
    }

    .richtext {
        h1, h2, h3 {
            margin: 0 0 1rem 0; 
        }
    }
    
    .center {
        margin-left: 50%; 
        margin-right: 50%;
    }
    
    .button-icon{
        height: 38px;
        width: 38px;
        &--small{
            height: 30px;
            width: 30px;
        }
    }
}