$margin-left: 1rem;
$margin-right: 1rem;
$padding-left: 1rem;
$padding-right: 1rem;

.nyk-investment-fund-list-document-mobile {
    height: 100%;
    width: 100%;
    position: relative;

    &__menu {
        margin-left: $margin-left;
        margin-right: calc($margin-right +1rem);
        background-color: transparent;
        width: 100%;
        padding-left: 0.25rem;
        white-space: nowrap;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            background: none;
        }

        &::-webkit-scrollbar-track {
            background: none;
        }

        &::-webkit-scrollbar-thumb {
            background: none;
        }

        &-list {
            list-style: none;
            display: flex;
            gap: 1rem;
            justify-content: flex-start;
            align-items: center;

            &-item {
                text-transform: uppercase;
                opacity: 0.4;
                cursor: pointer;

                &:last-child {
                    padding-right: 1rem;
                }

                &:active,
                &:focus,
                &:focus-visible {
                    opacity: 1;
                }

                &--active {
                    opacity: 1;
                }
            }
        }
    }

    &__fund-content {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        &-title {
            text-transform: uppercase;
            background-color: $color-beige-dark;
            padding: 0.75rem 0;
            margin-bottom: 0;
            padding-left: $padding-left;
            padding-right: $padding-right;
        }

        &-list {
            list-style: none;

            &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                border-bottom: 1px solid $color-grey-lighter;
                border-top: 1px solid $color-grey-lighter;
                text-transform: capitalize;
            }

            &-icon {
                cursor: pointer;

                svg {
                    height: 2rem;
                    width: 2rem;
                }
            }
        }
    }
}