//============= DEBUG ==============
$debug-colors: (
  desktop: hsla(204, 80%, 72%, 0.25),
  tablet: hsla(286, 51%, 44%, 0.25),
  phone: hsla(150, 80%, 44%, 0.25)
);

:root {
  --debug-max-width: $max-width;
  --debug-repeating-width: calc(100% / var(--debug-columns));
  --debug-column-width: calc((100% / var(--debug-columns)) - var(--debug-gutter));
  --debug-background-width: calc(100% + var(--debug-gutter));
  --debug-background-columns: repeating-linear-gradient(
    to right,
    var(--debug-color),
    var(--debug-color) var(--debug-column-width),
    transparent var(--debug-column-width),
    transparent var(--debug-repeating-width)
  );
}

@each $size in map-keys($mdc-layout-grid-breakpoints) {
  @include mdc-layout-grid-media-query_($size) {
    :root {
      --debug-color: map-get($debug-colors, $size);
      --debug-columns: map-get($mdc-layout-grid-columns, $size);
      --debug-margin: map-get($mdc-layout-grid-default-margin, $size);
      --debug-gutter: map-get($mdc-layout-grid-default-gutter, $size);
    }
  }
}

.debug {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: var(--debug-margin);
    bottom: 0;
    left: var(--debug-margin);
    margin-right: auto;
    margin-left: auto;
    width: calc(100% - var(--debug-margin) * 2);
    padding: 0 var(--debug-margin);
    max-width: calc(var(--debug-max-width) - var(--debug-margin) * 2);
    min-height: 100vh;
    content: '';
    background-image: var(--debug-background-columns);
    background-size: var(--debug-background-width) 100%;
    background-position: 0 var(--debug-baseline-shift);
    z-index: 1000;
    pointer-events: none;
  }

  .mdc-layout-grid__cell:not(:empty) {
    background-color: rgba(#000, .2);
  }
}
