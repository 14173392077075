.fund{
	&__details-list{
		&.dl-list{
			margin-top: 0;
			@include bp-above-desktop-wide {
				dt{
					flex: 0 0 20%;
				}
				dd{
					flex: 80% 0 0;
					max-width: 80%;
				}
			}
		}
		.tooltip{
			margin-left: 0;
		}
	}
	&__detail-buttons{
        display: flex;
		>*{
			margin-right: $gutter;
		}
	}
	&__documents-menu{
		position: relative;
		.mdc-menu{
			position: absolute;
			left: 0;
			min-width: 350px;
		}
	}
	a.fund__menu-link{
		/* MDC creates a hidden overlay over mdc-list-item elements, that takes the click from our link. */
		/* Position our link above the hidden overlay */
		z-index: 2;
		/* Style the link like the list item, so the entire list item area is clickable. */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 0 $gutter*2;
		display: flex;
		align-items: center;
	}
}
