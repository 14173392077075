.image-button {
    padding-bottom: 16px;
    padding-right: 24px;
    display: inline-block;

    &:last-child{
        padding-right: 0px;
    }

    @include bp-only-phone {
        padding-bottom: 32px;
        padding-right: 48px;

        &:last-child{
            padding-right: 0px;
        }
    }

    img {
        max-width:100%;
    }
}

.image-button-horizontal-container {
    max-width: fit-content;
    margin: auto;

    div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include bp-only-phone {
            flex-direction: column;
        }
        @include bp-only-tablet {
            flex-direction: column;
        }
    }

    .image-button {
        @include bp-until-desktop {
            padding-bottom: 32px;
            padding-right: 0px;
        }
    }
    @media (max-width:840px) {
        .mdc-button {
            width: 100%;
            margin-left: 0;
        }
        
        .mdc-button + .mdc-button {
        margin-top: 12px;
        }
    }
}