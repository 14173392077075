.nyk-burger-menu-primary {
    min-height: 70px;
    display: flex;

    .mdc-toolbar__row {
        padding-bottom: 5px;
    }

    &--spacing {
        padding-top: 0px;
        margin-top: -76px;
    }

    @media (max-width: $tablet-burger-menu-breakpoint) {
        display: none;
    }

    .toolbar-primary__nav-link:after {
        bottom: -1px;
    }

    &__universe-nav-item {
        color: $color-blue;
        font-size: 14px;
        text-transform: none;
        letter-spacing: 0.4px;
    }

    &__wrapper {
        max-width: 890px;
        margin: 0 auto;
        justify-content: flex-start;
        // z-index: -1;

        @media (max-width: 1440px) {
            margin-left: 225px;
        }
    }
}

.nyk-burger-menu-mobile-spacer {
    // height: 120px;
    margin-bottom: -30px;
    display: block;

    &--hide {
        display: none
    }
}
