$unchecked-star:  url(/assets/images/shape-star.png);
$checked-star: url(/assets/images/shape-star-red.png);

.popup-rating{
    position: fixed;
    top:10%;
    left:50%;
    margin-left:-190px;
    text-align: center;
    z-index:20;
    background: $color-white;
    width:380px;
    padding:$gutter*3;
    @include mdc-elevation(4);
    display: none;

    &--open {
        display: block;
        z-index: 2147483647;
    }

    transition: all 0.3s;
    animation: fadein 0.3s;

    &--close{
        opacity:0;
    }

    &:after,
    &:before{
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &:after {
        position: fixed;
        background: rgba($color-black,0.4);
        z-index: -2;
    }
    &:before {
        position: absolute;
        background:$color-white;
        z-index: -1;
    }
    
    

    [class*="title"]{
        font-size:20px;
        color:$color-blue-dark;
        margin-bottom:0;
    }
    [class*="title-tk"]{
        font-size:20px;
        margin-bottom:0;
        line-height: 2rem;
        font-weight: 800;
        letter-spacing: normal;
        color: #696057;
        line-height: 26px;
    }
    p{
        width:80%;
        margin-left:auto;
        margin-right:auto;
        margin-top:6px;
        margin-bottom:20px;
        line-height:1.75;
    }

    .paragraph-tk {
        color: #696057 !important;
    }

    &__close{
        cursor:pointer;
        position: absolute;
        right: $gutter*2;
        top:$gutter*2;
        opacity:.5;
        transform: rotate(45deg);
    }
    &__icon{
        margin:20px 0 40px 0;
    }

    &__stars{
        margin:0 0 30px 0;
        font-size: 0;
        //unicode-bidi: bidi-override;
        direction: rtl;

        * {
        //font-size: 1rem;
        }

        &__item {
            display: none;
        
            & + label {
                
                display: inline-block;
                overflow: hidden;
                text-indent: 9999px;
                width: 40px;
                height:28px;
                white-space: nowrap;
                cursor: pointer;

                &:before {
                display: block;
                text-indent: -9999px;
                content: "";
                width:100%;
                height:100%;
                
                
                background-image: $unchecked-star;
                background-repeat: no-repeat;
                background-size: 28px;
                background-position: 50%;
                
                }
            }
        
            &:checked ~ label:before,
                & + label:hover ~ label:before,
                & + label:hover:before {
                    background-image: $checked-star;    
            }
            &:last-child{
                + label{
                    text-indent: -9999px;
                    width: .5em;
                    margin-left: -.5em;
                    &:before{
                        width: .5em;
                    }
                }
            }
        }

        &:hover > input + label:before {
            background-image: $unchecked-star;
        }

        &:hover > input + label:hover ~ label:before,
        &:hover > input + label:hover:before {
            background-image: $checked-star;
        }
    }

    &__button{
        display:inline-block;
        font-weight:400;
        padding:0 50px;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
