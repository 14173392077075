$offset: 187;
$duration: 1.4s;

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loader svg {
  animation: rotator $duration linear infinite;
}

.loader .path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes rotator {
  0% { 
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(270deg);
  }
}

@keyframes colors {
	0% { stroke: $color-loader-1; }
	33% { stroke: $color-loader-2; }
	66% { stroke: $color-loader-1; }
	100% { stroke: $color-loader-2; }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}

// Internet Explorer 10 & 11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .loader svg {
    display: none;
  }
  .loader {
    margin: 0 auto;
    width: 69px;
    height: 69px;
    display: block;
    color: $color-loader-2;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: inline-block;
      border: 7px solid currentColor;
      border-bottom-color: transparent;
      border-radius: 100%;
      background: transparent;
      animation: rotate-ie 0.95s linear infinite;
    }
  } 
}

@keyframes rotate-ie {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform:rotate(360deg);
  }
}