.document-list-block {
    @each $size in map-keys($mdc-layout-grid-columns) {
        @include mdc-layout-grid-media-query_($size) {
            $gutter: map-get($mdc-layout-grid-default-gutter, $size);
            .mdc-layout-grid__cell--span-0, .mdc-layout-grid__cell--span-0-#{$size} {
                @include mdc-layout-grid-cell-span_($size, 0, $gutter);
                display: none;
            }
        }
    }   
}