.section--bond-data{
	.mdc-layout-grid{
		@include bp-until-desktop {
			padding-left:0;
			padding-right:0;
		}
	}
}

.bond-data-form {
    padding-bottom: 50px;

    @include bp-until-desktop {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.bond-data-block {
    .bond-data-table {
        text-align: left;
        width: 100%;

        .right-align {
            text-align: right;
            justify-content: flex-end;
        }

        .sorted {
            color: $color-turquoise
        }

        .flex-container {
            display: flex;
            font-size: 1.6rem;
        }

        .header-row {
            margin-bottom: 15px;
        }

        @include bp-until-desktop {
            font-size: 0.75rem;
        }

        .row-column-transpose {
            @include bp-above-desktop {
                .row-header {
                    display: none;
                } 
                     
                td {
                    padding-left: 5px;  
                    
                    &:first-child{
                        padding-left: 0;
                    }     
                }                     
            }
            @include bp-until-desktop {
                .row-header {
                    color: $color-blue;
                    font-weight: map-get($mdc-typography-font-weight-values, bold);
                }
                tr {
                    display: block;
                    margin-bottom: 25px;
                }
                td {
                    display: flex;
                    justify-content: space-between;
                    padding: 3px 10px;

                    &:first-child{
                        background: $color-beige-dark;
                    }
                }
            }
        }
        
        th {
            cursor: pointer;
            padding: 0;
            padding-left: 5px;       
            color: $color-blue;
            font-weight: map-get($mdc-typography-font-weight-values, bold);
            font-size: .75rem;
            text-transform: uppercase;
            vertical-align: top;

            &:first-child{
                padding-left: 0;
            }

            @include bp-until-desktop {
                display: none;
            }
        }

        svg {
            margin-left: 10px;
        }
    }

    .bond-input {
        width: 100%;
        height: 48px;
        margin-top: 16px;
        margin-bottom: 8px;
        border: none;

        input {
            height: auto;
            padding-top: 16px;
            padding-bottom: 4px;
        }

        select {
            padding-top: 16px;
        }

        .input-label {
            color: #131313;
        }        
    }

    .double-input {
        display: flex;

        .select-input {
            flex: 1;
            margin-left: 10px;
        }
        .text-input {
            flex: 3;
        }
    }

    .sorted-column {
        color: $color-turquoise
    }
}
