.author__name {
  margin-bottom: $gutter;
}

.author__title {
  padding-bottom: $gutter;
  display: inline-block;
  border-bottom: 3px solid $color-turquoise;
}

.author__social {
  margin-top: $gutter * 2;
}

.author-article {
	margin-top: 24px;
}

.author-article:first-child {
	margin-top: 48px;
}