.mdc-menu-bottom-left {
    position: absolute;
    top: 40px;
    max-height: 256px;
    overflow-y: auto;
    left: 0;
    min-width: 250px;
    @media (max-width:840px) {
        left: -60px;
    }
}