$border-color:#ede8e6;
$header-color:#07094a;
$icon-color:#68D2DF;
$icon-inactive-color:#999;
$date-color:#999;
$text-color:#333;
$page-count-color:#262626;

.NykreditDk, .Dotcom, .invest{
    .insights{
        &-news{
            &-item{
                &__image{
                    border-radius: 16px;
                }
                @include bp-above-desktop {
                    &:first-child{
                        .insights-news-item__image{
                            border-radius: 16px;
                        }
                    }
                }
            }
        }
    }
}

.invest {
    .insights{
        &-progress{
            &.fixed-element{ // ensure that the loadingbar on articles are sticky in the top of the page.
                position: fixed;
                top:0px;
                left:0;
            }
        }
    }
}

.insights{
   
    &__header{
        font-style:italic;
        font-size:18px;
        color: $header-color;
        opacity:0.3;

        @include bp-above-desktop {
            font-size:14px;
            padding:0;
        }
        
        .insights-all &{
            padding:0 $gutter*2;

            @include bp-above-desktop {
                padding: 0;
                font-size:16px;
            }
        }

        &__newsletter {
            margin-bottom: 0;
        }
    }
    &-news{
        @include bp-above-desktop {
            &:after{
                content:"";
                display:block;
                width:100%;
                background:$border-color;
                height:1px;
                position: relative;
                top:32px;
            }
        }

        @include bp-above-desktop {
            border-right:solid 1px $border-color;
            padding-right:$gutter*4;
            margin-bottom:$gutter*4;
        }

        img{
            max-width: 100%;
        }

        h2{
            color:$header-color;
            line-height: 1.33;
        }

        &-item{
            display:flex;
            flex-direction: column;
            padding:0 0 $gutter*3 0;
            text-decoration: none;
            cursor: pointer;

            @include bp-above-desktop {
                flex-direction: row;
                padding:$gutter*3 0;
                border-bottom:solid 1px $border-color;
            }
            
    
            &__image{
                width:100%;
                min-height: 196px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                margin-bottom: $gutter*2;
                

                @include bp-above-desktop {
                    width:40%;
                    margin-bottom: 0;
                    max-height:217px;
                    
                }
            }
            &__text{
                width:100%;
                color:$text-color;
                font-size:14px;
                line-height:1.6;
                float:left;
                

                @include bp-above-desktop {
                    font-size:15px;
                    width:60%;
                    padding:0 0 0 $gutter*4;
                }

                h2{
                    font-size: 24px;
                    line-height: 1.38;
                    font-weight: 800;
                    margin:0;

                    @include bp-above-desktop {
                        width: 85%;
                        margin:0 0 $gutter*1.5 0;
                    }
                }
                p{
                    margin:0;
                    line-height: 1.71;
                    @include bp-above-desktop {
                        width: 90%;
                        margin:0 0 $gutter*1.5 0;
                    }
                }
            }

            .insights-item__date{
                font-size: 14px;
            }
            .insights-item__meta{

                @include bp-above-tablet {
                    flex-direction: row;
                }
            }
            .insights-item__icon{
                width:16px;
                height:16px;
            }

            
            @include bp-above-desktop {

                &:first-child{
                    padding:$gutter*2 0 $gutter*5;
                    h2{
                        font-size:30px;
                        width:100%;
                    }
                    .insights-news-item__image{
                        width:50%;
                        max-height:257px;
                    }
                    .insights-news-item__text{
                        width:50%;
                        p{
                            width: 92%;
                        }
                    }
                }
                &:nth-child(2){
                    padding:$gutter*4 0 $gutter*3;
                }
                
            }

           
           
            &:last-child{
                border-bottom:none;
            }
        }
    }
    &-all{
        padding-top:14px;
        margin-bottom:$gutter*4;

        @include bp-above-desktop {
            border-right:solid 1px $border-color;
            padding-right:$gutter*4;
            margin-top:$gutter;

            .section--insights-subject &{
                border-right:none;
            }
        }

        &-grid{
            padding-top:0;
            @include bp-until-desktop {
                padding:0;
            }
        }

        &-inner-grid{
            > div{
                &:nth-child(odd){
                    
                    @include bp-above-phone {
                        
                        .insights-item{
                            margin:0 0 0 $gutter*2;
                        }
                    }
                    @include bp-above-desktop {
                        .insights-item{
                            margin:0;
                        }
                    }

                }
                &:nth-child(even){
                    @include bp-above-phone {
                        
                        .insights-item{
                            margin:0 $gutter*2 0 0;
                        }
                    }
                    @include bp-above-desktop {
                       
                        .insights-item{
                            margin:0;
                        }
                    }
                    
                }
            }
        }
        &__paging{
            margin:$gutter*4 0 $gutter*2;
            display:flex;
            justify-content:center;

            &__page-count{
                margin:0 $gutter*14;
                font-size:13px;
                color: $page-count-color;
                font-weight: 600;
            }
            button{
                &:disabled{
                    opacity:.4;
                }
            }
        }
        

    }
    &-filter{
        border-top:solid 1px $border-color;
        margin-bottom:$gutter*2;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        //border-radius: 4px;
        padding:15px $gutter*2;
        font-size:13px;
        display:flex;
        justify-content: space-between;
        color: #3d3d3d;
        
        @include bp-above-desktop {
            box-shadow:none;
            border-top:none;
            padding:0 $gutter 0 0;
            justify-content: flex-end;
        }

        &__list{
            display:flex;
            &__item{
                position: relative;
                margin:0 0 0 21px;
                cursor:pointer;
                &__native-control{
                    -webkit-appearance: checkbox;
                    visibility: hidden;
                    width:18px;
                    height: 18px;

                    &.checked + .insights-filter__list__item__background,
                    &:checked + .insights-filter__list__item__background{
                        
                        
                        .icon{
                            stroke:$icon-color; 
                        }
                    }
                }

                &__background{
                    
                    position: absolute;
                    top:0;
                    left:0;
                    width:18px;
                    height: 18px;
                    cursor: pointer;

                    .icon{
                        width:16px;
                        height: 16px;
                        stroke-width:1px;
                        stroke:$icon-inactive-color;
                        
                        
                    }
                }
            }
        }
    }
    &-item{
        margin:0 $gutter*2;
        
        
        &__text{
            padding:20px $gutter*3 $gutter*3 $gutter*3;
        }
        &__meta{
            display:flex;
            justify-content: space-between;
            flex-direction: row;


            @include bp-above-tablet {
                flex-direction: column;
                margin-bottom: $gutter;
            }
           
            @include bp-above-desktop-wide {
                flex-direction: row;
                margin-bottom:$gutter;
            }
        }

        &__date{
            font-style: italic;
            font-size:12px;
            color: $date-color;
            float:right;
            line-height: 15px;

          
        }

        &__title{
            font-size:16px;
            color: $header-color;
            font-weight:800;
            margin-top:3px;
            margin-bottom:0;
            word-wrap: break-word;
           
        }
        &__tags{
            color: $icon-color;
            font-size:12px;
            font-weight:600;
            text-transform: uppercase;
            margin-bottom:0;
            flex-grow:1;

            span{
                line-height: 1;
                padding-left: 8px;


                + span{
                    position: relative;
                    margin-left: 8px;
                    

                    &:before{
                        content:"";
                        display: block;
                        position: absolute;
                        left:0;
                        top:2px;
                        width:1px;
                        height:12px;
                        background: currentColor;
                    }
                }
            }
        }
        
        &__icon{
            stroke-width:1px;
            width:15px;
            height:15px;
            color:currentColor;
            position: relative;
            top:3px;
            stroke:$icon-color; 
        }
    }
    &-subjects{
        position: relative;
        transition: max-height 150ms ease-out;
        overflow:hidden;
        max-height: 500px;
        margin:0 -3px;

        &.concealed{
            max-height: 200px;

            @include bp-above-desktop {
                max-height: none;
            }

            .insights-subjects__overlay{
                display:block;
                @include bp-above-desktop {
                    display:none;
                }
            }
        }

        &__list{
            list-style-type: none;
        }
        &__item{
            
            border-radius: 1.8px;
            background-color: #f8f9fa;
            margin:0 3px $gutter 3px;

            &:hover {
                @include mdc-elevation(4);
              }
            &__link{
                

                display:block;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
                color: $header-color;
                padding:8px $gutter*3 8px 0;

                span{
                    background-repeat:no-repeat;
                    background-size:contain;
                    background-position:100% 50%;
                    display:block;
                    padding:11px 20px;
                }
            }
           
        }

        &__overlay{
            display:none;
            position: absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #fff 100%);
            

            @include bp-above-desktop {
                display:none;
            }

            button{
                position: absolute;
                bottom:0;
                text-align: center;
                width:100%;
                font-size:13px;
                color: #3d3d3d;
                padding:20px 0;
            }
        }
    }
    &-navigation{
        
        background-image: url('/assets/images/insights/sticky-top@3x.png');
        background-repeat: no-repeat;
        background-size:cover;
        color:#fff;
        background-color:$header-color;


        @include  bp-until-desktop-wide {
            margin-top:58px;
        }


        .mdc-layout-grid{
            padding-top:$gutter;
            padding-bottom:$gutter;
        }
        
        
        a{
            color:#fff;
            text-decoration: none;
        }

        &__back{
            font-size: 16px;
            font-weight: 800;
            display:flex;
            height:100%;
            min-height: 55px;
            align-items: center;
            @include  bp-until-desktop-wide {
                font-size: 14px;
                padding:$gutter 0;
            }
            .icon{
                margin-right:15px;
                stroke-width:4px;
                stroke:#fff;
                color:#fff;
                height:19px;
                

                @include  bp-until-desktop-wide {
                    
                    height:12px;
                    stroke-width:9px;
                    margin-right:10px;
                    
                }
            }
        }
        &__subject{
            display:flex;
            height:100%;
            justify-content: flex-end;
            align-items: center;
            font-size:14px;
            @include  bp-until-desktop-wide {
                display:none;
            }

            > span{
                opacity: 0.6;
                font-weight: normal;
                font-style:italic;
            }
            a{
                background-repeat:no-repeat;
                background-size:auto 85%;
                background-position:93% 50%;
                position: relative;
                display:block;
                padding:18px 20px;
                margin-left:$gutter*2;
                width:255px;
                display:block;

                &:before{
                    content:"";
                    display: block;
                    background-color: #f8f9fa;
                    border-radius: 2px;
                    opacity: 0.12;
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }

                &:hover{
                    @include mdc-elevation(4);
                    

                }
                
            }
            
        }
       
        
    }
    &-progress{
        background: linear-gradient(to right, $icon-color var(--scroll), transparent 0);
        background-color:$icon-color;
        z-index:10000;
        background-repeat: no-repeat;
        width:0;
        height: 8px;
        //position: fixed;
        top:58px;

        @include bp-above-desktop-wide {
            //position:static;
        }

        &.fixed-element{
            position: fixed;
            top:58px;
            left:0;

            @include bp-above-desktop-wide {
                top:0;
            }
        }
  
    }
    &-article{
        @include  bp-until-desktop-wide {
            padding-top:0;
        }
        .article__date{
            margin-bottom:0;
            margin-top:0;
        }
    }
    &-recommended{
        &__list{
            list-style-type: none;
            margin:$gutter 0 $gutter*4 0;
            padding-bottom:$gutter*2;
            border-bottom:solid 1px $border-color;
        }
        &__item{
            border-bottom:solid 1px $border-color;

            &__link{
                display:block;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.71;
                color: $header-color;
                padding:$gutter*2 0 $gutter*2 0;
                
                
                @include bp-above-desktop-wide {
                    padding:$gutter*2 20% $gutter*2 0;
                }

                h3{
                    font-size: 14px;
                    font-weight: 600;
                    margin:0;
                }
            }

            &:last-child{
                border-bottom:none;
            }
        }
        
    }
    &-hero{
        .hero-topic__topic{
            span{
                padding:$gutter*2 $gutter*10 0 $gutter*10;
                @include bp-above-desktop {
                    padding:$gutter*5 $gutter*19 0 $gutter*19;
                   
                  }
            
            }
        }
    }
}

.section--insights{
    padding-top:70px;
    @include bp-above-desktop-wide {
        padding-top:0;
    }

    h1{
        font-size: 16px;
        color: #000000;
        margin-bottom:$gutter*2;
        @include bp-above-desktop {
            font-size:24px;
            margin-bottom:$gutter/2;
        }
    }
}

.mdc-button{
    &__theme-opac
    {
        opacity: 0.2;
        border-radius: 3px;
        border: solid 1px #e6e6e6;
        $text-color: #ffffff;
    }
}

.mdc-button--transparent-white {
    @include mdc-button-ink-color(white);
    border: solid;
    border-radius: 3px;
    border-width: 1px;
    border-color: rgba(255,255,255,0.30);
}

.mdc-button--transparent-black {
    @include mdc-button-ink-color(black);
    border: solid;
    border-radius: 3px;
    border-width: 1px;
    border-color: rgba(0,0,0,0.25);
}

.mdc-button--transparent{    
    border: solid;
    border-radius: 3px;
    border-width: 1px;
    border-color: rgba(0,0,0,0.25);

    &__blue{
        @include mdc-button-ink-color($header-color);
    }
}

.banner{
    height: 48px;
    position: relative;
    background-size: cover;
  
    @include bp-only-phone {
      display: none;
    }
    
    .hero + & {
      height: 0;
    }

    &__logo{
        z-index: 1;
        margin: auto;
        display: table;
        top: 50px;

        &__newsletter {
            top: 0px;
            margin-bottom: -70px;
        }

        &__linkedin {
            top: 0px;
            margin-bottom: -50px;
        }
    }

    &__text-white{
        color: white
    }
    &__text-black{
        color: black
    }
}

.banner-grid-wide {
    width: 100%;
}

.insight-img-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.insight-promo-block {
    min-height: 369px;
}

.insight-promo-title {
    margin-bottom: 0px;
}

.insight-promo-image {
    max-width: 100%;
}

.insight-promo-richtext {
    color: $header-color;
}

.insight-promo-content {
    margin-top: 36px;
}

.insight-sidepanel-margin {
    margin-bottom: $gutter*6;
}

.insight-span-override {
    @each $size in map-keys($mdc-layout-grid-columns) {
        @include mdc-layout-grid-media-query_($size) {
            $gutter: map-get($mdc-layout-grid-default-gutter, $size);
            .mdc-layout-grid__cell--span-0, .mdc-layout-grid__cell--span-0-#{$size} {
                @include mdc-layout-grid-cell-span_($size, 0, $gutter);
                display: none;
            }
        }
    }   
}


.insights-linkedin-icon-lg {
    width: 76px;
    height: 76px;
}

.insights-linkedin-icon-sm {
    width: 36px;
    height: 36px;
}

.insights-linkedin-wide {
    &__section {
        margin-top: 0px;;
    }
    h2, p {
        margin-left: 120px;
        margin-right: 120px;

        @include bp-only-phone {
            margin-left: 24px;
            margin-right: 24px;
        }
        @include bp-only-tablet {
            margin-left: 64px;
            margin-right: 64px;
        }
    }
    h2 {
        font-size: 24px;
        margin-top: 4px;
        line-height: normal;
    }
    p {
        font-size: 18px;        
    }
    a {
        margin-top: 12px;
        margin-bottom: 32px;
    }
}

.insights-linkedin-sidepanel {
    padding: 0px 24px;

    .header-container {
        display: flex;
        align-items: center;
        padding: 24px 0px;

        .header-icon {
            margin-left: 0px;
            margin-right: 12px;
        }
    }
    h2 {
        line-height: normal;
        margin: 0px;
        font-size: 18px;    
        text-align: left;
    }
    p {
        font-size: 16px;    
        text-align: left;
    }
    a {
        margin-top: 12px;
        margin-bottom: 24px;
    }
}
.insights-newsletter-icon-lg {
    width: 108px;
    height: 108px;
}

.insights-newsletter {
    padding: 95px 24px 0px 24px;

    p {
        font-size: 24px;
        font-weight: bold;
        line-height: normal;
        max-width: 405px;
        margin: 0px auto;
    }
    a {
        margin-top: 27px;
        margin-bottom: 40px;
    }
}

.insights-newsletter-pattern {
    background-image: url('/assets/images/bg-NewsLetter.png');
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
}

.insights-newsletter-sidepanel {
    padding: 95px 24px 0px 24px;

    p {
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
    }

    a {
        margin-bottom: 40px;
    }
}

.insights-banner-margin {
    margin-bottom: 64px;;
}

.insights-teaser-header {
    color: $header-color;
}

.insights-banner-icon {
    height:4.0rem;
    width:auto;
}

.insights-banner-inline {
    display: inline-block;
}

.insight-teaser-tile {

    height: 350px;
    position: relative;

    @include bp-above-desktop {
        height: 450px;
    }

    &__container {
        width: 100%;
        height: 100%;
        padding: 24px; 
        display: flex;
        flex-direction: column;
    }     

    &__title {
        margin-top: 0px;
    }
    
    &__tile-text {
        flex: 1;
        margin-bottom: 20px;
        overflow: auto;
    }

    &__tile-button {
        margin-top: auto;
        flex-direction: column;
        justify-self: flex-end;
    }
}

.insight-disclaimer-banner-block {
    color: rgba(0, 0, 0, 0.25);
    p {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.86;
        letter-spacing: normal;
    }
}

.insights-article-block-empty-state {
    text-align: center;
    color: $date-color;
    @include bp-above-desktop {
        // center block in non-aligned spans
        transform: translateX(-5%);
    }    
}

.insights-overview-bottom-content {
    padding-top: 40px;
}

.insights-icon-padding{
    padding-right: 10px;
}
.insights-font-size--xxs{
    font-size:20px;
}

.insights-hidden{
    display:none;
}