﻿// MDC layout grid addons

.mdc-layout-grid {

    &--set-width {

        &-100 {
            width: 100%;
        }
    }

    &--remove-padding {
        padding: 0px !important;
    }

    &--remove-padding-phone {
        @media screen and (max-width: 600px) {
            padding: 0px !important;
        }        
    }
}

.mdc-card {

    &--gray {
        background: #c7c7c7;
    }

    &__actions {
        
        &--remove-padding {
            padding: 8px 0px;
        }
    }
}