//Add custom dropshadow to highcharts tooltip
.nyk-highcharts-tooltip {
    display: block;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;

    &__title{
        font-weight: bold;
        padding: 5px 15px;
        white-space: nowrap;
    }
    &__item{
        display: flex;
        align-items: center;
        padding: 5px 15px;
        white-space: nowrap;
    }
    &__color{
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 10px;
    }
    &__name{
        margin-right: 20px;
        font-size: 11px;
    }
    &__grow{
        flex-grow: 1;
    }
    &__value{
        font-size: 11px;
        font-weight: 600;
        color: #020447;

        &__first{
            position:relative;
            &:after{
                content:"-";
                position:absolute;
                right:-5px;
            }
        }
    }
}
.nyk-highcharts-text{
    color: #333;
    font-size: 12px;
}

.highcharts-tooltip{
    z-index: 10000;
}
//nyk-donut-chart
.circle-chart-container {
    width: 100%;
    height: 70%;
    padding: 10px;
    margin: 15% auto;
    @include bp-until-tablet {
        width: calc(100% + 60px);
        height: 100%;
        margin: -15px 0px 0px -30px;
        padding: 10px 0px;
    }
}


.nyk-donut{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; // responsive square.
    padding-bottom: 100%;

    .highcharts-container, .highcharts-root{ //make overflow visible (hover and tooltips)
        overflow: visible !important;
    }
    &__inner{  //for centering inner content
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    &__chart{ //graph container
        height: 100%;
        width: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        [data-high-chart]{
            height: 100%;
            width: 100%;
        }

        &--bring-to-front{
            z-index: 1;
        }
    }

    //inner content
    &__content{
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &__morningstar{
        p{
            margin-top: 0;
            @include bp-until-desktop-wide{
                margin-bottom: 5px;
            }
        }
        &__rating{
            margin-right: 6px;
            display: inline-block;
            svg{
                fill: $mdc-theme-primary;
            }
            &--grey{
                svg{
                    fill: $color-grey-lighter;
                }
                svg.icon--solid {
                    fill: $color-grey-lighter;
                }
            }
        }
    }
    
    &__return{
        margin-top: 20px;
        height: 120px;
        @include bp-until-desktop-wide{
            margin-top: 0px;
            height: auto;
            p{
                margin-top: 0;
            }
        }
        mdc-select{ //on top of numbers
            position: relative;
            z-index: 1;

            select{
                margin: 0 auto;
            }
        }
        .mdc-select{
            min-width: 180px;

            @include bp-until-tablet{
                height: 40px;
            }
        }
        .mdc-select__selected-text{
            margin:0;
            line-height: 3.5rem;
        }
        &__percentage{
            display: flex;
            &--plus{
				color: $color-green-2;
            }
            &--minus{
                color: $color-red-2;
            }
            font-size: 44px;
            line-height: 1em;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
            justify-content: center;
            height: 115px;
            @include bp-until-desktop-wide{ 
                height: 46px;
                font-size: 30px;
                margin-bottom: 0;
                margin-top: 0;
                line-height: 2em;
            }
        }
        &__number{
            font-size: 64px;
            @include bp-until-desktop-wide{ 
                font-size: 52px;
            }
            @include bp-until-tablet{ 
                font-size: 36px;
            }
        }
    }
    &__risk{
        margin-top: 10px;
        p{ margin: 5px; }
        @include bp-until-desktop-wide{    
            margin-top:  10px;    
            p{
                margin-top: 0;
                margin-bottom: 5px;
            }
        }
        @include bp-until-tablet{
            margin-top: 5px;
        }    
        &__label{
            color: $color-grey-dark;
            margin-right: 3px;
            font-size: 20px;
            &:last-child{
                margin-right: 0;
            }
            &--active{
                color: $mdc-theme-primary;
                font-weight: 800;
                font-size: 30px;
                line-height: 20px;
            }
        }
    }
}

// override tab-panels on graphs
.nyk-returns-graph {
    .tab-panels {
        @include bp-until-tablet {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}