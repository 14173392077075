//================ TYPOGRAPHY ===================//
[class*='mdc-typography--title'] {
  margin-top: -0.5rem;
}

.mdc-typography--title-xxl {
  @include fluid(font-size, 54, 72);
  @include fluid(line-height, 62, 80);
}

.mdc-typography--title-xl {
  @include fluid(font-size, 36, 56);
  @include fluid(line-height, 36, 56);
}

.mdc-typography--title-lg {
  @include fluid(font-size, 30, 45);
  @include fluid(line-height, 32, 48);

}

.mdc-typography--title-md {
  @include fluid(font-size, 26, 34);
  @include fluid(line-height, 30, 40);

}

.mdc-typography--title-sm {
  @include fluid(font-size, 24, 30);
  @include fluid(line-height, 28, 40);

}

@mixin mdc-typography--title-xs {
  @include fluid(font-size, 20, 24);
  @include fluid(line-height, 24, 32);
}

.mdc-typography--title-xs {
  @include mdc-typography--title-xs;
}

.mdc-typography--eb {
  font-weight: map-get($mdc-typography-font-weight-values, extrabold);
}

.mdc-typography--bd {
  font-weight: map-get($mdc-typography-font-weight-values, bold);
}

.mdc-typography--sb {
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
}

.mdc-typography--rg {
  font-weight: map-get($mdc-typography-font-weight-values, regular);
}

.mdc-typography--lt {
  font-weight: map-get($mdc-typography-font-weight-values, light);
}

.mdc-typography--body-alt {
  margin: $gutter 0;
}

//================ Color ====================//
$color-nykredit-dark-indigo: #07094a;

//=============== Mobile Setup ===============//
$font-size-mobile: 20px;
$breakpoint-mobile: 768px;

//================ TOOLBAR ===================//
.mdc-toolbar__section {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.Totalkredit {
  .mdc-toolbar__section--align-start {
    padding-left: 0;
  }
}

//================ CHECKBOX ===================//
@keyframes mdc-checkbox-fade-in-background {
  0% {
    border-color: $mdc-checkbox-border-color;
    background-color: transparent;
  }

  50% {
    @include mdc-theme-prop(border-color, $color-turquoise);
    @include mdc-theme-prop(background-color, $color-turquoise);
  }
}

@keyframes mdc-checkbox-fade-out-background {

  0%,
  80% {
    @include mdc-theme-prop(border-color, $color-turquoise);
    @include mdc-theme-prop(background-color, $color-turquoise);
  }

  100% {
    border-color: $mdc-checkbox-border-color;
    background-color: transparent;
  }
}

.mdc-checkbox__native-control:checked {
  ~.mdc-checkbox__background {
    @include mdc-theme-prop(border-color, $color-turquoise);
    @include mdc-theme-prop(background-color, $color-turquoise);
  }
}

.mdc-checkbox__native-control:disabled~.mdc-checkbox__background {
  //disabled border and background-color
  border-color: $color-grey-medium;
}

//================ BUTTONS ===================//
.mdc-button {
  @include mdc-button-container-fill-color(transparent);
  @include mdc-button-ink-color(text-primary-on-light);
  //@include mdc-ripple-color(black, 0.32);

  align-items: center;
  display: inline-flex;
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
  line-height: 1;

  background-color: transparent;
  @include mdc-theme-prop(color, text-primary-on-background);

  .icon {
    margin-left: $gutter;
  }

  .mdc-list+& {
    margin-top: $gutter*3;
  }

  &--small {
    min-width: inherit;
  }

}

.mdc-button--primary.mdc-button--raised {
  @include mdc-button-filled-accessible($mdc-theme-primary);
}



.NykreditDk,
.Dotcom,
.invest,
.MitNykredit {
  font-family: "NykreditSans-Regular", "Open Sans";

  button {
    font-family: "NykreditSans-Regular", "Open Sans";
  }

  [class*='mdc-typography--title'] {
    font-family: "NykreditSans-Regular", "Open Sans";
    margin-top: -0.5rem;
  }

  .mdc-typography {
    font-family: "NykreditSans-Regular", "Open Sans";
  }

  .mdc-typography--medium {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
  }

  .mdc-typography--medium-bold {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 600;
  }

  .mdc-typography--title-xxl {
    font-family: "NykreditSans-Medium", "Open Sans";
    @include fluid(font-size, 54, 72);
    @include fluid(line-height, 62, 80);
  }

  .mdc-typography--title-xl {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
    letter-spacing: 0.1rem;
    @include fluid(font-size, 36, 56);
    @include fluid(line-height, 36, 56);
  }

  .mdc-typography--title-lg {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
    @include fluid(font-size, 30, 45);
    @include fluid(line-height, 32, 48);

  }

  .mdc-typography--title-md {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
    @include fluid(font-size, 26, 34);
    @include fluid(line-height, 30, 40);

  }

  .mdc-typography--title-sm {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
    @include fluid(font-size, 24, 30);
    @include fluid(line-height, 28, 40);

  }

  @mixin mdc-typography--title-xs {
    font-family: 'NykreditSans-Medium';
    font-weight: 500;
    @include fluid(font-size, 20, 24);
    @include fluid(line-height, 24, 32);
  }

  .mdc-typography--title-xs {
    @include mdc-typography--title-xs;
  }

  .mdc-typography--body-xxxs {
    font-family: "NykreditSans-Regular";
  }

  .mdc-typography--eb {
    font-family: "NykreditSans-ExtraBold";
    font-weight: map-get($mdc-typography-font-weight-values, extrabold);
  }

  .mdc-typography--bd {
    font-family: "NykreditSans-Medium";
    font-weight: map-get($mdc-typography-font-weight-values, bold);
  }

  .mdc-typography--sb {
    font-family: "NykreditSans-Medium";
    font-weight: map-get($mdc-typography-font-weight-values, semibold);
  }

  .mdc-typography--rg {
    font-family: "NykreditSans-Regular";
    font-weight: map-get($mdc-typography-font-weight-values, regular);
  }

  .mdc-typography--lt {
    font-family: "NykreditSans-Regular";
    font-weight: map-get($mdc-typography-font-weight-values, light);
  }

  .mdc-typography--body-alt {
    font-family: "NykreditSans-Regular", "Open Sans";
    margin: $gutter 0;
  }

  .mdc-layout-grid {
    &__three-section {
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;
    }
  }

  .mdc-image-container {
    display: flex;
    align-items: center;
  }

  .mdc-button {
    font-family: "NykreditSans-Medium", "Open Sans";
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    border-radius: 89px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;

    &::before {
      background-color: #f7f6f5 !important;
    }

    &__arrow-button {
      display: inline-flex;
      align-items: center;
      height: 44px;

      &__link-button {
        max-width: 350px;
        padding: 0;
        color: #f7f6f5;

        &__link {
          display: contents;
          text-decoration: none;
        }

        &__text {
          color: $color-nykredit-dark-indigo;
          flex-grow: 1;
          margin-right: 24px;
          box-shadow: none;
          transition: color 0.3s ease;
        }

        &__icon {
          box-shadow: none;
          display: flex;
          width: 20px;
          height: 0.7rem;
          margin-right: 20px;

          transition: filter 0.3s ease-out;
          -webkit-transition: transform 0.3s ease-out;
          -moz-transition: transform 0.3s ease-out;
          -ms-transition: transform 0.3s ease-out;
          -o-transition: transform 0.3s ease-out;
          transition: transform 0.3s ease-out;
        }

        &:hover {
          .mdc-button__arrow-button__link-button__text {
            color: #0f1e82;
          }

          .mdc-button__arrow-button__link-button__icon {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
          }
        }
      }


      &--filled-blue {
        background-color: #0f1e82;
        max-width: 400px;
        padding: 10px 32px;

        &--link {
          display: contents;
          text-decoration: none;
        }

        &--text {
          color: white;
          // flex-grow: 1;
          // padding: 15px 24px 15px 24px;
          box-shadow: none;

          @media (max-width: 768px) {
            .mdc-button__arrow-button--filled-blue--text {
              font-size: 16px;
            }
          }

          /* Media query for mobile devices */
          @media (max-width: 480px) {
            .mdc-button__arrow-button--filled-blue--text {
              font-size: 16px;
            }
          }
        }

        &--icon {
          box-shadow: none;
          display: flex;
          width: 20px;
          // margin-right: 20px;
          margin: 11px 0 10px 40px;
          height: 0.7rem;
          -webkit-transition: transform 0.3s ease-out;
          -moz-transition: transform 0.3s ease-out;
          -ms-transition: transform 0.3s ease-out;
          -o-transition: transform 0.3s ease-out;
          transition: transform 0.3s ease-out;
        }

        &:hover {
          background-color: $color-nykredit-dark-indigo;

          .mdc-button__arrow-button--filled-blue--icon {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
          }
        }

        &:active {
          background-color: #131313;
        }

        &:disabled {
          background-color: #ede8e6;
          color: #948d86;
        }
      }


      &--filled-neutral {
        background-color: white;
        max-width: 400px;

        &--link {
          display: contents;
          text-decoration: none;
        }

        &--text {
          color: $color-nykredit-dark-indigo;
          flex-grow: 1;
          padding: 15px 24px 15px 24px;
          box-shadow: none;
        }

        &--icon {
          box-shadow: none;
          display: flex;
          width: 20px;
          margin-right: 20px;
          height: 0.7rem;

          -webkit-transition: transform 0.3s ease-out;
          -moz-transition: transform 0.3s ease-out;
          -ms-transition: transform 0.3s ease-out;
          -o-transition: transform 0.3s ease-out;
          transition: transform 0.3s ease-out;
        }

        &:hover {
          background-color: #ede8e6;

          .mdc-button__arrow-button--filled-neutral--icon {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
          }
        }

        &:active {
          background-color: #ccc3b9;
        }

        &:disabled {
          background-color: #ede8e6;
          color: #948d86;
        }
      }

      &--outlined {
        border-color: $color-nykredit-dark-indigo;
        border-style: solid;
        border-radius: 89px;
        border-width: 1px;
        max-width: 400px;


        &--short-width {
          max-width: 300px;
        }

        &--white-border {
          border-color: white;
        }

        &--link {
          display: contents;
          text-decoration: none;
        }

        &--text {
          color: $color-nykredit-dark-indigo;
          flex-grow: 1;
          padding: 15px 24px 15px 24px;
          box-shadow: none;

          &--white-text {
            color: white;
          }
        }

        &--icon {
          box-shadow: none;
          display: flex;
          width: 20px;
          margin-right: 20px;
          height: 0.7rem;

          -webkit-transition: transform 0.3s ease-out;
          -moz-transition: transform 0.3s ease-out;
          -ms-transition: transform 0.3s ease-out;
          -o-transition: transform 0.3s ease-out;
          transition: transform 0.3s ease-out;

        }

        &:hover {
          border-width: 2px;

          .mdc-button__arrow-button--outlined--icon {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
          }
        }

        &:hover &--icon {
          -webkit-transform: translateX(4px);
          -moz-transform: translateX(4px);
          -ms-transform: translateX(4px);
          -o-transform: translateX(4px);
          transform: translateX(4px);
        }

        &:active {
          background-color: #ede8e6;
        }

        &:disabled {
          border-color: #948d86;
          color: #948d86;
        }
      }
    }
  }

  .footer-seperator {
    justify-content: center;
    display: flex;
    position: relative;
    align-items: center;
    height: 70px;
    max-width: 1300px;
    margin: auto;

    &__line {
      width: 100%;
      height: 1px;
      border: solid 0.5px rgba(19, 19, 19, 0.2);
    }

    &__icon-wrapper {
      justify-content: center;
      display: flex;
      position: absolute;
      right: 0;
    }

    &__icon {
      border-radius: 50px;
    }
  }

  .footer-container {
    font-family: "NykreditSans-Regular", "Open Sans";
    display: grid;
    grid-template-rows: auto;
    background-color: #49bca3 !important;
    color: $color-nykredit-dark-indigo;
    justify-items: center;
    text-align: center;
    padding: 80px 0;

    --extra-margin {
      margin-top: 80px;
    }

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    &__title-container {
      padding: 0px;

      &--title {
        font-size: 45px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin: 0px;

        @media (max-width: $breakpoint-mobile) {
          margin: 0px 20px 0px 20px;
        }
      }
    }

    &__body-container {
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      margin: 20px 10% 32px 10%;

      @media (max-width: $breakpoint-mobile) {
        font-size: $font-size-mobile;
        margin: 20px 10% 10px 10%;
      }
    }

    &__info-container {
      display: flex;
      justify-content: center;

      &__info-box {
        font-family: "NykreditSans-Medium", "Open Sans";
        text-decoration: none;
        border-radius: 58px;
        border-style: solid;
        border-width: 1px;
        color: $color-nykredit-dark-indigo;
        border-color: $color-nykredit-dark-indigo;
        transition: all 0.3s ease;
        margin-left: 16px;
        padding: 10px 30px;

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        }
      }

      @media (max-width: $breakpoint-mobile) {
        display: grid;
        margin-top: 15px;
        align-items: center;
      }
    }

    &__message-box {
      text-align: center;
      margin: 44px 10% 0px 10%
    }
  }
}


.mdc-button--light-blue {
  @include mdc-button-filled-accessible($color-ii-light-blue);
}

.mdc-button--white {
  @include mdc-button-filled-accessible($mdc-theme-background);
}

.mdc-button+.mdc-button {
  margin-left: 15px;
}

.mdc-fab {
  align-items: center;
  border-radius: 0;
}

.mdc-fab--plain {
  @include mdc-fab-accessible($color-white);
}

//================ INPUTS AND CONTROLS ===================//
.mdc-form-field {
  display: flex;
}

.mdc-form-field--inline {
  display: inline-flex;
}

.mdc-form-field--column {
  flex-direction: column;
}

.mdc-text-field-helper-text {
  width: 100%
}

.mdc-select {
  border-bottom: 1px solid $color-olive;
}

.mdc-text-field:not(.mdc-text-field--box) .mdc-text-field__input {
  border-bottom: 1px solid $color-olive;
  line-height: 1;
}

.mdc-text-field__input {
  border-radius: 0;
}

.mdc-text-field--box {
  margin-top: 0;
}

.NykreditDk,
.Dotcom,
.invest {
  .mdc-text-field {
    &--white {
      background-color: #fff;
    }

    &--grey {
      background-color: #f7f6f5;
    }
  }
}

//================ DRAWER ===================//
.mdc-drawer--temporary .mdc-list-item {
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
  text-transform: uppercase;

  &--lc {
    text-transform: none;
  }
}

/* IE fix. When file is minified, duplicate CSS-attributes are replaced by the lastest declared.
   For example: If the CSS-style includes the attribute 'background' twice, minification will remove everything, but the last occurance. I.e.:
   .my-style {
     background: #FFF; // This will be removed.
     background: #000;
   }
 */

.mdc-drawer--temporary__drawer {
  background: #fff;
  transform: translateX(-107%);
}

//================ MENU ===================//
.mdc-menu {
  .mdc-list-item {
    align-items: center;
    min-height: $gutter*6;
    //white-space: pre-wrap;
  }

  a {
    padding: $gutter 0;
    text-decoration: none;
  }
}

//================ TABS ===================//
.mdc-tab-bar--align-left {
  margin-left: 0;
}

.invest {
  .mdc-tab-bar__indicator {
    background-color: $color-purple;
  }
}

.mdc-tab-bar--overflow-x {
  overflow-x: auto;
}

//================ SELECTS ===================//
.mdc-select {
  background-position: calc(100% - 8px) center;

  &--generated {

    @include mdc-select-ink-color(rgba(0, 0, 0, 0));

    &.mdc-select--box {
      border-radius: 0;

      &:not(.mdc-select--disabled) {
        background-color: transparent;

        .mdc-select__native-control {
          color: rgba(0, 0, 0, 0.01); //should be transparent instead, but that doesn't work in IE11
        }
      }
    }

    .mdc-select__asterix {
      position: absolute;
      right: 0;
    }

    select {
      font-weight: 800;
      position: absolute;
      left: 0;
      top: 0;
      max-width: inherit;
      padding: 0;
      outline: none;
      border-bottom: none;

      &:focus::-ms-value {
        color: rgba(0, 0, 0, 0.01);
        background-color: transparent;
      }

      option {
        color: $color-dark;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
      }
    }
  }
}


//================ LIST ===================//
.mdc-list-item {
  align-items: flex-start;
  box-sizing: content-box;
  height: auto;

  &__text {
    white-space: normal;
  }
}

.mdc-drawer--temporary .mdc-list-item {
  align-items: center;
  height: $gutter*6;
}

.mdc-list-item__graphic {
  margin-right: 12px;
}

.mdc-list-item__graphic,
.mdc-list-item__start-detail {
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;

  &:before {
    content: '';
    background-color: $mdc-theme-secondary;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
  }
}

.mdc-drawer--temporary--selected {
  --mdc-theme-primary: $color-white;
  background: $color-red;
  color: $color-white;
}

//================ GRID ===================//
.mdc-layout-grid {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
}

.NykreditDk,
.Dotcom,
.invest {
  .mdc-layout-grid {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.mdc-layout-grid--no-spacing {
  padding-top: 0;
  padding-bottom: 0;
}

.mdc-layout-grid--no-side-spacing {
  padding-left: 0;
  padding-right: 0;
}

.mdc-layout-grid--no-bottom-spacing {
  padding-bottom: 0;
}

.mdc-layout-grid__cell {
  position: relative;
  min-width: 0;
}

.mdc-layout-grid__cell--center {
  text-align: center;
}

.mdc-layout-grid__cell--align-end {
  margin-left: auto;
}

.mdc-layout-grid__cell {
  &--span-0 {
    display: none;
  }

  @each $size in map-keys($mdc-layout-grid-columns) {
    @include mdc-layout-grid-media-query_($size) {
      $gutter: map-get($mdc-layout-grid-default-gutter, $size);

      // Span 0 cols classes.
      @at-root .mdc-layout-grid__cell--span-0,
      .mdc-layout-grid__cell--span-0-#{$size} {
        @include mdc-layout-grid-cell-span_($size, 0, $gutter);
        grid-column-end: none;
        margin: 0;
      }

      @for $i from 1 through map-get($mdc-layout-grid-columns, desktop) {

        //@for $i from 1 through 3 {
        // Breakpoint based order classes.
        &--order-#{$i}-#{$size} {
          @include mdc-layout-grid-cell-order($i);
        }

        &--span-#{$i}-#{$size} {
          display: block;
        }
      }
    }
  }
}

.mdc-form-field {
  .mdc-text-field {

    &:not(.mdc-text-field--disabled),
    &--invalid:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: $color-dark;
      }
    }

    &--outlined {
      &:hover {
        box-shadow: $color-dark 0px 0px 0px 1px;
      }

      margin-top: 8px !important;
      transition: box-shadow 0.15s ease-in-out;
      border-radius: 4px;
      box-shadow: #c9c3ba 0px 0px 0px 1px;

      input {
        border-bottom: transparent !important;
        font-weight: 400 !important;
        font-size: 16px;
        height: 100%;
        padding: 12px 12px 12px 12px;
      }

      .mdc-line-ripple {
        display: none;
      }

      .mdc-floating-label {
        color: rgba(0, 0, 0, 0.6) !important;
        bottom: 19px;
        background-color: inherit;
        padding-left: 6px;
        padding-right: 6px;
        left: 10px;
        background-color: inherit;
      }
    }
  }

  .mdc-text-field--disabled.mdc-text-field--outlined {
    opacity: 0.4
  }

  .mdc-text-field--invalid.mdc-text-field--outlined {
    box-shadow: $color-dark-red 0px 0px 0px 1px;

    .mdc-floating-label {
      color: $color-dark-red;
    }
  }

  .mdc-text-field--focused.mdc-text-field--outlined {
    border-color: transparent;
    box-shadow: $mdc-theme-light-primary 0px 0px 0px 2px;

    .mdc-floating-label {
      color: $mdc-theme-light-primary !important;
    }
  }
}




.mdc-list-item .mdc-slider {

  @include mdc-slider-color-accessible($color-blue);
  @include mdc-slider-rail-color($color-grey-lighter, 1);

}

.mdc-text-field--textarea {
  border: none;

  .mdc-text-field__input {
    border-bottom: 1px solid $color-olive;
    overflow: hidden;
    height: auto;

    &:focus {
      border: none;
    }
  }

  .mdc-floating-label {
    margin-top: 0;
    top: 16px;

    &--float-above {
      color: $color-blue;
    }
  }

}

.mdc-radio {

  @include mdc-radio-checked-stroke-color($color-blue);
  @include mdc-radio-ink-color($color-blue);
  @include mdc-radio-focus-indicator-color($color-blue);
  @include mdc-states($color-blue, true);

}

.mdc-checkbox {
  @include mdc-checkbox-container-colors(rgba($color-dark, 0.54), transparent, $color-turquoise, true);
  @include mdc-states($color-blue, true);
}

.mdc-switch {
  @include mdc-switch-track-color($color-blue);
  @include mdc-switch-knob-color($color-blue);
  @include mdc-switch-focus-indicator-color($color-blue);
}

.mdc-slider {
  @include mdc-slider-color-accessible($color-blue);
  @include mdc-slider-rail-color($color-grey-lighter, 1);
  @include mdc-slider-thumb-color($color-blue);
}

.hide-cell {
  display: none;
}