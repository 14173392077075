$margin-top-desktop:$gutter*10;
$margin-top-phone:$gutter*4;

.section--related{
    text-align: center;
}
.related-item{
    text-align: left;
    margin-top:$margin-top-desktop;

    @include bp-only-phone {
        margin-top: $margin-top-phone;
    }

    h3{
        margin-bottom:5px;
        margin-top:0;
        @include bp-above-desktop-wide{
            margin-top:-6px;
        }
    }
    ul{
        list-style:none;
    }
    
    &__link{
        display:block;
        padding:5px 0;
        color:$color-turquoise;
        line-height:1.75;
    }
    &__icon{
        margin-top:$margin-top-desktop;
        display: flex;
        justify-content: center;

        @include bp-only-phone {
            margin-top: $margin-top-phone;
        }
    }
}
