.carousel {
  max-width: 100%;
  overflow: hidden;
}

.carousel__inner {
  position: relative;

  @include bp-only-desktop {
    position: static;
  }
}

//================ LINKS ===================//
.carousel__link-wrapper {
  // Adjust for .mdc-layout-grid__inner
  @each $size in map-keys($mdc-layout-grid-columns) {
    @include mdc-layout-grid-media-query_($size) {
      margin-bottom: -#{map-get($mdc-layout-grid-default-margin, $size)};
    }
  }

  @include bp-only-phone {
    display: none;
  }
}

.carousel__link {
  text-transform: uppercase;
  padding: $gutter*3 0;
  position: relative;
  margin-bottom:0;

  &::after {
    background-color: $mdc-theme-primary;
    content: "";
    display: block;
    height: 4px;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 0.2s $mdc-animation-acceleration-curve-timing-function;
  }

  &:not(:first-child) {
    margin-left: $gutter*7;
  }

  &.is-active {
    &::after {
      opacity: 1;
      transition-timing-function: $mdc-animation-deceleration-curve-timing-function;
    }
  }
}

//================ SLIDES ===================//
.carousel__slide-wrapper {
  position: relative;
  max-height: 690px;
}

.carousel__slide {
  position: relative;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
}

//================ CAPTIONS ===================//
.carousel__caption-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  pointer-events: none;

  // Adjust for .mdc-layout-grid__inner
  @each $size in map-keys($mdc-layout-grid-columns) {
    @include mdc-layout-grid-media-query_($size) {
      margin-top: -#{map-get($mdc-layout-grid-default-margin, $size)};
    }
  }

  // Full screen width on small devices
  @include bp-until-desktop {
    margin-left: -#{map-get($mdc-layout-grid-default-margin, 'tablet')};
    width: 100vw;
  }

  @include bp-only-phone {
    margin-left: -#{map-get($mdc-layout-grid-default-margin, 'phone')};
  }

  // Counteract identical stacking themes removing top padding
  .carousel__caption.theme--turquoise {
    padding-top: 48px !important;
  }
}

.carousel__caption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transition: opacity .2s $mdc-animation-acceleration-curve-timing-function;
  position: absolute;
  overflow: hidden;
  padding: $gutter*4;

  @include bp-above-tablet {
    padding: $gutter*6;
  }

  @include bp-above-desktop {
    width: 450px;
    height: 450px;
    margin-top: -300px;
  }

  &.is-active {
    position: relative;
    opacity: 1;
    pointer-events: all;
    transition: opacity .3s $mdc-animation-deceleration-curve-timing-function .3s;
  }
}

.carousel__caption-link {
  align-self: flex-start;
  margin-top: auto;
}

//================ NAVIGATION ===================//
.carousel__navigation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.carousel__navigation {
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  @include bp-until-max {
    max-width: $max-width;
  }
}

.carousel__navigation-prev,
.carousel__navigation-next {
  pointer-events: all;
  position: absolute;
  transform: translateY(-50%);

  @include bp-until-desktop {
    top: 50%;
  }

  @include bp-only-desktop {
    bottom: $gutter*4;
  }

  @include bp-above-max {
    top: 50%;
  }
}

.carousel__navigation-prev {
  left: $gutter*2;

  @include bp-only-desktop {
    left: auto;
    right: $gutter*10;
    transform: translate(-100%, -50%);
  }
}

.carousel__navigation-next {
  right: $gutter*2;

  @include bp-only-desktop {
    right: $gutter*8;
  }
}
