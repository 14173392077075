﻿.netbank-framing {
    .body-wrapper {
        /* Need min-width to work in IE11 */
        min-height: 100vh;
    }
    .body-wrapper, #main-container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .netbank__iframe {
        flex: 1;
        /* Need width to work in IE11 */
        width: 100%;
        border: 0;
    }
    .netbank__hidden-iframe {
        width: 0;
        height: 0;
        border: 0;
    }
}