.js-epi-form-container {
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
    height: 0;
    width: 0;

    &--open {
        height: 100vh;
        width: 100%;
        opacity: 1;
        z-index: 99999;
    }
    .epi-input-field-text-container {
        .Form__Element__ValidationError {
            display: table-cell;
            padding-left: 15px;
        }

        .mdc-text-field-helper-text {
            padding-left: 15px;
        }
    }
    .mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
        color: #131313;
    }

    .epi-form-spinner {
        margin-top: 45px;
    }
}

.js-lead-form-success {
    min-width: 650px;
    margin-top: 15vh;
    flex-direction: column;
    @media screen and (min-width: 740px) {
        min-height: 80vh;
        justify-content: flex-start;
    }
    @media screen and (max-width: 740px) {
        min-width: 375px;
        padding: 0 20px;
        margin-top: 0;
    }

    &__title {
        margin-bottom: 45px;
        font-size: 56px;
        max-width: 60%;
        line-height: 60px;
        font-weight: 800;
    
        @media screen and (max-width: 740px) {
            font-size: 28px;
            line-height: 36px;
            max-width: 100%;
        }
    }

    &__msg {
        max-width: 80%;
        margin-bottom: 40px;
    
        @media screen and (max-width: 740px) {
            max-width: 100%;
            margin-bottom: 25px;
        }
    }

    &__btn {
        width: fit-content;
    }
}

.lead-epi-form-modal {
    .submit-button-container {
        padding-top: 30px;

        @media screen and (max-width: 740px) {
            padding-top: 20px;
        }

        .mdc-button + .mdc-button {
            margin-left: -5px;
        }
    }

    background-color: $color-white;
    inset: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: auto;

    &__container {
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;
    }

    &__contact-form-wrapper {
        padding: 10px 30px 20px 30px;

        img {
            width: 100%;
            height: auto;
        }

        picture, img {
            position: relative;
            top: 20vh;
            transform: translateY(-50%);

            @media screen and (max-width: 840px) {
                display: none;
            }
        }

        @media screen and (max-width: 1340px) {
            padding: 20px 60px 20px 60px;
        }

        @media screen and (max-width: 740px) {
            padding: 20px 0px 20px 0px;
        }
    }

    &__close-icon {
        z-index: 1;
        position: absolute;
        top: 70px;
        right: 0;
        background-color: $color-grey-white;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include bp-until-desktop {
            width: 60px;
            height: 60px;
            top: 0;
            margin-left: calc(100% - 60px)
        }
    }

    &__content {
        display: flex;
    }

    &__content-form {
        @media screen and (max-width: 1240px) {
            width: 100%;
        }
    }

    &__content-title {
        max-width: 600px;
    }

    /* Episerver class overrides */

    .js-lead-form-success-image {
        picture, img {
            display: none;
            @media screen and (max-width: 740px) {
                display: block;
                position: relative;
                top: 0;
                max-width: 85vw;
                -webkit-transform: unset;
                transform: unset;
            }
        }
    }
    .mdc-epi-form {
        .Form__MainBody {
            .FormStep__inner {
                .FormSubmitButtonContainer {
                    display: inline-block;

                    button {
                        position: absolute;
                        right: 10px;
                        bottom: 25px;
                    }
                }
            }
            .FormSubmitButton {
                margin-bottom: 0;
            }

            .epi-form-inline-element {
                display: inline-block;
            }

            .accordion__item {
                border-top: none;
                border-bottom: none;
            }

            .accordion__item-label {
                display: none;
            }

            .lead-text-element-aligner__inner {
                h3 {
                    margin-bottom: 45px;
                    font-size: 56px;
                    max-width: 60%;
                    line-height: 60px;

                    @media screen and (max-width: 1240px) {
                        max-width: 80%;
                    }

                    @media screen and (max-width: 740px) {
                        margin-bottom: 30px;
                        font-size: 28px;
                        line-height: 36px;
                        max-width: 100%;
                    }
                }
            }

            .FormChoice {
                margin-top: 36px;

                .Form__Element__Caption {
                    font-size: 18px;
                    font-weight: 300;
                    color: #131313;
                }

                .Form__Element__Caption--hidden {
                    display: none;
                }
            }

            .choice-fields {
                background-color: $color-grey-white;
                padding: 0.6rem 0 0.6rem 0.5rem;
                padding-bottom: 15px;
                margin-top: 15px;

                &--white {
                    background-color: white;
                    padding-bottom: 0;
                    margin-top: 0;
                }

                &--transparent {
                    background-color: transparent;
                    padding-bottom: 0;
                    margin-top: 0;
                }
            }

            .choice-container--multiline {
                margin: 6px 0 0 0;
            }

            .Form__NavigationBar {
                display: inline-block;
                @media screen and (max-width: 740px) {
                    padding-top: 15px;
                }
            }

            .lead-text-element-aligner__inner {
                position: relative;
                max-width: 630px;
                padding: 0 10px 1.5rem;

                .mdc-form-field {
                    max-width: 400px;

                    @media screen and (max-width: 1000px) {
                        max-width: 100%;
                    }

                    &.international-phone-field {
                        &__countrycode {
                            max-width: 100px;
                        }
                        &__number {
                            max-width: 200px;
                        }
                    }
                }
            }

            .epi-input-field-text-container {
                display: block;
                width: 400px;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                }

                @media screen and (min-width: 1340px) {
                    width: 300px;
                    display: inline-block;

                    &:nth-of-type(odd) {
                        .mdc-text-field--outlined {
                            margin-left: 10px;
                        }
                    }

                    &:nth-of-type(even) {
                        .mdc-text-field--outlined {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.epi-form-spinner {
    display: none;
    position: absolute;
    margin: 5px;
    height: 100%;
    width: 100%;

    &__next-step {
        margin-top: 13px;
    }

    &__submit {
        left: calc(50% - 40px);
    }
}

.FormSubmitButtonContainer--sending .js-epi-form-spinner {
    overflow: hidden;
    display: block;
}

.FormNextButtonClass--sending {
    color: #0f1e82 !important;
}

.FormNextButtonClass--sending .js-epi-form-spinner {
    overflow: hidden;
    display: block;
}


.NykreditDk, .Dotcom, .invest {
    .mdc-text-field--textarea .mdc-floating-label {
        background-color: transparent;
    }
}