.section--tab-slider{
    .title{
        margin-bottom: $gutter *3;
        & + p {
            margin-bottom: $gutter * 5;
        }
    }
    .tab-panels{
        border-top: 1px solid $color-grey-medium;
        .section--text-image{
            padding-top: 80px;
            .store-image + .store-image{ //appstore and google play images. 
                margin-left: $gutter *3;
            }
        }
        .section--file-list{
            padding-top: 80px;
        }
    }
}