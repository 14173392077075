@import '../../foundation/breakpoints';

.investment-fund-list-circle-graphs {
	height: 100%;
	width: 100%;

	&__circle {
		fill: none;
		stroke-linecap: round;
		animation: progress .75s cubic-bezier(.73, .16, .69, .94) forwards;
	}

	@keyframes progress {
		0% {
			stroke-dasharray: 0 100;
		}
	}

	
	&__content-wrapper {
		position: relative;
		width: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
	}
	&__stroke-wrapper {
		position: relative;
		width: 100%;
		min-height: 100%;
		min-height: 17rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&__stroke-wrapper-small {
		position: relative;
		width: 100%;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__graph-container {
		position: relative;
		width: 70px;
		height: 70px;
	}

	&__svg-container {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 17rem;
	}

	&__info {
		text-align: center;
	}

	&__info-list {
		list-style: none;
		display: flex;
		flex-direction: column;
		margin: 0;
		justify-content: center;
		width: 50%;
		min-width: 12rem;
		height: 11rem;;

		&-small {
			gap: 0.3rem;
		}
	}

	&__output-item {
		display: flex;
		gap: 0.5rem;
		justify-content: space-between;
		align-items: center;
	}

	&__output-type {
		font-size: 14px;
		margin: 0;

		@include bp-until-desktop {
			font-weight: bold;
		}

		&:before {
			position: relative;
			border-radius: 100%;
			left: -0.5rem;
			content: "";
			height: 0.5rem;
			width: 0.5rem;
			display: inline-block;
			background-color: var(--background-color);
		}
	}

	&__output-value {
		font-size: 20px;
		font-weight: 600;
		margin: 0;

		@include bp-until-desktop {
			font-size: 14px;
		}
	}

	&__multiple-graphs {
		width: 100%;
		display: flex;
	}
}