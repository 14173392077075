$padding: 16px;

.nk-range-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;

    &__slider {
        position: relative;
        z-index: 2;
        accent-color: #ccc3b9;
        height: 2px;
        cursor: pointer;
        background-color: #ccc3b9;

        &--active {
            accent-color: #07094a;
        }
    }

    &__labels-container {
        display: flex;
        justify-content: space-between;
        padding-bottom: $padding;

        label {
            margin: 0;
            font-size: 12px;
            color: initial;
        }

        &--min-max {
            padding-bottom: 0;
            padding-top: $padding;
        }
    }

}