.generic-table-filter-and-search {
    overflow: visible;
    height: 100%;

    &__filter-button-sticky {

        &.mdc-button.js-mdc-ripple.mdc-button--raised.mdc-ripple-upgraded {
            position: -webkit-sticky;
            /* Safari */
            position: sticky;
            top: 1rem;
            margin: 0;
            z-index: 5;
            background-color: $color-white;
    
        }
    }

    &__container {
        min-height: 5rem;

    }

}