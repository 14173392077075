
$desktop-bond-info-breakpoint : 1440px;
$tablet-bond-info-breakpoint : 1080px;
$mobile-bond-info-breakpoint : 720px;


.nyk-simple-generic-table {

    &--no-divider-spacing {
        margin-bottom: 10px;
    }

    width: 100%;
    border-collapse: collapse; 
    margin-bottom: 30px;
    position: relative;
    
    @include bp-only-phone {
        margin-bottom: 20px;
    }
    
    &__container {
        position: relative;
        
        @include bp-only-phone {
            background-color: #fff;
            box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);
        }
    }

    &__tooltip-container {
        padding-left: 8px;
        
        @include bp-only-phone {
            display: none;
        }

        &--mobile {
            display: none;
            @include bp-only-phone {
                display: inline-block;
                // position: absolute;
                right: 20px;
            }
        }
    }

    &__container-title {
        display: none;
        font-size: 16px;
        font-weight: 800;

        @include bp-only-phone {
            display: inline;
        }
    }

    CAPTION {
        position: absolute;
        left: -999999px;
        z-index: -1;
    }

    &__caption, & TH {
        font-weight: bold;
        text-transform: uppercase;
    }

    TR {
        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-light;
        }

        TD {
            text-align: right;
            font-size: 14px;
        }
        
        TH {
            background-color: $color-beige-dark;
            text-align: right;
            font-size: 12px;
            width: 10%;

            @include bp-only-phone {
                height: 40px;
            }
        }
        
        TH:first-child {
            @include bp-only-phone {
                color: transparent;
            }
        }
        
        TD:first-child {
            font-weight: 600;
            padding: 10px 15px;
        }
        
        TH, TD {
            padding: 10px 20px;
            width: 180px;
            white-space: nowrap;
            
            @include bp-only-phone {
                width: 140px;
                padding: 4px 20px;

                // Better odds of fitting data on small screens
                @media (max-width:450px) {
                    padding: 4px 10px;
                    white-space: unset;
                    text-align: center;
                }
            }
        }

        TD:nth-child(1),
        TH:nth-child(1) {
            text-align: left;
            width: auto;
            white-space: normal;
        }
    }

    &__cell { 

        &--disabled {
            opacity: 0.4;
        }

        &--hide {
            display: none;
        }
    }

    &__mobile-title-adjuster {
        @include bp-only-phone {
            height: 72px;
            display: flex;
            align-items: center;
            padding-left: 20px;
        }
    }
}