@mixin descending-z-index($count: 30){
  
  position: relative;
  .filter__select--wrapper{
    position: relative;
    $target: 0;
    $index: $count;
    @while $index > 0 {
      &:nth-child(#{$target}){ z-index: #{$index}; }
      $target: $target + 1;
      $index: $index - 1;
      }
    }
}


/* Select
--------------------------------------------------------------------------------*/
.filter {
  color: $color-dark;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  margin-bottom: $gutter*3;
  
  .mdc-menu {
	  -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), -2px 2px 5px -2px rgba(0,0,0,.12), 2px 2px 5px -2px rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), -2px 2px 5px -2px rgba(0,0,0,.12), 2px 2px 5px -2px rgba(0,0,0,.12);
    
    &--open{
      @include mdc-elevation(4);
    }
  }
    
  @include bp-above-desktop-wide {
	  .filter__input-container {
		display: flex;
		width: 80%;
    flex-wrap: wrap;
    @include descending-z-index;
	  }
	  
	  .filter__search-wrapper {
		width: 20%;
	  }
  }  
}

/*
.filter__select-wrapper {
  display: flex;
  flex: 1 1 auto;

  @include bp-until-desktop-wide {
    margin-top: $gutter*5;
    order: 1;
  }

  @include bp-above-desktop-wide {
    flex-direction: row;
    margin-right: $gutter*2;
  }
}
*/

.filter__overlay-open {
  /*
  background-color: $color-white;
  border-radius: $corner-radius;
  border: 2px solid $color-grey-dark;
  text-transform: uppercase;
  transition: background 0.3s $mdc-animation-standard-curve-timing-function;
  padding: 0 $gutter*2;
  */
  
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;

  &:hover,
  &:focus {
    /*background-color: $color-grey-light;*/
  }

  @include bp-until-desktop-wide {
    margin-left: $gutter*2;
    order: 1;
  }

  @include bp-above-desktop-wide {
    display: none;
  }
}

.filter__select--wrapper {
  @include bp-until-desktop-wide {
    display: none;
  }

  @include bp-above-desktop-wide {
    & + & {
      padding-left: $gutter*2;
    }
  }
}

.filter__select {
  @include mdc-elevation(1);
  //@include mdc-elevation-transition;
  background-color: $color-white;
  border: 1px solid #e8e8e8;
  border-radius: $corner-radius;
  flex: 1 1 auto;
  position: relative;

  &:hover,
  &:focus {
    @include mdc-elevation(4);
  }
  
  &-disabled:hover {
	@include mdc-elevation(1);
  }
}

.filter__select-toggle {
  width: 100%;
  text-align: left;
  padding-left: $gutter*2;
  position: relative;
  z-index:10;//put on top of dd
  background: #fff;

  .with-selection & {
    

    .filter__select-inner{
      width: calc(100% - #{$gutter*5});
    }
  }
  
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.filter__select-remove {
  height: 100%;
  position: absolute;
  padding-right: $gutter*2;
  padding-left: $gutter;
  top: 50%;
  transform: translateY(-50%);
  z-index:10;
  right: -4px;
}

.filter__select-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.filter__select-text {
  overflow: hidden;
}

.filter__select-title,
.filter__select-selction,
.filter__select-noselction {
  //@include truncate;
  line-height: 1.2;
}

.filter__select-title {
  margin:0 0  $gutter/2 0;
}

.filter__select-selction {
  color: $color-turquoise;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin:0;
}

.filter__select-noselction {
  color: $color-grey-dark;
  margin:0;
}

.filter__select-menu-anchor {
  position: absolute;
  left: 0;
  top: 0;
}


.filter__select-menu {
  width: 100%;
  left: 0;
  top: calc(100% - 4px);
  min-width: 0;

  &.mdc-menu--open{
    z-index:1;
  }

  /*
  &[data-width="120"]{
    width: 120%;
  }

  &[data-width="150"]{
    width: 150%;
  }
  &[data-width="200"]{
    width: 200%;
  }
  */
  
  .mdc-checkbox.mdc-checkbox--disabled + label{
      text-decoration: line-through;
      color: $color-grey-medium;
  }
}

.filter__select-menu {
  overflow-y:hidden;
	ul.mdc-menu__items {
		&.mdc-list {
			padding: $gutter+1 0;
			max-height: 400px;
		}
	}

	li.mdc-list-item {
		.mdc-form-field {
			z-index: 2;
			width: 100%;
            /* The mdc-menu select handler does not fire the MDCSimpleMenu:selected event when a child element of a menu item is clicked. We work around that by setting pointer-events: none on those child elements. */
            pointer-events: none;
			
			label {
				cursor: pointer;
				word-break: break-word;
				white-space: pre-line;
			}
		}
  }
}

.filter__mdc-select-container{
  width: 100%;
  @include bp-until-tablet {
    margin-bottom: $gutter *2;
  }
  @include bp-above-desktop-wide {
    width: 35%;
    flex-basis: 35%;
  }
  .mdc-select{
    width: 100% !important;
    height: 100%;
    min-height: 70px;
    padding-left: $gutter*2;

    @include mdc-elevation(1);
    //@include mdc-elevation-transition;
  }
}

/* Search
--------------------------------------------------------------------------------*/
.filter__search-wrapper {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @include bp-above-desktop-wide {
    margin-left: $gutter*2;
  }

  &--large{
    @include bp-above-desktop-wide {
      width: 65%;
      flex-basis: 65%;
    }
  }
}

.filter__search-form{
  width: 100%;
}

.filter__search-input {
  @include mdc-elevation(1);
  //@include mdc-elevation-transition;
  appearance: none;
  background-color: $color-white;
  border-radius: $corner-radius;
  border: none;
  display: block;
  height: 70px;
  width: 100%;
  padding: 0 $gutter*6 0 $gutter*2;
  margin:0;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &--large-text {
    font-size: 1.6rem;
  }

  @include bp-until-desktop {
    padding-right: 65px;
  }

  &::placeholder {
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    @include mdc-elevation(4);
  }


}

/* 
	"'Hey, how about we add a big ugly black X to all input fields by default?' 
	'That's the best idea I've ever heard. I don't understand why nobody else does this?!'"
	- Microsoft, Internet Explorer, 2017.
*/
.filter__search-input::-ms-clear {
    display: none;
}

.filter__search-icon {
  position: absolute;
  top: 50%;
  right: $gutter*3;
  transform: translateY(-50%);
  transition: opacity 0.2s $mdc-animation-acceleration-curve-timing-function;
  opacity: 0.2;

  &:hover,
  &:focus,
  &--active {
    opacity: 1;
  }
}

/* Overlay
--------------------------------------------------------------------------------*/
.filter__overlay {
  background-color: $color-white;
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  padding: $gutter*2;

  &.is-active {
    display: block;
  }
}

.filter__overlay-inner {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: $gutter*2;
  overflow: auto;
}

.filter__overlay-title {
  margin-top: $gutter*6;
  text-align: center;
}

.filter__overlay-close {
  position: absolute;
  top: $gutter*2;
  right: $gutter*2;
}

.filter__overlay-submit {
  justify-content: center;
  margin-top: $gutter*8;
  height: 70px;
  width: 100%;
}

/* Selection button
--------------------------------------------------------------------------------*/
.filter__selections {
  padding-top: $gutter*3;
}

.filter__selection {
	border-radius: $gutter*4;
    margin-right: $gutter;
	margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $gutter*1.5;
    height: $gutter*4;
  }

  * {
    pointer-events: none;
  }
  &-text{
    margin:0;
  }
}

.filter__selection-icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $gutter;
  width: $gutter*2;
  height: $gutter*2;
}

.filter__selection--default {
  background-color: $color-grey-light;
  color: rgba($color-dark, 0.85);

  .filter__selection-icon {
    background-color: #b7b7b7;
    color: $color-white;
  }
}

.filter__selection--reset {
  background-color: $color-turquoise;
  color: $color-white;

  .filter__selection-icon {
    background-color: $color-white;
    color: $color-turquoise;
  }
}
