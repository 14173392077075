// Styling for InformationArticlePage (P07)

.Totalkredit  .p-information-article-page {
    &__title {
        padding-top: 100px;
        padding-bottom: 0px;


        .article__meta{
            border: none; 
            padding: 0px;
        }

        .article__share{
            margin-top: -30px
        }
    }

    &__title h1 {
        margin-bottom: 0px;
    }

    @include bp-until-desktop{
        &__title {
            padding-top: 75px;

            .article__share {
                margin-top: -50px;
            }
        }        
    }
    
    @include bp-until-tablet{
        &__title {
            padding-top: 50px;
        }        
    }
}