.nyk-cell-type-checkbox {

    &--checked {
        background-color: $color-turquoise;
    }

    &__banner {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem 2rem;
        background-color: $color-turquoise;
        gap: 1rem;
        margin-top: 1rem;
        min-height: 100px;

        &__button {
            background-color: $color-blue;
            color: $color-white;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            font-size: 14px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 2px;
            margin-right: 1rem;
        }

        &__count {
            font-weight: 600;
            font-size: 16px;
            color: $color-blue-dark;
        }

        &__close {
            height: 1.75rem;
            width: 1.75rem;
            cursor: pointer;

            svg path {
                stroke: $color-blue-dark;
                stroke-width: 4px;
            }
        }

        &--hide {
            display: none;
        }

        @media (max-width: 780px) {
            justify-content: center;
        }

    }

}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: $color-turquoise;
}