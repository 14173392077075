.tab-panels{
    .js-tabpanel {
        display: none;
        &--active{
            display: block;
        }
    }
    &--no-side-spacing{
        padding-left: 0px;
        padding-right: 0px;
    }
}

.tab-panels-animate{
    .js-tabpanel {
        transition: opacity.3s $mdc-animation-standard-curve-timing-function;
        opacity: 0;
        height: 0;
        overflow: hidden;
        
        &--active{
            opacity: 1;
            height: auto;
            overflow:inherit;
        }
    }
}