//General variables
$filelist-itemMinHeight: 58px;
$filelist-itemPadding: $gutter+8;

.file-list {
  // padding: 0 $filelist-itemPadding;
  padding: 0;
}

.file-list__item,
.file-list__showmore {
  height: auto !important;
  min-height: $filelist-itemMinHeight;
  padding: 0 $filelist-itemPadding;
  align-items: center;
}

.file-list__item {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: $gutter $filelist-itemPadding - 2 $gutter $gutter*2 !important;
  background-color: $color-white;
  left: 0 !important;
  cursor: pointer;
  //@include mdc-elevation-transition;

  &:hover {
    @include mdc-elevation(4);
    z-index: 2;
  }

  &:not(:first-child) {
    border-top: none;
  }

  span {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.file-list__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.file-list__item__text {
  flex-basis: 100%;

  span {
    max-height: 46px;
    overflow: hidden;
  }
}

.file-list__item__secondary {
  text-transform: uppercase;
  margin-bottom:0;
}

.file-list__start-detail,
.file-list__item__end-detail {
  overflow: initial !important;
  white-space: initial !important;
}

.file-list__start-detail {
  width: $gutter*4;
  height: $gutter*4;
  margin-right: $gutter*3;

  svg {
    stroke-width: 1px !important;
  }

  &::before {
    display: none;
  }
}

.file-list__item__end-detail {
  width: $gutter*4;
  height: $gutter*4;
  margin-right: 0;
  margin-left: $gutter*3;
}

.file-list__header {
  align-items: center;
  background-color: $mdc-theme-primary;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
  color: $color-white;
  height: $filelist-itemMinHeight;
  min-height: $filelist-itemMinHeight;
  padding: 0 $filelist-itemPadding;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid $mdc-theme-primary;
  text-transform: normal;
  font-size: 1rem;

  .mdc-typography--body-sm{
    margin-bottom:0;
  }
}

.file-list__showmore {
  padding: 0;

  a {
    display:block;
    width: 100%;
    line-height: $filelist-itemMinHeight;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $color-dark;
    margin-bottom:0;
  }
  &:hover{
    background:none;
  }
}

.file-list--multi {
  .file-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .file-list__item {
    width: 100%;

    @include bp-above-desktop {
      width: 50%;

      &:nth-child(odd) {
        border-right: none;
      }
    }
  }
}
