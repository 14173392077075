.NykreditDk {
    .tk-bond-information {
        
        font-variant-numeric: unset;
        .tk-bond-information__isin-search-icon-container {
            svg {
                stroke: $color-blue-dark;
            }
        }
        .tk-bond-information__header-title {
            color: $color-blue-dark;
        }
        .tk-bond-information__header-subtitle {
            color: $color-blue-dark;
            font-weight: 300;
        }
        p, h2 {
            font-family: "Open Sans",Roboto,sans-serif
        }
        &__last-updated-wrapper {
            p {
                font-family: "Open Sans",Roboto,sans-serif
            }
        }
        .tk-bond-information__component-spinner-container .tk-loader .path {
            animation:
                tk-dash 1.4s ease-in-out infinite, 
                bondinformation-spinner-tk-colors (1.4*4s) ease-in-out infinite;

            @keyframes bondinformation-spinner-tk-colors {
                0% { stroke: $color-beregner-secondary; }
                33% { stroke: $color-beregner-primary; }
                66% { stroke: $color-beregner-secondary; }
                100% { stroke: $color-beregner-primary; }
            }
        }
    }
    .tk-bond-info-table {
        TR {
            TH {
                color: $color-blue-dark;
            }
        }
        .tk-bond-info-table__caption-row {
            &:not(:first-of-type) {
                background-color: $color-beige-dark;
            }
        }
        .tk-bond-info-table__accordion-trigger {
            color: #060849;
        }
        .tk-bond-info-table__accordion-arrow-container {
            svg {
                stroke: #060849;
            }
        }
        .tk-bond-info-table__link {
            color: $color-blue;
            font-family: "Open Sans",Roboto,sans-serif
        }
    }
    .tk-bond-info-table--type-a {
        TR {
            TD {
                &:nth-child(1) {
                    >* {
                        font-weight: 600;
                        color: #060849;
                    }
                }
                &:nth-child(2) {
                    >* {
                        font-weight: 600;
                        color: #060849;
                    }
                }
            }
        }
    }
    .tk-bond-info-table--type-b {
        TR {
            TD {
                &:nth-child(1) {
                    >* {
                        font-weight: 600;
                        color: #060849;
                    }
                }
                &:nth-child(2) {
                    >* {
                        font-weight: 600;
                        color: #060849;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 720px) {
        .tk-bond-info-table {
            .tk-bond-info-table__caption {
                background-color: $color-beige-dark;
            }
            .tk-bond-info-table__cell-mobile-label {
                font-family: 'GT-Eesti-Text';
                font-weight: 600;
                color: #060849;
            }
        }
        .tk-bond-info-table--type-a {
            TR {
                TD {
                    &:nth-child(1) {
                        >.tk-bond-info-table__cell-mobile-value {
                            font-weight: 400;
                            color: currentColor;
                        }
                    }
                    &:nth-child(2) {
                        >.tk-bond-info-table__cell-mobile-value {
                            font-weight: 400;
                            color: currentColor;
                        }
                    }
                }
            }
        }
        .tk-bond-info-table--type-b {
            TR {
                TD {
                    &:nth-child(1) {
                        >.tk-bond-info-table__cell-mobile-value {
                            font-weight: 400;
                            color: currentColor;
                        }
                    }
                    &:nth-child(2) {
                        >.tk-bond-info-table__cell-mobile-value {
                            font-weight: 400;
                            color: currentColor;
                        }
                    }
                }
            }
        }
    }
    .tk-bond-information__alarm-banner {
        background-color: #3c8de1;
    }

}