.social {
  display: flex;
}

.social__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: $gutter*2;
  }
}

.social__link-simple {
  &.social__link--facebook {
    color: $color-facebook;

    &:hover,
    &:focus {
      color: darken($color-facebook, 10%);
    }
  }

  &.social__link--twitter {
    color: $color-twitter;

    &:hover,
    &:focus {
      color: darken($color-twitter, 10%);
    }
  }

  &.social__link--linkedin {
    color: $color-linkedin;

    &:hover,
    &:focus {
      color: darken($color-linkedin, 10%);
    }
  }
}

.social__link-circle {
  border-radius: 100%;
  transition: background-color 0.3s $mdc-animation-standard-curve-timing-function;
  width: 45px;
  height: 45px;

  svg {
    color: $color-white;
  }

  &.social__link--facebook {
    background-color: $color-facebook;

    &:hover,
    &:focus {
      background-color: darken($color-facebook, 10%);
    }
  }

  &.social__link--twitter {
    background-color: $color-twitter;

    &:hover,
    &:focus {
      background-color: darken($color-twitter, 10%);
    }
  }

  &.social__link--linkedin {
    background-color: $color-linkedin;

    &:hover,
    &:focus {
      background-color: darken($color-linkedin, 10%);
    }
  }
}
