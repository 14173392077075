.accordion {
  width: 100%;
}

.accordion__item {
  border-top: 1px solid $color-grey-light;

  &:last-child {
    border-bottom: 1px solid $color-grey-light;
  }

  .icon {
    flex-shrink: 0;
  }

  .accordion__item-icon-opened {
    display: none;
  }

  &.is-open {
    .accordion__item-icon-closed {
      display: none;
    }

    .accordion__item-icon-opened {
      display: block;
    }
  }
}

.accordion__item-handler {
  width: 100%;
  
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.accordion__item-label {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: $gutter*2;
  text-align: left;
}

.accordion__item-content {
  transition: height .3s $mdc-animation-standard-curve-timing-function;
  overflow: hidden;
  height: 0;
}

.accordion__item-content-inner {
  padding: $gutter $gutter*2 $gutter*2 $gutter*2;

  .image {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .video {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
