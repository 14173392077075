dl.dl-list{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    dt{
        padding: 2px 0;
        flex: 0 0 35%;
        overflow: hidden; /* Prevents long words in labels from causing layout to break */
    }
    dd {
        padding: 2px 0 20px 0;
        margin-left: 0;
        
        flex: 65% 0 0;
        padding-left: 20px;
        max-width: 65%; /* Fix for IE issue in which box-sizing is not taken into account when using flex */  

        font-weight: 300;
    }
    dd + dd {
        margin-left: 35%;
    }
}