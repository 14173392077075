

//General parameters
//$itemPadding: $gutter+8;
$headerHeight: 60px;

.financial-calendar {
  position: relative;
  
  -ms-touch-action: pan-y;
  touch-action: pan-y;
    
  
  @include bp-until-desktop {
    overflow: hidden;
  }

  .mdc-layout-grid__cell {
    max-width: 100%;
  }
}

/* Date
--------------------------------------------------------------------------------*/
.financial-calendar__date {
  display: flex;
  align-items: center;
  margin-bottom: $gutter*2;

  @include bp-until-desktop {
    justify-content: space-between;
    padding: 0 $gutter;
  }
}

.financial-calendar__date-prev {
  margin-right: $gutter*2;
}

.financial-calendar__date-next {
  margin-left: $gutter*2;
  margin-right: $gutter*5;
}

/* Slides
--------------------------------------------------------------------------------*/
.financial-calendar__slide {
  margin-bottom: $gutter*2;

  > div {
    display: flex;
  }

  > div > div {
    position: relative;

    @include bp-until-desktop {
      &::before {
        background: url('/assets/images/financial-calendar-bg.png');
        content: '';
        position: absolute;
        left: $gutter;
        right: $gutter;
        top: $headerHeight;
        height: calc(100% - #{$headerHeight} - 5px);
        pointer-events: none;
      }

      &::after {
        border: 1px solid $color-grey-lighter;
        border-top-width: 0;
        content: '';
        position: absolute;
        left: $gutter;
        right: $gutter;
        top: $headerHeight;
        height: calc(100% - #{$headerHeight} - 5px);
        pointer-events: none;
        z-index: 1;
      }
    }
  }

  @include bp-until-desktop {
    overflow: visible !important;
  }

  @include bp-above-desktop {
    border-bottom: 1px solid $color-grey-lighter;
    position: relative;

    // Left wrapper border
    &::before {
      position: absolute;
      left: 0;
      top: $headerHeight;
      height: 100%;
      width: 1px;
      z-index: 1;
      content: '';
      background-color: $color-grey-lighter;
    }

    // Right wrapper border
    &::after {
      position: absolute;
      right: 0;
      top: $headerHeight;
      height: 100%;
      width: 2px;
      z-index: 1;
      content: '';
      border-left: 1px solid $color-white;
      background-color: $color-grey-lighter;
    }

    > div > div {
      background: url('/assets/images/financial-calendar-bg.png');
      position: relative;

      // Right item border.
      &::after {
        position: absolute;
        right: 0;
        top: $headerHeight;
        height: 100%;
        width: 1px;
        z-index: 1;
        content: '';
        background-color: $color-grey-lighter;
      }
    }
  }
}

.financial-calendar__slide-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.financial-calendar__slide-item {
  @include bp-until-desktop {
    height: 100%;
    padding: 0 $gutter;
    position: relative;
  }
}

.financial-calendar-showmore {
  margin: -$gutter*2 auto $gutter*2 auto;
  min-width: 0;
}

.financial-calendar__list {
  padding: 0 $gutter+8 0 $gutter+8;
  
  .financial-event {
    background-color: $color-beige;
  }
}

.financial-calendar__list-item {
  left: -16px;
  right: initial;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.financial-calendar__list-item,
.financial-calendar__list-header {
  height: auto !important;
  min-height: 58px;
  padding: 0 $gutter+8;
  align-items: center;
}

.financial-calendar__slidet-header-fix {
  position: absolute;
  top: 0;
  height: 6px;
  width: 100%;
  background: $mdc-theme-primary;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;

  @include bp-until-desktop {
    display: none;
  }
}

.financial-calendar__list-header {
  @extend .file-list__header;
  border-radius: 0;
  min-height: auto;
  margin-top: 5px;
  padding-top: 13px;
  padding-bottom: 18px;
  position: relative;

  @include bp-until-desktop {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.financial-calendar__list-item {
  border-bottom: 1px solid $color-grey-lighter;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $gutter*3 $gutter+8 - 2 $gutter*1.5 $gutter+8 !important;
  background-color: $color-white;
  cursor: pointer;
  //@include mdc-elevation-transition;

  &:last-child:not(:first-child) {
    border-bottom: none;
  }

  &:hover {
    @include mdc-elevation(4);
    z-index: 2;
    background-color: $color-white;
  }

  .mdc-list-item__text span {
    padding-bottom: $gutter;
  }
  .mdc-list-item__text{
    width: 100%;
    @include bp-above-tablet {
      min-height: 144px; //min height equals, 3 lines of heading and 2 lines of text. 
    }
    @include bp-above-desktop {
      min-height: 112px; //min height equals, 2 lines of heading and 2 lines of text. 
    }
  }
}

.financial-calendar__list-item-title {
  margin-bottom: $gutter;
  max-width: calc(100% - #{$gutter*5}); // IE11
  line-height: 2rem;
}

.financial-calendar__list-item-add {
  position: absolute;
  right: $gutter+8 - 2;
  top: $gutter*3;
}

.financial-calendar__list-item-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding-top: $gutter*4;

  a {
    color: $color-blue-dark;
    margin-left: -$gutter;
  }

  span {
    color: #6b6b6b;
    margin-bottom:0;
  }
}

.financial-calendar-prev,
.financial-calendar-next {
  position: absolute;
  top: 42%;
  z-index: 2;

  @include bp-until-desktop {
    display: none;
  }
}

.financial-calendar-prev {
  left: $gutter*2;
}

.financial-calendar-next {
  right: $gutter*2;
}

/* Drawer
--------------------------------------------------------------------------------*/
.financial-calendar__drawer {
  color: $color-dark;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 6;
  pointer-events: none;
}

.financial-calendar__backdrop {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.financial-calendar__drawer-inner {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: initial;
  right: 0;
  height: 100%;
  will-change: transform;
  width: calc(100% - 40px);
  max-width: 655px;
  background-color: $color-white;
  transform: translateX(100%);
  transition: 0.2s transform ease-in-out;
  z-index: 6;
  overflow-y: auto;
}

.financial-calendar__drawer-header {
  display: block;
  width: 100%;
  position: relative;

  img {
    width: 100%;
    &[src=''] {
      display: none; //fix IE11 bug, where img with no src is very tall. 
    }
  }
  
  .financial-calendar__drawer-close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    cursor: pointer;
  }
}

.financial-calendar__drawer-body {
  padding: $gutter*5;
  padding-bottom: 95px;

  @include bp-above-desktop {
    padding-bottom: 160px;
  }

  h3 {
    margin: $gutter*2 0;
  }

  .mdc-button {
    margin-top: $gutter*4;

    svg {
      padding-right: $gutter;
    }
  }

  .mdc-list-item__secondary-text {
    text-transform: uppercase;
  }

  .mdc-typography--body-lg-alt {
    padding-bottom: $gutter;
  }
}

.financial-calendar__drawer.is-active {
  pointer-events: all;

  .financial-calendar__backdrop {
    display: block;
  }

  .financial-calendar__drawer-inner {
    transform: translateX(0);
    @include mdc-elevation(6);
  }
}

.financial-calendar__drawer-item {
  & + & {
    margin-top: $gutter*4;
  }
}
.financial-calendar__drawer-documents{
  &--hidden{
    display:none;
  }
}
.financial-calendar__drawer-document {
  background-color: $color-beige-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $gutter*2;

  > div {
    padding-right: $gutter*2;
  }
  .mdc-typography--body-lg{
    margin-bottom:0;
    margin-top:0;
  }

  & + & {
    margin-top: 2px;
  }

  .mdc-list-item__secondary-text + & {
    margin-top: $gutter;
  }
}

.financial-calendar__drawer-document-body {
  color: $color-grey-dark;
}

.financial-calendar__drawer-document-more {
  display: inline-block;
  margin-top: 36px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.financial-calendar__drawer-bottom {
  border-top: 1px solid $color-beige-dark;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 6;
  max-width: 655px;
  width: calc(100% - 40px);
  padding: $gutter*2 40px;
  will-change: transform;
  transform: translateX(100%);
  transition: 0.2s transform ease-in-out;

  @include bp-above-desktop {
    padding: $gutter*6 40px;
  }

  &::before {
    background-color: $color-white;
    content: '';
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &__buttons {
    display: flex;
    align-items: center;

    &__add {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
  
      &:hover,
      &:focus {
        text-decoration: underline;
      }
        
      .icon {
        margin-right: $gutter;
      }
    }

    &__readMore {
      flex: 0 0 auto;
    }
  
  }

}

.financial-calendar__drawer.is-active + .financial-calendar__drawer-bottom {
  pointer-events: all;
  transform: translateX(0);
}
