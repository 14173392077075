﻿.regional-protection-card {

    ol {
        li {
            font-style: italic;
            font-weight: bold;
        }
    }

    .response-buttons {
        text-align: center;

        .mdc-button {
            justify-content: center;
        }
    }
}