.podcast{
    &-links{
        margin-bottom:40px;
        width:100%;
        @include bp-above-phone {
            //width:auto;
        }
        &__header{
            font-style:italic;
            font-size:18px;
            color: #07094a;
            opacity:0.3;
            @include bp-above-phone {
                font-size:14px;
            }
        }
        &__list{
            margin-top:5px;
            display:flex;
            justify-content: space-between;
            @include bp-above-phone {
                justify-content: flex-start;
            }
            
        }

    }
    &-link{
        height:48px;
        
        @include bp-above-phone {
            padding:0 27px 0 16px;
        }

        &__label{
            font-size:14px;
            text-transform: none;
            letter-spacing: 0.3px;
            font-weight:400;
            display:none;
            @include bp-above-tablet {
                display:inline;
            }
        }

        .mdc-button__icon{
            width:33px;
            height:33px;
            @include bp-above-phone {
                margin-left:0;
                margin-right:0;
            }
            
        }
        .icon{
            position: relative;
            &--spotify{
                @include bp-above-phone {
                    top:3px;
                    left:-3px;
                }
            }
            &--itunes{
                @include bp-above-phone {
                    left:-5px;
                }
            }
            &--soundcloud{
                @include bp-above-phone {
                    margin-right:8px;
                }
            }
        }
    }
}