.nyk-select-display-state-navigation {
    display: flex;
    justify-content: space-between;

    &__content-wrapper-left {
        align-self: left;
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    &__content-wrapper-right {
        align-self: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;

        &-title {
            color: $color-blue-dark;
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__display-button {
        svg {

            height: 18px;
            width: 18px;
            cursor: pointer;

            path {
                stroke: $color-grey-dark;
            }
        }

        &--active {

            svg {

                cursor: pointer;

                path {

                    stroke: $color-blue;

                }
            }
        }
    }
}