//============= SPACERS ==============
.spacer--xs {
  @include fluid(height, 20, 32);
}

.spacer--sm {
  @include fluid(height, 24, 48);
}

.spacer--md {
  @include fluid(height, 32, 64);
}
