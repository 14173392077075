﻿// NK Animations 
// Adjust transition duration in the variable beneath
$nk-duration: 0.5s;

.nk-fadefromleft{
    transition: all $nk-duration ease-in-out;

    &.ng-enter{        
        opacity: 0;
        transform: translateX(-20px);

        &.ng-enter-active {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    &.ng-leave {        
        opacity: 1;
        transform: translateX(0px);       

        &.ng-leave-active {
            opacity: 0;
            transform: translateX(20px);
        }
    }
}

.nk-blink {
    transition: all $nk-duration ease-in-out;

    &.ng-enter{
        opacity: 0;

        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave{
        opacity: 1;

        &.ng-leave-active {
            opacity: 0;
        }
    }
}