.mdc-split-image-text-row--content-area .button-list{
  display: flex;
  flex-wrap: wrap;

  > a {
    margin-top: 12px;
    margin-right: 20px;
    margin-left: 0px;
    min-width: 200px;
    flex: 1;
  }
}

@media (max-width:840px) {
    .aligned-button-container {
      display: flex;
      flex-direction: column;
      
      .mdc-button {
        width: 100%;
        margin-left: 0;
    }
      .mdc-button + .mdc-button {
        margin-top: 12px;
      }

    &--less-bottom-margin {
        
        .richtext {
            margin-bottom: 0px;
        }
    }


    }

    @media (max-width: 480px) {

      .button-list {
          display: flex;
          flex-wrap: wrap;
  
      }
      .button-list a.mdc-button{
              margin-top: 12px;
              margin-right: 20px;
              margin-left: 0;
              min-width: 200px;
              flex: 1;
          }
       
  }
  }
 