$text-primary-color: $color-blue;

$range-width: 100%;
$range-height: 8px;

$range-bar-height: 8px;
$range-border-radius: 0;
$range-bar-color: $color-white;
$range-bar-border-color: #000000;
$range-bar-border-size: 0;
$range-bar-fill: #fb264e;

$range-cursor-size: 23px;
$range-cursor-color: #fb264e;
$range-cursor-border-size: 0;
$range-cursor-border: #000000;

.center-image{
    display: block;
    margin: 0 auto;
}

.co2-beregner{
    display: flex;
    &__background{
        background-color: #ede8e6;
        padding: 40px;
    }
    &__header{
        text-align: center;
        color: $text-primary-color;

        @include bp-until-tablet {
            font-size: 18px;
        }
    }
    &__value{
        text-align: center;
        margin-top: 4%;
        margin-bottom: 0;
        color: $text-primary-color;

        @include bp-until-tablet {
            font-size: 44px;
            margin-top: 0;
            line-height: 48px;
        }
    }
    &__label{
        @include fluid(font-size, 16, 18);
        text-align: center;
        color: $text-primary-color;

        @include bp-until-tablet {
            font-size: 13px;
        }
    }
    &__main-image{
        width: 90%;
        margin-top: 10px;
    }
    &__sub-section{
        background-color: #ede8e6;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 15px;
    }
    &__sub-container{
        @include bp-until-tablet {
            padding: 10px 0 0 0;
        }
    }
    &__subvalue{
        text-align: center;
        color: $text-primary-color;
        font-size: 30px;
        font-weight: 800;
        margin: 0;

        @include bp-until-tablet {
            font-size: 21px;
        }
    }
    &__sublabel{
        text-align: center;
        color: $text-primary-color;

        @include bp-until-tablet {
            font-size: 14px;
        }
    }
    &__sub-image{
        width: 60px;
        height: 60px;

        @include bp-until-tablet {
            width: 50px;
            height: 50px;
        }
    }
    &__container {
        flex-grow: 1;
        position: relative;
        display: inline-block;
        height: $range-height;
        width: $range-width;
        margin-top: 15px;
    }

    &__fill {
        box-sizing: border-box;
        top: calc(50% - 4px);
        width: 50%;
        height: $range-bar-height;
        background: $range-bar-fill;
        z-index: 1;
        position: absolute;
        pointer-events: none;
    }
    &__volume-container {
        margin: 5px 17px;
        height: 30px;
        position: relative;
    }    
    // reset styles
    &__volume-slider {
        position: absolute;
        left: 0;
        -webkit-appearance: none;
        width: $range-width;
        height: 100%;
        background: transparent;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        cursor: pointer;
        
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
        }
        
        &::-ms-track {
            width: $range-width;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
            -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
        }
        
        &:focus {
            outline: none;
        }
        
        &::-moz-focus-outer {
            border: 0;
        }
    }
    
    // webkit browsers
    &__volume-slider {    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: $range-cursor-border-size solid $range-cursor-border;
            height: $range-cursor-size;
            width: $range-cursor-size;
            border-radius: 50%;
            background: $range-cursor-color;
            cursor: pointer;
            margin-top: -8px;
        }
        
        &::-webkit-slider-runnable-track {
            width: $range-width;
            height: $range-bar-height;
            cursor: pointer;
            background: $range-bar-color;
            border-radius: $range-border-radius;
            border: $range-bar-border-size solid $range-bar-border-color;
            -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
        }
    }
    
    // Firefox
    &__volume-slider {  
        &::-moz-range-thumb {
            border: $range-cursor-border-size solid $range-cursor-border;
            height: $range-cursor-size;
            width: $range-cursor-size;
            border-radius: 50%;
            background: $range-cursor-color;
            cursor: pointer;
        }
        
        &::-moz-range-track {
            box-sizing: border-box;
            width: $range-width;
            height: $range-bar-height;
            cursor: pointer;
            background: $range-bar-color;
            border-radius: $range-border-radius;
            border: $range-bar-border-size solid $range-bar-border-color;
            -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
            box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.5);
        }
    }
  
    // Internet Explorer
    &__volume-slider {    
        &::-ms-thumb {
            box-shadow: 0 0 0 $range-cursor-border-size $range-cursor-border;
            height: $range-cursor-size;
            width: $range-cursor-size;
            border-radius: 50%;
            background: $range-cursor-color;
            cursor: pointer;
            margin-top: 2px;
            box-sizing: border-box;
            border: 0;
            box-shadow: 0;
        }
        
        &:focus::-ms-thumb {
            border: 0;
        }
        
        &::-ms-track {
            width: $range-width;
            height: $range-bar-height;
            cursor: pointer;
            background: $range-bar-color;
            border-radius: $range-border-radius;
            border: $range-bar-border-size solid $range-bar-color;
            color: transparent;
            box-sizing: border-box;
        }
        
        &::-ms-fill-lower {
            background: $range-bar-fill;
            border-radius: $range-border-radius;
            border: $range-bar-border-size solid $range-bar-fill;
        }
        
        &::-ms-fill-upper {
            background: $range-bar-color;
        }
        
        &::-ms-tooltip {
            display: none;
        }
    }
}