$tablet-width : 840px;

.npa-association {
    display: flex;
    flex-direction: column;
    align-items: center; 

    &__title-container {
        
        @media (max-width:$tablet-width) {
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
        }
    }

    &__dropdown-container {
        @media (min-width:$tablet-width) {
            width: 280px;        }
    }

    &__download-button-container {
        text-align: center;
    }

    .richtext {
        color: #07094a;
    }

    &__overview-wrapper {
        max-width: 1110px;
        width: 100%;
    }

    &__section {
        padding: 0!important;
    }

    svg {
        color: currentColor;
    }

    &__title-with-icon-container {
        margin: 40px 0;

        h1 {
            display: inline; 
        }

        span {
            padding-left: 20px;
            position: relative;
            top: 8px;
        }
    }

    p {
        color: inherit;
    }

    h1 {
        font-weight: 900;
        font-size: 48px;
        text-align: center;

        @media (max-width:$tablet-width) {
            font-size: 30px;
        }
    }

    &__document-link {
        margin-bottom: 24px;
    }

    .mdc-layout-grid {
        padding: 0 24px; 

        @media (max-width:$tablet-width) {
            padding: 0; 
            width: 100%;
        }
    }

    &__sub-heading {
        margin: 10px;
        font-weight: 600;
        text-align: left;

        @media (max-width:$tablet-width) {
            margin: 10px 0 -10px 0;
        }
    }

    &__department-container {
        display: block;

        &--hidden {
            display: none;
        }
    }

    &__department-table {
        
        table {
            border-collapse: collapse;
            width: 100%;
        }

        TR:first-child {
            border-bottom: 1px solid currentColor;
        }

        TH {
            text-align: left;

            &:last-child {
                text-align: right; 
            }
        }

        TD, TH {
            padding: 5px;
        }

        TD {
            &:last-child {
                text-align: right;
            }
        }
    }

    &__download-list {
        list-style-type: none;
        padding: 0;
        margin: 20px 0;

        max-height: 27vh;
        overflow-y: auto;

        @media (max-width:$tablet-width) {
            margin-top: 0;
            padding: 20px 0;    
        }

        li {
            border-bottom: 1px solid #dbdbdb;
            display: flex;
            align-items: end;
            justify-content: space-between;
            padding: 10px 0 5px 0;

            p {
                margin: 0;
            }
        }

        svg {
            stroke: currentcolor;
        }
    }

    &__accordion-item-icon {
        display: block;

        &--open {
            transform: rotate(180deg);
            display: none;
        }   
    }

    .is-open .npa-association__accordion-item-icon {
        display: none;
    }

    .is-open .npa-association__accordion-item-icon--open {
        display: block
    }

    &__max-width-paragraph {
        max-width: 880px;
        padding-bottom: 40px;
        text-align: center;

        @media (max-width:$tablet-width) {
            text-align: left;
            padding-bottom: 0;
        }

        &--small {
            max-width: 450px;
            text-align: left;
        }
    }

    &__tab-list {
        list-style-type: none;
        padding: 0;
    }

    &__tab-list-item {
        border-bottom: 1px solid #dbdbdb;
        padding-left: 10px;

        &:first-child {
            border-top: 1px solid #dbdbdb; 
        }

        &--selected p {
            font-weight: bold;
        }
    }

    &__other-info-layout-correction {
        width: calc(100% - 100px);

        @media (max-width:$tablet-width) {
            width: 100%;
        }
    }

    &__contact-layout-correction {
        @media (max-width:$tablet-width) {
            margin-top: 40px;
        }
    }

    .mdc-tab {
        color: inherit;
        opacity: 0.5;

        &--active {
            opacity: 1;
        }
    }

    &__vertical-tab {
        .mdc-tab-bar {
            display: flex;
            flex-direction: column;
        }

        .mdc-tab__icon-text {
            text-transform: capitalize;
            font-size: 16px;
            color: inherit;
            margin: 0;
        }

        .mdc-tab {
            text-align: left; 
            border-bottom: 1px solid #ededed;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:first-child {
                border-top: 1px solid #ededed;
            }
        }

        .mdc-tab--active {
            font-weight: bold;
        }

        .mdc-tab-bar__indicator {
            display: none;
        }

        .tab-panels {
            padding: 0;
        }
    }

    &__horizontal-tab {
        width: 100%;

        .mdc-tab {
            min-width: 50px;
            padding: 0 10px;
            height: 30px;
        }

        .mdc-tab__icon-text {
            text-transform: none;
            font-weight: bold;
        }

        .mdc-tab-bar__indicator {
            background-color: #fb264e;
        }

        .tab-panels {
            padding: 0;
        }
        
        .section {
            overflow-x: auto;
        }

        h3 {
            margin-top: 0;
        }
    }

    &__info-text-with-icon {
        margin-top: 15px;
        display: flex;
        font-weight: bold;
        align-items: center;

        svg {
            margin-right: 15px;
            fill: currentcolor;
        }

        p {
            margin: 0;
        }
    }

    &__info-text-with-icon-wrapper {
        margin: 40px 0 60px 0;

        a {
            color:#07094a;
            text-decoration:none;  
        }
    }

    &__tab-panel {
        opacity: 1;
        position: relative;
        z-index: 1;
        width: 640px;

        @media (max-width:$tablet-width) {
            width: 100%;
        }

        &--hidden {
            opacity: 0;
            position: absolute;
            z-index: -999999
        }
    }

    &__accordion-container {
        display: none;

        .is-open .accordion__item-content {
            margin-bottom: 20px;
        }

        @media (max-width:$tablet-width) {
            display:block
        }
    }

    &__nested-tabs-desktop {
        display: block;

        @media (max-width:$tablet-width) {
            display: none;
        }
    }

}