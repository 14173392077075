@mixin truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Fluid properties
--------------------------------------------------------------------------------*/
@mixin fluid($property, $min, $max, $start: 480, $end: 1340, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

/* Intrinsic Ratios
--------------------------------------------------------------------------------*/
@mixin intrinsic-ratio-parent {
  position: relative;
  height: 0;
}

@mixin intrinsic-ratio-child {
  display: block;
  position: absolute;
  width: 100% !important; // Nuke the external styles
  height: 100% !important; // Nuke the external styles
  top: 0;
  margin: 0;
  padding: 0;
}

@mixin intrinsic-ratio-ratio($ratio: 16/9, $width: 100%, $position: top) {
  padding-#{$position}: (1 / $ratio) * $width;
  width: $width;
}

@mixin intrinsic-ratio($ratio: 16/9, $width: 100%, $elements: '> *', $position: top) {
  @include intrinsic-ratio-parent;
  @include intrinsic-ratio-ratio($ratio, $width, $position);

  @each $element in $elements {
    #{$element} {
      @include intrinsic-ratio-child;
    }
  }
}
