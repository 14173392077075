//simple tooltip
nk-joint-benefits-calculator {
    .tooltip{
        position: relative;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        height: 16px;
        width: 16px;
        line-height: 16px;
        margin-left: 10px;
        color: #000;
        display: inline-block;
        font-weight: 700;
        top: 3px;

        &:before{
            content: '';
            position: absolute;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #07094a;
            display: none;
            z-index: 3;
        }
        &:after{
            content: attr(data-tooltip);
            position: absolute;
            bottom: calc(100% + 10px);
            background-color: #07094a;
            color: #fff;
            padding: 16px;
            min-width: 300px;
            text-align: center;
            border-radius: 8px;
            left: calc(-125px + 50%);
            text-transform: none;
            font-weight: 400;
            font-size: 14px;
            display: none;
            z-index: 3;
        }

        &:hover{
            &:after, &:before{
                display: block;
            }
        }
    }
}

.tooltip{
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    height: 16px;
    width: 16px;
    line-height: 16px;
    margin-left: 10px;
    color: #000;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    font-size: .75rem;

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;
    }

    &.logo {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto;    
    }
}




nyk-tooltip {
    all: initial;
}