.soundcloud {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  iframe {
    padding-bottom: $gutter*1.5;

    &::last-child {
      padding-bottom: $gutter*3;
    }
  }
}

.soundcloud-image {
  @include bp-until-desktop {
    display: none;
  }
}

.soundcloud-consent-placeholder {
  min-height: 162px;
  background-color: $color-grey-light;
}

.consent-placeholder-soundcloud {
  margin-top: 30px;
}

.consent-placeholder-soundcloudList {

  .consent-placeholder-soundcloud {
    position: inherit;
    background-color: $color-grey-light;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    text-align: center;
    background-color: $color-grey-light;

    &table {
        position: relative;
        top: 50%;
    }

    th,
    td {
    padding: 10px 80px 10px 80px;

        @media (max-width: 640px) {
            padding: 10px 10px 10px 10px;
        }
    }
  }
}