

.nk-text-input {
    font-size: 16px;
    color: $color-grey-dark;
    position: relative;
    margin: 6px;
    width: 100%;

    @include bp-only-phone {
        margin: 6px 0;
    }

    &__postfix-label {
        position: absolute;
        display: block;
        content: attr(postfixLabel);
        color: initial;
        top: 17px;
        right: 14px;
        font-size: 16px;
        opacity: 0;
    }

    &__field {
        background-color: $color-white;
        height: 56px;
        padding: 0 36px 0 16px;
        border-radius: 4px;
        border: 1px solid $color-grey-dark;
        text-align: left;
        width: 100%;
        
        &:not(:placeholder-shown) { //CSS trick to trigger rules when input field has input
            text-align: right;

            & + label {
                top: -8px;
                font-size: 12px;
                background-color: white;
            }

            ~ span {
                opacity: 1;
            }
        }

        &::placeholder {
           color: transparent; 
        }
    }

    &__label {
        transition: all 0.1s ease-in-out;
        font-size: 16px;
        display: block;
        width: fit-content;
        background-color: transparent;
        position: absolute;
        top: 17px;
        left: 12px;
        padding: 0 5px;
    }

    &__error {
        border: 2px solid #e71515;
        outline: none;
    }
}