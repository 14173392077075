.rr-scenario-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    &__table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1.5rem;

        &-head {

            background-color: $color-blue-dark;

            &-row {
                &:first-child {
                    padding-left: 5rem;
                }
            }
        }

        &-cell {
            padding: 1rem;
            border-bottom: 1px solid $color-grey-light;
            font-weight: 400;

            &--header {
                text-transform: uppercase;
                text-align: center;
                border: none;
                color: $color-white;


                &-first {
                    padding-left: 2rem;
                    text-align: left;
                }
            }

            &-span {
                line-height: 2rem;

                &--bold {
                    font-weight: bold;
                    padding-left: 1rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    min-height: 4rem;
                }

            }

            // Utility class
            &--center-content {
                text-align: center;
            }
        }

    }

    &__description {
        padding: 0 2rem;
    }
}

.Sparinvest {
    .rr-scenario-table {
        &__table {
            &-head {
                background-color: $color-teal;
            }

            &-cell {
                &--header {
                    color: $color-blue-dark;
                }
            }
        }
    }
}