.rr-performance-graph {
    list-style: none;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 870px) {
        margin: 1rem;
    }

    &__text {

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
        }

        &-title {
            text-align: left;
            font-weight: 600;
            font-size: 1.35rem;
            margin: 0.5rem 0;

            @media screen and (max-width: 870px) {
                font-size: 1.2rem;
            }
        }

        &-content {
            display:flex;
            flex-direction: column;
            gap: 0.5rem;

            &-paragraph {
                font-size: 1rem;
                line-height: 1.5;
            }

        }

    }

    &__graph {

        &-wrapper {
            display: flex;
            flex-direction: column;
        }

        &-description {
            color: $color-blue-dark;
            font-size: 0.8rem;
            margin: 1rem 0;
        }

    }
    

}