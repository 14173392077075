.section--faq-answer{
    padding-bottom:0;
}

.faq-hero__title {
    margin: 8px auto 0px;
    line-height: normal;
}

.faq-answer__header {
    @extend .file-list__header;
    padding: 0 32px;
    text-transform: uppercase;
}

.faq-answer__body {
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 32px;
    background-color: $color-white;
    line-height: 1.75;
    color: $color-dark;

    &:not(:first-child) {
        border-top: none;
    }

    a {
        color: $color-turquoise;
        text-decoration: none;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 27px;

            &:before {
                content: '';
                background-color:$color-red;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                top: calc(50% - 5px);
            }
        }
    }
}

.faq-feedback {
    margin-top: 35px;
    text-align: center;

    &__choices{
        .mdc-form-field{
            padding-right:$gutter*2;
        }
    }
    
    .mdc-radio{

        &::before{
            background-color: rgba($color-turquoise,0.14);
        }
        &__native-control:checked{
            + .mdc-radio__background{
                .mdc-radio__outer-circle{
                     border-color:$color-turquoise;
                }
                .mdc-radio__inner-circle{
                    background-color: $color-turquoise;
                }
             }
        }
    }
    
    .mdc-text-field:not(.mdc-text-field--box) .mdc-text-field__input{
        border-bottom:solid 2px $color-beige-dark;
        line-height: 1.56;
    }
    .mdc-button{
        padding:0 $gutter*3;
    }

    .mdc-text-field--textarea .mdc-floating-label{
        background-color:$color-beige;
    }

    &__formfield{
        > label{
            padding-left:0;
        }
    }

    &__textfield{
        width:100%;
        min-height:225px;
        max-width:420px;
        margin:0 auto;

        &.mdc-text-field--focused{
            textarea{
                border-color:$color-blue !important;
            }
        }

        &__input{
            height:225px;
            width:100%;
            background-color:$color-beige;
            padding:$gutter*5 $gutter*3;
            font-size:16px;
            color:$color-dark;
            resize: none;

            .faq-feedback__textfield &{
                height:225px;
            }
        }
        &__label{
            font-size:16px;
            bottom:auto;
            top:$gutter*3;
            left:$gutter*3;
            

            &.mdc-text-field__label--float-above{
                color:$color-blue;
                transform:translateY(-20%) scale(0.75, 0.75)
            }
        }
    }
}

.faq-card {
    &__body {
        &--overflow {
            height: 6.5rem;
            overflow: hidden;
        }
    }
}