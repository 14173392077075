.table {
    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }
    
    .current-price--row {
        width: 130px;
    }

    .bond-price--development {
        @include bp-until-desktop {
            margin-right: 10px;
        }

        @include bp-above-desktop {
            margin-left: 20px;
            float: left;
        }

        svg {
            vertical-align: middle;
        }
    }
}

.select-bond-type {
    display: flex;
    justify-content: space-between;

    @include bp-until-desktop {
        flex-direction: column-reverse;
        span {
            margin-bottom: 20px;
        }
    }
}