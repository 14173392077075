.webinar {
  position: relative;
  height: 1500px; /* Height of iframe content */
  padding-top: 0px;

  iframe, object, embed {
    position: absolute;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__phone-padding {
    @media (max-width: 440px) {
      padding-top: 6px;
    }
  }   

  @media only screen and (max-width: 767px) {
    height: 1000px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 1100px;
  }
}


.NykreditDk, .Dotcom, .invest {
  .responsive-webinar-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 1292px;
    max-height: 726px;
  }
  
  .responsive-webinar-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
