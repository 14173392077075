@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mdc-typography .chat__toggle {
    right: $gutter * 3;
    bottom: $gutter * 3;
    left: auto;
    top: auto;
  }
}

.chat__toggle {
  position: fixed;
  left: 100%;
  bottom: 0;
  z-index: 5;

  // Adjust for .mdc-layout-grid__inner
  @each $size in map-keys($mdc-layout-grid-columns) {
    @include mdc-layout-grid-media-query_($size) {
      $margin: #{map-get($mdc-layout-grid-default-margin, $size)};
      transform: translate(calc(-100% - #{$margin}), -$margin);
    }
  }

  &.is-active {
    &.chat__toggle-open {
      display: none;
    }

    &.chat__toggle-close {
      display: block;
    }
  }
}

.chat__toggle-close {
  display: none;
}

.chat__overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 5;
  pointer-events: none;
  display: none;
  top: 0;
  left: 0;

  @include bp-until-desktop {
    flex-direction: column-reverse;
  }

  &.is-active {
    display: flex;
    pointer-events: all;
  }
}

.chat__content {
  -webkit-overflow-scrolling: touch;
  flex: 0 0 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  @include bp-above-desktop {
    flex: 0 0 50%;
  }
}

.chat__content-inner {
  max-width: $max-width / 2;
  padding: $gutter * 6 $gutter * 3 $gutter * 9;

  @include bp-above-desktop {
    padding: $gutter * 8;
  }

  // Exception for richtext
  a:not(.mdc-button) {
    color: $color-turquoise;
    text-decoration-skip: ink;
    transition: color 0.2s $mdc-animation-acceleration-curve-timing-function;

    &:hover,
    &:active {
      color: $color-red;
    }
  }
}

.chat__content-blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chat__content-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 calc(50% - #{$gutter * 1.5});
  white-space: nowrap;

  @include bp-only-phone {
    flex: 0 0 100%;
  }

  &:first-child {
    margin-right: $gutter * 3;
  }

  &:not(:first-child) {
    margin-top: $gutter * 7;
  }

  p {
    margin-top: 0;
  }

  div + div {
    margin-top: $gutter;
  }

  .icon {
    margin-right: $gutter * 2;
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.chat__content-block-line {
  display: flex;
  align-items: center;
}

.chat__content-block--large {
  flex: 0 0 100%;
}

.chat__image {
  background-image: url("/assets/images/contact-bg.png");
  background-size: cover;
  flex: 0 0 25%;

  @include bp-above-desktop {
    flex: 0 0 50%;
  }
}

//// salesforce chat ////
.NykreditDk {
  .sidebarHeader img {
    max-width: 100px;
  }

  .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage .uiImage {
    max-width: 50px;
    width: 50px;
  }

  .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage img {
    width: 100%;
  }

  .chatNotification {
    -webkit-animation: chatButtonNotification 1s infinite; /* Safari 4+ */
    -moz-animation: chatButtonNotification 1s infinite; /* Fx 5+ */
    -o-animation: chatButtonNotification 1s infinite; /* Opera 12+ */
    animation: chatButtonNotification 1s infinite; /* IE 10+, Fx 29+ */
  }

  @keyframes chatButtonNotification {
    0%,
    49% {
      background-color: #0f1e82;
    }

    50%,
    100% {
      background-color: #68d2df;
    }
  }

  .embeddedServiceHelpButton .helpButton {
    right: 100px;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #0f1e82;
    font-family: "GT-Eesti-Pro-Display", Arial, "Open Sans";
  }

  .embeddedServiceSidebarMinimizedDefaultUI {
    right: 100px;
  }

  @media (max-width: 840px) {
    .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage img {
      max-width: 80px;
      width: 80px;
    }
    .embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
      bottom: 24px;
      height: 90px;
      width: 90px;
      right: 80px;
      min-width: 90px;
      max-width: 90px;
      border-radius: 0;
    }

    .embeddedServiceHelpButton {
      .helpButton {
        right: 16px;
        bottom: 25px;
        width: 56px;
        height: 56px;
        .helpButtonLabel {
          width: 40px;
          height: 32px;
          bottom: -11px;
          background-color: #ffffff;
          opacity: 0.5;
          border-radius: 100%;
          -webkit-animation: sk-scaleout 1s infinite ease-in-out;
          animation: sk-scaleout 1s infinite ease-in-out;
        }
        .uiButton {
          width: 100%;
          height: 100%;
          min-width: 56px;
          border-radius: 0;
          font-size: 1.25em;
          .embeddedServiceIcon {
            left: 4px;
          }
        }
      }
    }

    @keyframes sk-scaleout {
      0% {
        -webkit-transform: scale(0);
      }

      100% {
        -webkit-transform: scale(1);
        opacity: 0;
      }
    }

    @keyframes sk-scaleout {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
      }
    }
  }
}
//// salesforce chat ////
