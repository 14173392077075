.block-item {
  color: currentColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  text-decoration: none;

  @include bp-only-phone {
    margin-bottom: $gutter*2;
  }
}

.block-item__icon {
  display: flex;
  justify-content: center;

  .mdc-layout-grid__cell--span-3-desktop &{
    justify-content: flex-start;
    margin-bottom:10px;
  }
}

.block-item__title {
  margin-top: 0;
}

.block-item{
  &__titleElement h2{
    font-size: 42px;
    margin-top: 0px;
    color: #ff4d00;
  }
}

_:-ms-fullscreen, :root .mdc-layout-grid__cell--span-3-desktop .block-item--cell {
    width:100%;
    .block-item__content{
      max-width:100%;
      overflow:hidden;
      .block-item__title{
        margin-left:1px;
      }
      .mdc-typography--body{
        margin-left:1px;
      }
    }
}
