

.nk-circular-graph {

	&__circle {
		fill: none;
		stroke-linecap: round;
		animation: progress .75s cubic-bezier(.73,.16,.69,.94) forwards;
	}
	
	@keyframes progress {
		0% {
		stroke-dasharray: 0 100;
		}
	}

	&__stroke-wrapper {
		position: relative;
		width: 100%;
		min-height: 250px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__stroke-wrapper-small {
		position: relative;
		width: 100%;
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}	

	&__graph-container {
		position: relative;
		width: 70px;
		height: 70px;
	}

	&__svg-container {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 270px;
	}

	&__collective-info {
		text-align: center; 		
		margin-top: 13px;	
	}

	&__collective-info-before-tax {
		font-size: 14px;
		margin-bottom: 0;
	}	

	&__collective-info-before-tax-value {
		font-size: 40px;
		font-weight: 600;
		margin: 0;

		span {
			font-size: 14px;
		}
	}

	&__collective-info-after-tax {
		font-size: 12px;
		margin-bottom: 3px;
	}
	
	&__collective-info-after-tax-value {
		font-size: 18px;
		font-weight: bold;
	}

	&__loan-type {
		font-weight: bold;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
	}

	&__info-container-small {
		padding-left: 20px;
	}
}
  
