﻿[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// Fixes horizontal scrolling on mobile devices
#main-container {
    
    @media screen and (max-width: 600px){
        overflow-x: hidden;
    }    
}

//animation for block-item-news (news-section__list)
@mixin bp-medium-up { //taken from src\scss\foundation\_breakpoints.scss
    @media (min-width: 768px) {
        @content;
    }
}
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation:    $animation;
    -ms-animation:     $animation;
    -o-animation:      $animation;
    animation:         $animation;
}

.news-section{
    @include keyframes(news-block-item-appear) {
         0% {
            opacity: 0;
            max-height: 0;
        }
        50%{
            opacity: 0;
            max-height: 200px;
        }
        100% {
            opacity: 1;
        }
    }
    @include keyframes(see-more-button-appear) {
         0% {
            opacity: 0;
        }
        50%{
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    /* The starting CSS styles for the enter animation */
    .block-item-news.ng-enter { 
        @include animation(0.75s news-block-item-appear);
    }

    .news-section__see-more-button.ng-hide-remove{
        @include animation(0.75s see-more-button-appear);
    }
}

.no-animate {
   -webkit-transition: none !important;
   transition: none !important;
}


.anchor_tags {
	display: block; //or else 'isScrolledIntoView' from scrolHash.js wont calculate the correct position.
}

.anchor_point { // Use for anchorblock and sectiontitleblock to accommodate for fixed top menu
    padding-top: 65px;
    margin-top: -65px;
    z-index: -100;
    position: relative;
}

@media (max-width: 768px) {
    .mega-menu__adjust--small {
        height: 54px;
    }
}


/* SPACING BETWEEN BLOCKS BEGIN */
// Classes for hero intro title and section title
.section--hero-intro-title {
    padding-top: 64px;
    padding-bottom: 64px;

    &:not(&__standalone) {
        padding-bottom: 0;
    }
}

.section--intro-title {
    &:not(&__standalone) {
        padding-bottom: 0;
    }
}

// This is to accommodate for anchor_point when making block spacing rules 
.theme--white:not(.theme--pattern) + .anchor_point + .theme--white:not(.theme--pattern),
.theme--beige:not(.theme--pattern) + .anchor_point + .theme--beige:not(.theme--pattern), 
.theme--blue-dark:not(.theme--pattern) + .anchor_point + .theme--blue-dark:not(.theme--pattern) {
     padding-top: 0; 
}

// This is to accommodate for extra div by episerver when making block spacing rules 
.theme--white:not(.theme--pattern) + div > :first-child.theme--white:not(.theme--pattern),
.theme--beige:not(.theme--pattern) + div > :first-child.theme--beige:not(.theme--pattern),
.theme--blue-dark:not(.theme--pattern) + div > :first-child.theme--blue-dark:not(.theme--pattern) {
    padding-top: 0;
}
/* SPACING BETWEEN BLOCKS END */

.hide-on-mobile {

    @media screen and (max-width: 600px){
        display: none !important;
    }
}

.hide-on-tablet {
    @media screen and (max-width: 839px){
        display: none !important;
    }
}

.hide-on-desktop {
    @media screen and (min-width: 840px){
        display: none !important;
    }
}

.visible-on-mobile {
    display: none;
    
    @media screen and (max-width: 600px){
        display: block;
    }
}

.visible-on-tablet {
    display: none;
    
    @media screen and (max-width: 839px){
        display: block;
    }
}

.text {

    &-left {
        text-align: left;
    }
    &-right {
        text-align: right;
    }
    &-left-phone {
        @media screen and (max-width: 600px){
            text-align: left;
        }
    }
    &-right-phone {
        @media screen and (max-width: 600px){
            text-align: right;
        }
    }

    &-no-transform {
        text-transform: none !important;
    }
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.has-margin {
    margin: 20px auto;

    &--top {
        margin-top: 20px;

        &-extra {
            margin-top: 30px;
        }
    }

    &--bottom {
        margin-bottom: 20px;

        &-extra {
            margin-bottom: 30px;
        }
    }
}

#LiveMonTransparentImage {
    display: none;
}

.archive-results {
    transition: 1s all;
}