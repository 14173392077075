mdc-select > select.mdc-select {
  display: none;
}

@media (max-width: 600px) and (pointer: coarse) {
  mdc-select > .mdc-select[role="listbox"] {
    //display: none;
  }

  mdc-select > select.mdc-select {
    display: block;
    background-color: transparent;
  }
}

mdc-select.width-wrapper {
  display: inline-block;

  .mdc-select {
    min-width: 100%
  }
}
