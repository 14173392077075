
//*THEME*//
$theme-find-fond-dropdown-color: $color-turquoise !default;

.section--find-fond-filter{
  @include bp-above-desktop {
    padding-bottom: $gutter * 2;
  }
}

.find-fond {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.find-fond__description {
  width: 100%; //IE flexbox fix
}

.find-fond__text {
  font-weight: map-get($mdc-typography-font-weight-values, light);
}

.find-fond-search {
  display: flex;
  padding: $gutter*4 0 $gutter*2 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include bp-until-desktop {
    flex-direction: column;
  }
  @include bp-until-tablet {
    .mdc-typography--title-sm{
      @include mdc-typography--title-xs;
    }
  }

  &__text{
    /* copy of the select styling to ensure the text and the selects are horizontally aligned */
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
    display: inline-flex;
    padding-top: 12px;
    padding-bottom: 6px;
    @include bp-until-desktop {
      padding-bottom: 12px;
    }
    span{
      margin-top: -12px;
    }
  }

  &__dropdown {
    margin-right: 10px;
    margin-left: 10px;
    @include bp-until-desktop {
      padding-bottom: 18px;
    }
    
    .mdc-select{
      color: $theme-find-fond-dropdown-color;
      padding: 12px 28px 5px 8px;
      height: 46px;
      border-bottom: 1px solid currentColor;
      //max-width: inherit;
      //width: inherit !important;
      @include mdc-select-ink-color(rgba(0,0,0,0));
      &:not(.mdc-select--disabled){
        .mdc-select__native-control{
          color:rgba(0,0,0,0.01);
        }
      } 
     
    }
    select{
      
      font-weight: 800;
      position:absolute;
      left:0;
      top:0;
      max-width: inherit;
      padding:0;
      padding-left:0;
      outline:none;
      border-bottom:none;
      
      option{
        color:$color-dark;
        font-size:16px;
      }
      
    }
    .mdc-select__selected-text{
      color: $theme-find-fond-dropdown-color;
      margin-top: -4px;
      margin-bottom:0;
      position: relative;
      @include bp-above-desktop{
        margin-top: -9px;
      }
      @include bp-above-desktop-wide{
        margin-top: -12px;
      }
    }
  }
}

.find-fond__links {
  display: flex;
  flex-direction: row;
  margin-top: $gutter*8;
}


