.customer-package-calculator {
    &__container {
        background-color: $color-beige;
        padding: 40px 30px 1px;
    }
    
    &__body{
        max-width: 878px;
        margin: 0px auto;
    
        &__mobile-navigation { display: none; }    
        .question {
            display: flex;
            margin-bottom: 60px;

            > * + * {
                margin-left: 15px;
            }

            svg {
                margin-top: 12px;
                stroke-width: 1px;
            }

            &--text{
                flex-grow: 1;
                width: calc(100% - 414px);
                h2 {
                    margin:2px 0px;
                    font-size: 30px;
                    font-weight: 800;
                    color: $color-blue;
                    font-family: "Open Sans",Roboto,sans-serif;
                }
                
                p {
                    margin:2px 0px;
                    font-family: "Open Sans",Roboto,sans-serif;
                }
            }

            .answer-container{
                display: flex;
                width: 360px;
                margin-top: 15px;

                > * + * {
                    margin-left: 15px;
                }

                .radio-container{
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    width: 110px;
                    
                    .answer-lable{
                        text-align: center;
                        color: $color-dark;
                        font-family: sans-serif;
                        font-size: 14px;
                        width: 100%;
                    }

                    input[type=radio] {
                        cursor: pointer;
                        position: relative;
                        opacity: 0;
                        width: 10px;
                    }

                    .checkmark {
                        position: relative;
                        top: 0;
                        right: 0;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        border: solid 2px rgba(19, 19, 19, 0.56);
                    }

                    input:checked ~ .checkmark {
                        border-color: $color-turquoise;
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked ~ .checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        top: 6px;
                        left: 6px;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background:$color-turquoise;
                    }
                }
            }
        }
    }

    &__to-result {
        text-align: center;
        margin: 60px 0px;
    }

    hr {
        border-top: 1px solid rgba(237, 237, 237, 0.97);
        margin: 20px;
    }

    &__recommendation {
        &__header {
            text-align: center;
            margin: 60px;

            h1{
                margin-bottom: 20px;
            }

            &__message {
                margin-top: 20px;
                margin-bottom: 20px;

                p{
                    margin: 2px 0px;
                    line-height: 33px;
                    color: $color-blue;
                    font-size: 24px;
                    font-weight: 100;

                    .text-color-lightblue{
                        color: $color-turquoise;
                        font-weight: bold;
                    }

                    .text-color-red {
                        color: $color-red;
                        font-weight: bold;
                    }
                }
            }
        }

        &__body {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 30px;

            > * {
                flex-basis: 320px;
                flex-grow: 1;
                margin: 10px 15px;
            }
        }

        &__asterisk {
            margin-bottom: 8px;
            display: flex;
            padding: 0px 15px;
            > div {
                display: flex;
                margin-right: 20px;
                svg {
                    margin: 2px;
                    width: 10px;
                    height: 15px;
                    stroke: #68d2df;
                    stroke-width: 0.3px;
                }
            }
        
            span {
                font-weight: 100;
                line-height: 28px;
            }
        }
    }
}

@include bp-until-desktop{    
    .customer-package-calculator {
        &__container{
            margin: 0px -15px;
            padding: 30px;
        }

        &__body{
            max-width: 450px;

            &__mobile-navigation {
                display: flex;
                justify-content: space-between;
                color: #0f1e82;
            }
    
            .question{
                display: none;

                > * + * {
                    margin-left: 0px;
                }

                &--current{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    margin: 0px;
                }
                
                svg {
                    width: 42px;
                    height: 42px;
                }
                
                &--text {
                    width: 100%;
                    h2 {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                .answer-container {
                    display: flex;
                    flex-direction: column;
                    gap: 13px;
                    margin-top: 0px;
                    
                    > * + * {
                        margin-left: 0px;
                    }
                    
                    .radio-container {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: flex-end;
                        align-items: center;
                        width: auto;
                        
                        .answer-lable {
                            text-align: left;
                            width: auto;
                        }

                        .checkmark {
                            width: 29px;
                            height: 29px;
                        }

                        .checkmark:after {
                            top: 4px;
                            left: 4px;
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }
        }

        &__recommendation {
            &__header{
                margin: 20px;

                p {
                    font-weight: 400;
                    margin-top: 20px;
                }

                button {
                    margin: 20px 0px;
                }
            }
        }
    }
}