.hide-base-svg {
  display: none;
}

// Icons are rendered in an <img> tag that defaults to a border radius 16px. Therefore we set it to 0 and as !important.
.icon {
  display: inline-block;
  color: currentColor;
  //vector-effect: non-scaling-stroke;
}

.icon--top {
  vertical-align: top;
}

.icon--middle {
  vertical-align: middle;
}

.icon--stroke {
  fill: none;
  stroke: currentColor;
}

.icon--solid {
  fill: currentColor;
  stroke: none;
}

.icon--xxxlg {
  stroke-width: 1.5px;
  width: 10rem;
  height: 10rem;
}

.icon--xxlg {
  stroke-width: 1.5px;
  width: 8rem;
  height: 8rem;
}


.icon--xlg {
  stroke-width: 1.5px;
  width: 6rem;
  height: 6rem;
}

.icon--lg {
  stroke-width: 1.5px;
  width: 4rem;
  height: 4rem;
}

.icon--md {
  stroke-width: 2px;
  width: 3rem;
  height: 3rem;
}

.icon--rg {
  stroke-width: 2px;
  width: 2rem;
  height: 2rem;
}

.icon--sm {
  stroke-width: 3px;
  width: 1.5rem;
  height: 1.5rem;
}

.icon--xs {
  stroke-width: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.icon--xxs {
  stroke-width: 4px;
  width: 1rem;
  height: 1rem;
}

.icon--xxxs {
  stroke-width: 4px;
  width: .6rem;
  height: .6rem;
}

.icon--12px {
  stroke-width: 4px;
  width: 12px;
  height: 12px;
}

.icon--15px {
  stroke-width: 4px;
  width: 15px;
  height: 15px;
}

.icon--20px {
  stroke-width: 4px;
  width: 15px;
  height: 15px;
}

.NykreditDk, .Dotcom, .invest {
  .icon {
    display: inline-block;
    color: currentColor;
    //vector-effect: non-scaling-stroke;
    border-radius: 0px !important;
  }
  
  .icon--top {
    vertical-align: top;
    border-radius: 0px !important;
  }
  
  .icon--middle {
    vertical-align: middle;
    border-radius: 0px !important;
  }
  
  .icon--stroke {
    fill: none;
    stroke: currentColor;
  }
  
  .icon--solid {
    fill: currentColor;
    stroke: none;
    border-radius: 0px !important;
  }
  
  .icon--xxxlg {
    width: 10rem;
    height: 10rem;
    border-radius: 0px !important;
  }
  
  .icon--xxlg {
    width: 8rem;
    height: 8rem;
    border-radius: 0px !important;
  }
  
  
  .icon--xlg {
    width: 6rem;
    height: 6rem;
    border-radius: 0px !important;
  }
  
  .icon--lg {
    width: 4rem;
    height: 4rem;
    border-radius: 0px !important;
  }
  
  .icon--md {
    width: 3rem;
    height: 3rem;
    border-radius: 0px !important;
  }
  
  .icon--rg {
    width: 2rem;
    height: 2rem;
    border-radius: 0px !important;
  }
  
  .icon--sm {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0px !important;
  }
  
  .icon--xs {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0px !important;
  }
  
  .icon--xxs {
    width: 1rem;
    height: 1rem;
    border-radius: 0px !important;
  }
  
  .icon--xxxs {
    width: .6rem;
    height: .6rem;
    border-radius: 0px !important;
  }
  
  .icon--12px {
    width: 12px;
    height: 12px;
    border-radius: 0px !important;
  }
  
  .icon--15px {
    width: 15px;
    height: 15px;
    border-radius: 0px !important;
  }
  
  .icon--20px {
    width: 15px;
    height: 15px;
    border-radius: 0px !important;
  }
}