
.consent-placeholder-style {
    position: absolute;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    text-align: center;
    background-color: $color-grey-light;

    &table {
        position: relative;
        top: 50%;
    }

    th,
    td {
    padding: 10px 80px 10px 80px;

        @media (max-width: 640px) {
            padding: 10px 10px 10px 10px;
        }
    }
}