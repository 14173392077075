.contact {
  text-align: center;
}

.contact__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: $color-white;
  margin-top: $gutter*6;
}

.contact__label {
  margin-top: $gutter*2;
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
  text-transform: uppercase;
  white-space: nowrap;
}

.contact__mifid-text{
  @include fluid(padding-top, 0, 128);
  @include fluid(padding-bottom, 0, 1);
  &.richtext {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    vertical-align: middle;
    margin-bottom: 0px;
    // transform: translate(0,300%);
    a {
      color: rgba(104, 210, 223, 0.6);
    }
  }
}