.hero-video-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
}
.hero-video-center-fill {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero-video-center-fill {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
    }
}

.NykreditDk, .Dotcom, .invest {
    .hero-video-center-fill {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 16px 0 0 16px;
    }
}