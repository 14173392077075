
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 600px;

$color-sparinvest-dark-green: #005151;
$color-sparinvest-green: #80c7bc;
$color-sparinvest-light-green: #b5e3d8;
$color-sparinvest-yellow: #ffcd00;

$color-nykredit-dark-indigo: #07094a;

.nyk-hero-campaign-container {
    &--mobile-spacing {
        @media (max-width: $mobile-breakpoint) {
            margin-top: 64px;
            margin-bottom: 64px;
        }
    }

    .nyk-hero-campaign {
        margin-top: -10px;
        padding-bottom: 15px;

        @media (max-width: $mobile-breakpoint) {
            margin-bottom: -10px;
        }

        &__block-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 12px 0;
        }

        &__block-container {
            overflow: hidden;
            position: relative;
            width: 100%;
            min-height: 420px;
            height: 100%;
            max-height: 400px;
            margin: 4px;
            border-radius: 16px;

            @media (max-width: $mobile-breakpoint) {
                min-height: 260px;
                padding: 40px 32px 80px 32px;
            }

            p {
                line-height: 1.5;
            }

            .hero-box__image {
                top: 0;
                right: 0;
                max-width: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;

                @media (max-width: $mobile-breakpoint) {
                    height: 350px;
                }
                @media (max-width: $tablet-breakpoint) {
                    height: 420px;
                    max-height: unset;
                }
            }

            .mdc-typography--title-md,
            .mdc-typography--title-sm {
                margin-bottom: 0;
            }

            .mdc-typography--title-md {
                font-size: 36px;
                line-height: 44px;
                letter-spacing: 0.8px;

                @media (max-width: $mobile-breakpoint) {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            .mdc-typography--title-sm {
                font-size: 28px;
                line-height: 1.29;

                @media (max-width: $mobile-breakpoint) {
                    font-size: 24px;
                    line-height: 36px;
                }
            }

            &--image {
                padding: 0;
            }

            &--single-slot {
                width: calc(33.3% - 8px);

                @media (max-width: $tablet-breakpoint) {
                    width: calc(50% - 8px);
                }

                @media (max-width: $mobile-breakpoint) {
                    width: calc(100% - 8px);
                }
            }
            &--video {
                .hero-box__image {
                    display: none;

                    @media (max-width: $mobile-breakpoint) {
                        display: block;
                    }
                }
            }

            &--double-slot {
                padding: 0;
                width: calc(66.6% - 8px);

                @media (max-width: $tablet-breakpoint) {
                    width: calc(100% - 8px);
                }

                @media (max-width: $mobile-breakpoint) {
                    height: 174px;
                    min-height: unset;
                }

                .hero-video-wrapper {
                    display: block;

                    @media (max-width: $mobile-breakpoint) {
                        display: none;
                    }
                }
                .hero-box__image {
                    @media (max-width: $mobile-breakpoint) {
                        display: none;
                    }
                    &--mobile {
                        display: none;
                        @media (max-width: $mobile-breakpoint) {
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }

            &--bg-color-teal {
                background-color: $color-teal;
            }

            &--bg-color-white {
                background-color: $color-white;
                border: 1px solid $color-grey-lighter;
            }

            &--bg-color-blue {
                background-color: $color-blue;
            }

            &--bg-color-grey {
                background-color: $color-beige-dark;
            }
            &--bg-color-sparinvest-medium-green {
                background-color: $color-sparinvest-green;
            }
            &--bg-color-sparinvest-light-green {
                background-color: $color-sparinvest-light-green;
            }
            &--bg-color-light-blue {
                background-color: $color-light-blue;
            }
            &--bg-color-light-teal {
                background-color: $color-light-teal;
            }
            &--bg-color-purple {
                background-color: $color-purple;
            }
            &--bg-color-yellow {
                background-color: $color-yellow;
            }
        }

        &__text-single-slot-section-top {
            &__text {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 9;
                -webkit-box-orient: vertical;
            }

            &--font-color-blue {
                color: $color-blue-dark;

                .nyk-hero-campaign__text-single-slot-section-top__title {
                    color: $color-blue-dark;
                }
            }

            &--font-color-white {
                color: $color-white;

                .nyk-hero-campaign__text-single-slot-section-top__title {
                    color: $color-white;
                }
            }

            &--font-color-sparinvest {
                color: $color-sparinvest-dark-green;

                .nyk-hero-campaign__text-single-slot-section-top__title {
                    color: $color-sparinvest-dark-green;
                }
            }
        } 

        &__text-single-slot-section-bottom {
            position: absolute;
            bottom: 50px;

            @media (max-width: $mobile-breakpoint) {
                bottom: 32px;
            }

            .mdc-button--secondary {
                background-color: $color-white;
                margin-right: 48px;

                @media (max-width: $mobile-breakpoint) {
                    margin-right: 32px;
                }
            }
        }

        &__image-single-slot-icon-container {
            height: 45px;
            width: 45px;
            transform: rotate(180deg);
            margin-left: auto;

            svg {
                stroke: $color-blue-dark;
            }
        }

        &__image-single-slot-container {
            position: absolute;
            border-radius: 3px;
            width: calc(100% - 48px);
            display: flex;
            align-items: center;
            padding: 24px 12px 24px 32px;
            left: 24px;
            bottom: 24px;
            background-color: rgba($color-white, 0.9);
            text-decoration: none;

            @media (max-width: $mobile-breakpoint) {
                bottom: 38px;
            }
        }

        &__image-single-slot-text {
            font-size: 20px;
            margin: 0;
            color: $color-blue-dark;
            max-width: 80%;
        }
    }
}

// Ensure that it does not target TotalKredit or SparInvest
.NykreditDk, .Dotcom, .invest {
    .nyk-hero-campaign-container {

        &--mobile-spacing {
            @media (max-width: $mobile-breakpoint) {
                margin-top: 64px;
                margin-bottom: 64px;
            }
        }

        &__primary-button {
            border-color: $color-nykredit-dark-indigo;
            border-width: 1px;
            border-style: solid;
            border-radius: 89px;
            height: 44px;
            display: flex; 
            align-items: center;  
            justify-content: space-between; 


            &--white-border {
                border-color: white;
            }

            &--text {
                font-family: "NykreditSans-Medium", "Open Sans";
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: left;
                box-shadow: none;
                flex-grow: 1;
                padding: 6px 20px 6px 24px;
                margin-right: 40px;

                &--white {
                    color: white;
                }
            }

            &--icon {
                box-shadow: none;
                display: flex;
                height: 44px;
                width: 44px;
            }
        }

        &__secondary-button {
            display: flex; 
            justify-content: space-between; 
            height: 44px;
            
            &--text {
                align-items: center;
                display: flex;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                box-shadow: none;
            }

            &--icon {
                width: 44px;
            }
        }

        &__secondary--picture {

            &__main-container {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            &__button-container {
                height: 44px;
                margin-left: 40px;
                margin-right: 24px;
                display: flex;
                justify-content: space-between;
                margin-top: auto;
                margin-bottom: 30px;
            }
            
            &__text-container {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                align-items: center;
                display: flex;
                
                &--color-white {
                    color: white;
                }
                &--color-indigo {
                    color: $color-nykredit-dark-indigo;
                }
            }

            &__icon-container {
                width: 44px;
            }
        }
    
        .nyk-hero-campaign {
            font-family: "NykreditSans-Medium", "Open Sans";
            margin-top: -10px;
            padding-bottom: 15px;
            position: relative;
    
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: -10px;
            }
    
            &__block-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin: 12px 0;
            }
    
            &__block-container {
                overflow: hidden;
                position: relative;
                display: flex;
                width: 100%;
                min-height: 420px;
                height: 100%;
                max-height: 400px;
                margin: 4px;
                padding: 40px 24px 24px 40px;
                border-radius: 16px;

                @media (max-width: $mobile-breakpoint) {
                    min-height: 260px;
                    padding: 40px 32px 80px 32px;
                }
    
                p {
                    line-height: 1.5;
                }
    
                .hero-box__image {
                    top: 0;
                    right: 0;
                    max-width: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100%;
                    position: absolute;
    
                    @media (max-width: $mobile-breakpoint) {
                        height: 350px;
                    }
                    @media (max-width: $tablet-breakpoint) {
                        width: 100%;
                        height: 100%;
                        max-height: unset;
                    }

                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s;
                    }
                }
    
                .mdc-typography--title-md,
                .mdc-typography--title-sm {
                    margin-bottom: 0;
                }
    
                .mdc-typography--title-md {
                    font-family: "NykreditSans-Medium", "Open Sans";
                    font-size: 36px;
                    line-height: 44px;
                    letter-spacing: 0.8px;
    
                    @media (max-width: $mobile-breakpoint) {
                        font-size: 28px;
                        line-height: 36px;
                    }
                } 
    
                .mdc-typography--title-sm {
                    font-family: "NykreditSans-Medium", "Open Sans";
                    font-size: 28px;
                    line-height: 1.29;
    
                    @media (max-width: $mobile-breakpoint) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
    
                &--image {
                    padding: 0;
                }
    
                &--single-slot {
                    padding: 40px 40px 30px 40px;
                    width: calc(33.3% - 8px);
    
                    @media (max-width: $tablet-breakpoint) {
                        width: calc(50% - 8px);
                    }
    
                    @media (max-width: $mobile-breakpoint) {
                        width: calc(100% - 8px);
                    }

                    &:hover {
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s;
                    }
                }
                &--video {
                    .hero-box__image {
                        display: none;
    
                        @media (max-width: $mobile-breakpoint) {
                            display: block;
                        }
                    }
                }

                &--footer-content {
                    flex: 1;
                    padding: 20px;
                    box-sizing: border-box;
                }
    
                &--double-slot {
                    padding: 0;
                    width: calc(66.6% - 8px);

                    &:hover {
                        box-shadow: none;
                    }
    
                    @media (max-width: $tablet-breakpoint) {
                        width: calc(100% - 8px);
                    }
    
                    @media (max-width: $mobile-breakpoint) {
                        height: 174px;
                        min-height: unset;
                    }
    
                    .hero-video-wrapper {
                        display: block;
    
                        @media (max-width: $mobile-breakpoint) {
                            display: none;
                        }
                    }
                    .hero-box__image {
                        @media (max-width: $mobile-breakpoint) {
                            display: block;
                        }
                        &--mobile {
                            display: none;
                            @media (max-width: $mobile-breakpoint) {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
    
                &--bg-color-teal {
                    background-color: $color-teal;
                }
    
                &--bg-color-white {
                    background-color: $color-white;
                    border: 1px solid $color-grey-lighter;
                }
    
                &--bg-color-blue {
                    background-color: $color-blue;
                }
    
                &--bg-color-grey {
                    background-color: $color-beige-dark;
                }
                &--bg-color-sparinvest-medium-green {
                    background-color: $color-sparinvest-green;
                }
                &--bg-color-sparinvest-light-green {
                    background-color: $color-sparinvest-light-green;
                }

                &__secondary-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 50%;
                    min-height: 50vh;
                    padding: 1rem;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                    &--text-section-top, &--text-section-middle, &--text-section-bottom {
                        width: 100%;
                    }

                    &--bottom-section {
                        margin-top: auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
    
            &__text-single-slot-section-top {
                &__three-section-layout {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    grid-template-rows: auto 1fr auto;
                }

                &__text {
                    font-family: "NykreditSans-Regular", "Open Sans";
                    font-size: 16px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: 0.46px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 9;
                    -webkit-box-orient: vertical;
                    margin: 16px 0 32px 0;
                }
    
                &--font-color-blue {
                    color: $color-blue-dark;
    
                    .nyk-hero-campaign__text-single-slot-section-top__title {
                        color: $color-blue-dark;
                    }
                }
    
                &--font-color-white {
                    color: $color-white;
    
                    .nyk-hero-campaign__text-single-slot-section-top__title {
                        color: $color-white;
                    }
                }
    
                &--font-color-sparinvest {
                    color: $color-sparinvest-dark-green;
    
                    .nyk-hero-campaign__text-single-slot-section-top__title {
                        color: $color-sparinvest-dark-green;
                    }
                }
            }
    
            &__text-single-slot-section-bottom {
                position: absolute;
                bottom: 50px;
                display: flex;
    
                @media (max-width: $mobile-breakpoint) {
                    bottom: 32px;
                }
    
                .mdc-button--secondary {
                    background-color: $color-white;
                    margin-right: 48px;
    
                    @media (max-width: $mobile-breakpoint) {
                        margin-right: 32px;
                    }
                }
            }
    
            &__image-single-slot-icon-container {
                height: 45px;
                width: 45px;
                transform: rotate(180deg);
                margin-left: auto;
    
                svg {
                    stroke: $color-blue-dark;
                }
            }
    
            &__image-single-slot-container {
                position: absolute;
                border-radius: 3px;
                width: calc(100% - 48px);
                display: flex;
                align-items: center;
                padding: 24px 12px 24px 32px;
                left: 24px;
                bottom: 24px;
                background-color: rgba($color-white, 0.9);
                text-decoration: none;
    
                @media (max-width: $mobile-breakpoint) {
                    bottom: 38px;
                }
            }
    
            &__image-single-slot-text {
                font-size: 20px;
                margin: 0;
                color: $color-blue-dark;
                max-width: 80%;
            }
        }
    }
}