.nav-primary{
    
    &--inverted{
      list-style:none;

      aside &{
        padding:15px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-height:58px;
        overflow-x:auto;
        margin:0;
      }
    }
    &__nav-link{

      &--inverted{
        color:$color-text-nav-primary;
        opacity:.6;
        font-size:12px;
        padding:0 $gutter*2;
        position: relative;

        aside &{
          padding:0 10px;
        }
  
        @include bp-until-desktop-wide {
          font-size:14px;
        }
  
        &:after{
          display:none;
        }
        
        &.is-active{
          opacity:1;
        
          &:after{
            display:block;
            bottom: -8px;
            left: calc(50% - 5px);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $color-text-nav-primary;

            aside &{
              bottom: -15px;
            }
          }
        }
        &:hover{
          opacity:1;
        }
      }
    }
  }