
$color-nykredit-grey: #f7f6f5;
//============= THEMES ==============
$themes: (
  'white': (background: $color-white, color: $color-blue-dark, titleColor: $color-blue-dark),
  'beige': (background: $color-beige, color: $color-blue-dark, titleColor: $color-blue-dark),
  'beige-dark': (background: $color-beige-dark),
  'blue': (background: $color-blue, color: $color-white),
  'blue-dark': (background: $color-blue-dark, color: $color-white),
  'red': (background: $color-red, color: $color-white),
  'turquoise': (background: $color-turquoise),
  'green': (background: $color-green, color: $color-white),
  'olive': (background: $color-olive, color: $color-white),
  'totalkredit': (background: $color-totalkredit, color: $color-white),
  'grey': (background: $color-nykredit-grey, color: $color-white),
) !default;

$components: (
  accordion,
  blocks,
  cards,
  carousel,
  contact,
  intro-quote,
  list,
  table,
  text-block,
  text-image,
  video-image
);

[class*='theme--'] {
  @include fluid(padding-top, 64, 128);
  @include fluid(padding-bottom, 64, 128);
}

[class*='theme-insight--'] {
  @include fluid(padding-top, 64, 40);
  @include fluid(padding-bottom, 64, 40);
}

.theme--pattern {
  background-image: url('/assets/images/section-bg.png');
  background-repeat: repeat;
  background-position: center center;
}

.theme--shadow {
  box-shadow: 0 16px 21px rgba(0, 0, 0, 0.1);
}

// Create theme classes modifiers.
@each $key, $value in $themes {
  .theme--#{$key}, .theme-color--#{$key} {
    $background: getStyle($themes, $key, background);
    $color: getStyle($themes, $key, color);
    $titleColor: getStyle($themes, $key, titleColor);

    @if $background {
      background-color: $background;
    }
    @if $color {
      color: $color;
    }
    @if $titleColor {
      [class*='mdc-typography--title'] {
        color: $titleColor;
      }
    }
  }

  .theme--#{$key} {
    // Remove top padding when identical themes follow each other
    &:not(.theme--pattern) + &:not(.theme--pattern) {
      padding-top: 0;
    }

    // Negative top margin for two equal components with the same theme.
    @each $key2, $value2 in $components {
      &:not(.theme--pattern).section--#{$key2} {
        & + & {
          margin-top: -$gutter*8;
        }
      }
    }
  }
}

//================ SECTION ===================//
.NykreditDk, .Dotcom, .invest {
  .section {
    position: relative;
    background-color: #f7f6f5;

    &--white {
      background-color: #fff;
    }
  }
}

.section--top-spacing {
  padding-top: 80px;
  padding-bottom: 0px;
}
.section--small-spacing {
  padding-top: $gutter*8;
  padding-bottom: $gutter*8;
}
.section--no-spacing-bottom {
  padding-bottom: 0;
}
.section--no-spacing-top {
  padding-top: 0;
}
.section--no-spacing {
  padding-top: 0;
  padding-bottom: 0;
}

.section__title {
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
  margin-bottom: $gutter * 4;
  text-transform: uppercase;
}

//================ chromeless ================//

@include bp-only-phone{
  .chromeless{
    main{
        article{
            &:first-child{
                padding-top:0;
            }
            > .mdc-layout-grid{
                &:first-child{
                    padding-bottom:0;
                }
            }
            h1{
                margin-bottom:1.5rem;
            }
            h1 + [class*="spacer"]{
                height:0;
            }
            .article__meta{
                padding-bottom:10px;
            }
        }
    }
 }
}

.chromeless [class*='theme--']:last-child {
  padding-bottom: 0;
}
