
.notification {
    display: flex;
    flex-direction: column;
    width: auto;
    background-color: $color-white;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid $color-grey-lighter;
    position: relative;

    @include bp-above-desktop {
      flex-direction: row;
    }

    & + &{
        margin-top: 10px;
    }

    &__container {
        padding-top: $gutter*5;
    }
}

.notification__top{
    padding: $gutter*2 0 0 $gutter*3;
}
.notification__content {
    padding: $gutter*2 $gutter*3;
    width: 100%;
    position: relative;
    @include bp-above-desktop {
        padding-right: $gutter*15;
    }
    p{
        margin: 0;
    }
    .notification__btn {
        color: $color-white;

        @include bp-above-desktop {
            position: absolute;
            right: $gutter*2;
            top: calc(50% - 18px);
        }
        @include bp-until-desktop {
            margin-top: $gutter;
            margin-left: 0;
            padding-left: 0;
        }
    }  
}

.notification  {
    color: $color-white;

    &__red {
        background: $color-alert; 
    }
    &__blue {
        color: $color-white;
        background: $color-warning; 
    }
}

.notification__top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    @include bp-above-desktop {
        padding: $gutter*2 0 $gutter*2 $gutter*3;
        &:after{
            content: '';
            width: 1px;
            height: 80%;
            display: block;
        }
    }
}


  