.js-content-slide-in {
  
  @include bp-above-phone { 
    transition: all 0.6s $mdc-animation-deceleration-curve-timing-function;
    will-change: transform;
    transform: translateY(60px);
    opacity: 0;
  }

  &.slide-in {
    @include bp-above-phone { 
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

.js-content-slide-in--video {
  @include bp-above-phone { 
    transition: all 0.6s $mdc-animation-deceleration-curve-timing-function;
    transform: translateY(60px);
    opacity: 0;
  }

  &.slide-in {
    @include bp-above-phone { 
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

@keyframes enterFromBottom {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
    
  }
}

@keyframes exitToTop {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
