.factbook__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include bp-above-tablet {
    color: $color-blue-dark;
    padding-top: $gutter*7;
    margin-bottom: -320px;
  }

  img {
    align-self: center;
    margin-bottom: $gutter*3;
    max-width: 100%;
    max-height: 200px;
  }
  .mdc-typography--title-xxl{
    margin-bottom:0;
  }
}

.factbook__links {
  text-align: center;

  @include bp-above-tablet {
    margin-top: 100px;
  }
  @include bp-only-tablet {
	  margin-bottom: -80px;
  }

  div {
    margin-top: 24px;
  }
  
  div:first-child {
    margin-top: 0;
  }
  
  &--white {
    @include fluid(padding-top, 64, 128);
    @include fluid(padding-bottom, 64, 128);
      
    @include bp-only-tablet {
      padding-top: 160px;
      padding-bottom: 160px;
    }	
  }
}

@include bp-only-phone {
	.theme--white:not(.theme--pattern) + .factbook__links--white {
		padding-top: 0;
	}
}

.section--factbook + .theme--white:not(.theme--pattern) {
    padding-top: 0;
}
.factbook__wrapper {
  background-color: inherit;
}