//============= LIGHT ==============//
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Light.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Light.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, light);
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-LightItalic.woff') format('woff');
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, light);
}

@font-face {
  font-family: 'Eesti-Pro';
  src: url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Light.woff2') format('woff2'),
    url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Light.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, light);
}

//============= REGULAR ==============//
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Regular.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, regular);
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Italic.woff') format('woff');
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, regular);
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Regular.woff2') format('woff2'),
    url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Regular.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, regular);
}

//============= SEMI BOLD ==============//
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Semibold.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-SemiboldItalic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-SemiboldItalic.woff') format('woff');
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Medium.woff2') format('woff2'),
    url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Medium.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, semibold);
}

//============= BOLD ==============//
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Bold.woff2') format('woff2'), url('/assets/fonts/OpenSans-Bold.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Bold.woff2') format('woff2'),
    url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Bold.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
}

//============= EXTRA BOLD ==============//
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Extrabold.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Extrabold.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, extrabold);
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-ExtraboldItalic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-ExtraboldItalic.woff') format('woff');
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, extrabold);
}

@font-face { //same as bold
  font-family: 'GT-Eesti-Pro-Display';
  src: url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Bold.woff2') format('woff2'),
    url('/assets/fonts/totalkredit/GT-Eesti-Pro-Display-Bold.woff') format('woff');
  font-style: normal;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
}


//============= Nykredit Fonts ===========//

@font-face {
  font-family: "NykreditSans-Bold";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-Bold.woff2") format("woff2"),
       url("/assets/fonts/nykredit/WOFF/NykreditSans-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NykreditSans-BoldItalic";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-BoldItalic.woff2") format("woff2"),
       url("/assets/fonts/nykredit/WOFF/NykreditSans-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "NykreditSans-ExtraBold";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-ExtraBold.woff2") format("woff2"),
       url("/assets/fonts/nykredit/WOFF/NykreditSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "NykreditSans-ExtraBoldItalic";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-ExtraBoldItalic.woff2") format("woff2"),
       url("/assets/fonts/nykredit/WOFF/NykreditSans-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "NykreditSans-Light";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-Light.woff2") format("woff2"),
       url("/assets/fonts/nykredit/WOFF/NykreditSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NykreditSans-LightItalic";
  src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-LightItalic.woff2") format("woff2"),
        url("/assets/fonts/nykredit/WOFF/NykreditSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
    font-family: "NykreditSans-Medium";
    src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-Medium.woff2") format("woff2"),
         url("/assets/fonts/nykredit/WOFF/NykreditSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "NykreditSans-MediumItalic";
    src: url("/assets/fonts/nykredit/WOFF/NykreditSans-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "NykreditSans-Regular";
    src: url("/assets/fonts/nykredit/WOFF/NykreditSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
@font-face {
    font-family: "NykreditSans-RegularItalic";
    src: url("/assets/fonts/nykredit/WOFF2/NykreditSans-RegularItalic.woff2") format("woff2"),
         url("/assets/fonts/nykredit/WOFF/NykreditSans-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}