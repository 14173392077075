 @import '../../foundation/_variables';
// $mobile-bond-info-breakpoint : 720px;

.nyk-select-fund-display-type {
    margin: 0;

    .mdc-radio {
        @include mdc-radio-unchecked-stroke-color(rgba($color-blue-dark, 0.54));
        @include mdc-radio-checked-stroke-color($color-turquoise);
        @include mdc-radio-ink-color($color-turquoise);

        &--small {
            width: 34px;
            height: 34px;
            left: -10px;

            .mdc-radio__background {
                left: 9px;
                top: 8px;
            }

            &+label {
                position: relative;
                left: -10px;
                padding-left: 0;
                padding-right: 17px;
            }
        }

        &+label {
            color: $color-blue-dark;
            cursor: pointer;
        }

        &--disabled {

            +label,
            .mdc-radio__background {
                opacity: 0.2;
            }
        }

        &::after,
        &::before {
            background-color: $color-blue;
        }
    }

    .mdc-radio__native-control:checked + .mdc-radio__background {
        .mdc-radio__outer-circle {
            border-color: $color-turquoise;
        }
        .mdc-radio__inner-circle {
            background-color: $color-turquoise;
        }
    }

    .nyk-select-custom-input {

        &__chevron {
            svg path {
                stroke: none;

            }

            polyline {
                stroke: $color-blue-dark;
            }

        }

        &__content-container {

            &--selected {
                max-height: unset;
                overflow-y: auto;
            }
        }
    }

}