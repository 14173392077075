.section--search-result {
  min-height: 20vh;
  @include bp-above-phone {
    padding-top:45px;
  }
}

.search-result {
  max-width: 890px;
  margin: 0 auto;
}

.search-result__title {
  border-bottom: 1px solid $color-grey-light;
  color: $color-blue-dark;
  padding-bottom: $gutter*4;
  margin-bottom: $gutter*4;

  span {
    text-transform: uppercase;
  }
}

.search-result__list {
  list-style: none;
  margin-bottom: $gutter*4;
}

.search-result__item-link {
  text-decoration: none;
}

.search-result__item {
  border-bottom: 1px solid $color-grey-light;
  padding: $gutter*4 0;

  &:first-child {
    padding-top: 0;
    > a{
      > .icon{
        top:6px;
      }
      .search-result__item__subject + .icon{
        top:0;
      }
    } 
     
  }

  p {
    margin-bottom: 0;
  }
}

.search-result__item-type {
  color: $color-turquoise;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .search-result__item-link:hover &,
  .search-result__item-link:focus & {
    text-decoration: underline;
  }
}


.search-result__item__icon{
  float:left;
  position: relative;
  top:-2px;
  color:$color-blue-dark;
  & + h2{
    padding-left:$gutter*5;
  }
}
.search-result__item__path{
  color:$color-olive;
  
}
.search-result__formfield{
  position: relative;
  &__textfield{
    width:100%;
    height:48px;
    @include bp-only-phone {
      margin-top:0 !important;
    }
    
    input{
      color:$color-blue-dark;
      padding-bottom: 13px;
      padding-top:0;
      font-size:32px;
      font-weight:800;
      line-height:1;
      height:auto;


      @include bp-only-phone {
        font-size:20px;
        padding-bottom: 6px;
      }

      &::placeholder{
        opacity:.2;
      }
      &::-ms-clear {
        display: none;
      }
    }
  }
  &__searchbtn{
    position: absolute;
    right:$gutter * 3;
    top:2px;

    .icon--sm{
      width:28px;
      height:28px;
    }

    @include bp-only-phone {
      right:20px;
      top:20px;

      .icon--sm{
          width:20px;
          height:20px;
      }
    }
  }
}
.search-result__count{
  margin-top:4px;
  letter-spacing:normal;
  @include bp-only-phone {
    margin-top:1px;
    font-size:14px;
  }
  &__number{
    padding-right:11px;
    @include bp-only-phone {
      font-size:16px;
      padding-right:10px;
    }
    @include bp-above-phone {
      position: relative;
      top:4px;
      font-size:25px;
    }
  }
}
.search-result__tabs{
  border-bottom:solid 1px rgba($color-black,0.1);
  overflow-x:auto;
  overflow-y:hidden;
  margin-top:0;

  @include bp-above-phone {
    margin:30px 0 40px 0;
  }

  &__grid{
    padding-bottom:0;
    .mdc-layout-grid__cell{
      margin-bottom:0;
    }
  }
  &__tab-bar{
    margin:0;
  }  
  &__tab{
    &.mdc-tab--active{
      font-weight:600;
    }
  }
  .mdc-tab-bar__indicator{
    height:3px;
  }
}
.search-result__item-grid{
  text-align: center;

  .search-result__list{
    text-align: left;
  }

  @include bp-only-phone {
    padding:0;
    .mdc-layout-grid__inner{
      grid-gap:0;
    }
    .search-result__item{
      padding-left:$gutter*2;
      padding-right:$gutter*2;
      &:first-child{
        padding-top:32px;
        > a > .icon{
          top:-2px;
        }
        
      }
    }
  }
}
.search-result__item__title{
  font-size:23px;
}
.search-result__item__filelink {
  color: $color-turquoise;
  text-decoration:none;

  .search-result__item:hover &,
  .search-result__item:focus & {
    text-decoration: underline;
  }
}
.search-result__item__body{
  margin-top:4px;
}
.search-result__item__subject{
  text-transform: uppercase;
  padding-bottom:10px;
  color:$color-blue-dark;
}
.search-result__button{
  margin:0 auto;
}

.search-result-subjects{
  text-align: left;
  margin-bottom:70px;

  @include bp-only-phone {
    display:none;
  }
  &__caption{
    text-transform: uppercase;
    padding:$gutter;
    color:$color-black;
  }
  label{
    color:$color-dark;
    font-size:16px;
  }
}

.search-popular-searches{
  text-align: left;
  

  @include bp-only-phone {
    display:none;
  }
  &__caption{
    text-transform: uppercase;
    padding:0 $gutter;
    color:$color-black;
    white-space: nowrap;
    margin-bottom: 0;
  }
  ul{
    list-style:none;
    padding-top:5px;
  }
  a{
    color:$color-turquoise;
    text-decoration: none;
    padding:6px $gutter;
    display:block;

    &:hover{
      text-decoration: underline;
    }
  }
}