.fund-disclaimer {
    overflow: hidden;
    
    .fund-disclaimer--background {
        position: absolute;
        top: 64px;
        width: 100%;
        height: 10000px;
        background: rgba(0, 0, 0, .7);
        z-index: 5;

        &__nykinvest {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .fund-disclaimer--modal {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 6;
        padding-bottom: 64px;

        &__nykinvest {
            top: 0;
            left: 0;
            padding: 0;
        }
    }

    .fund-disclaimer--modal--inner {
        width: 600px;
        border-radius: 5px;
        background-color: white;

        .fund-disclaimer--modal--inner--content {
            padding: 30px;
            background-color: white;
            border-radius: 8px 8px 0 0;
            max-height: calc(70vh - 132px);
            overflow: auto;
        }

        .fund-disclaimer--modal--inner--footer {
            padding: 30px;
            top: 0;
            background-color: rgb(245, 245, 245);
            display: flex;
            justify-content: space-between;
            border-radius: 0 0 8px 8px;
        }
    }
}