$mobile-height: 82px;

// investment fund list variation modifications
.mdc-toolbar.toolbar--investment-fund-list {
    background: none;

    .mdc-toolbar__row {
        border-top: 1px solid $color-grey-lighter;
        border-bottom: 1px solid $color-grey-lighter;
        width: 100%;
        margin: 0;
        padding: 1.5rem 0;
    }

    .toolbar__nav-link.toolbar-secondary__nav-link {
        color: $color-blue-dark;
        font-size: 14px;
        letter-spacing: 0.04rem;
        font-weight: 400;


        &:hover {
            font-weight: 500;
        }

        &:focus {
            font-weight: 700;
        }
    }


}


.mdc-toolbar__row {
    padding-top: 20px;
    padding-bottom: 20px;

    // Adjust side padding.
    @each $size in map-keys($mdc-layout-grid-columns) {
        @include mdc-layout-grid-media-query_($size) {
            padding-left: #{map-get($mdc-layout-grid-default-margin, $size)};
            padding-right: #{map-get($mdc-layout-grid-default-margin, $size)};
        }
    }
}

.nav-primary {
    background-color: $color-white;
    color: black;
    position: relative;
    z-index: 2;
    max-height: $mdc-toolbar-row-height;

    &--inverted {
        background-color: $color-bg-nav-primary;
        max-height: 40px;

        @include bp-until-desktop-wide {
            display: none;
        }

        .mdc-toolbar__row {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 40px;
        }
    }

    &.with-expanded-menu {
        z-index: 6;
    }

    .toolbar__nav:not(.toolbar-primary__nav-buttons):not(.toolbar-primary__nav) {
        @include fluid(margin-left, 22, 82, 840);
        margin-bottom: 0;

        @include bp-until-desktop-wide {
            display: none;
        }
    }

    .mdc-toolbar__row {
        max-height: 64px;
    }
}

.nav-primary__devider {
    background-color: $color-grey-light;
    height: 1px;

    .search-expanded & {
        display: none;
    }
}

.invest .nav-secondary {
    z-index: 3;
}

.nav-secondary {
    background-color: $mdc-theme-primary;
    color: $color-white;
    margin-top: -1px; // Pull up above primary nav devider line.
    z-index: 1;

    &.mdc-toolbar--fixed {
        transform: translateY($mdc-toolbar-row-height); //inital offset
    }

    &.mdc-toolbar--fixed--on-mobile {
        @include bp-until-desktop-wide {
            position: fixed;
            transform: translateY(0); //inital offset
            top: 0;
            left: 0;
            z-index: 3;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12);
        }
    }

    @include bp-above-desktop-wide {
        .toolbar__nav:not(.toolbar__nav--no-margin) {
            margin-left: 82px + 130px;
        }
    }

    .mdc-toolbar__row {
        min-height: 44px;
        padding-top: 10px;
        padding-bottom: 10px;

    }

    @include bp-until-desktop-wide {

        .mdc-toolbar__row {
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;

            position: relative;
        }

        .mdc-toolbar__section {
            justify-content: flex-start;
        }

        .mdc-toolbar__section.mdc-toolbar__sction-show-lg {
            z-index: -1;
        }
    }

    &--white {
        background-color: white;
        color: black;
    }

    &--primary-on-mobile {
        >.mdc-toolbar__row {
            max-height: 63px;
        }

        @include bp-until-desktop-wide {
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        }

        &.search--fullwidth.search-expanded {
            .mdc-toolbar__row {
                @include bp-until-desktop-wide {
                    min-height: $mobile-height;
                    padding: 0;
                    overflow-x: hidden;
                }
            }
        }

        &:before,
        &:after {
            display: none;
        }

        .mdc-toolbar__row {
            @include bp-until-desktop-wide {
                height: 58px;
            }
        }
    }

    &.secondary-toolbar--fixed {
        @include bp-until-desktop-wide {
            position: fixed;
            z-index: 2;

            &.mdc-toolbar--flexible-space-minimized {
                top: 0;
                left: 0;
            }
        }
    }
}

.toolbar__logo {
    img {
        display: block;
        width: 130px;
    }

    &--small {
        img {
            width: 105px;
        }
    }

    &--large {
        img {
            width: 136px;

            @include bp-until-desktop-wide {
                width: 105px;
            }
        }
    }

    @include bp-until-desktop-wide {
        position: absolute;
        left: 50%;
        top: calc(50px - #{$gutter} * 2);
        transform: translate(-50%, -50%);
    }
}

.toolbar__drawer-toggle {
    line-height: 0;
    margin-right: $gutter * 2;

    @include bp-above-desktop-wide {
        display: none;
    }
}

.toolbar__nav {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;

    &--dt-only {
        @include bp-until-desktop-wide {
            display: none;
        }
    }
}

.toolbar__nav-item {
    display: flex;
    align-items: center;
    position: relative;

    svg {
        transition: transform 0.3s ease;
    }

    &.with-seperator {
        &:before {
            content: "";
            position: absolute;
            top: calc(50% - 12px);
            right: 0;
            height: 24px;
            width: 1px;
            border-right: 1px solid $color-grey-light;
        }
    }

    &.with-dropdown {
        padding-right: $gutter * 3.2;
    }

    &.is-open svg {
        transform: rotate(180deg);
    }
}

.toolbar__nav-item-icon {
    line-height: 0;
    position: relative;

    // Border between items.
    @include bp-above-desktop-wide {
        &:not(:last-child) {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                background-color: #e7e7e7;
                width: 1px;
                height: 26px;
            }
        }

        &--small {
            font-size: 14px;

            .toolbar-primary__nav-button {
                height: 100%;

                >svg {
                    margin-top: 13px;
                }

                div {
                    padding-top: 13px;
                }
            }

            &:first-child {
                &::after {
                    content: "";
                    position: absolute;
                    top: 12px;
                    height: 16px;
                    left: 0;
                    background-color: #e7e7e7;
                    width: 1px;
                }
            }

            &:not(:last-child) {
                &::before {
                    top: 12px;
                    height: 16px;
                }
            }
        }
    }

    @include bp-until-desktop-wide {
        &:not(.toolbar__nav-item-icon-permanent) {
            display: none;
        }
    }
}

.toolbar__nav-item-lang {
    svg {
        opacity: 1;
        // transition: opacity 0.3s $mdc-animation-deceleration-curve-timing-function 0.3s;
    }

    a {

        &:hover,
        &:focus {
            svg {
                opacity: 1;
            }
        }
    }

    div {
        svg {
            opacity: 1;
        }
    }
}

.toolbar__nav-link {
    color: currentColor;
    display: block;
    font-weight: map-get($mdc-typography-font-weight-values, semibold);
    padding: 0 $gutter * 2;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    .toolbar__nav--ttn & {
        text-transform: none;
    }

    .toolbar__nav--mit-nyk & {
        text-transform: none;
        color: #0f1e82;
    }
}

.NykreditDk, .Dotcom, .invest {
    .toolbar__nav-link {
        display: block;
        font-weight: 400;
        padding: 0 $gutter * 2;
        text-decoration: none;
        white-space: nowrap;
    
        .toolbar__nav--ttn & {
            text-transform: none;
        }
        
        .toolbar__nav--mit-nyk & {
            text-transform: none;
            color: #0f1e82;  
        }
    }
}

.toolbar-secondary__nav-toggle {
    z-index: 1;
    margin-left: -$gutter;
    margin-right: $gutter;

    @include bp-until-desktop-wide {
        display: none;
    }
}

.toolbar-secondary__nav-menu {
    background-color: $mdc-theme-primary;
    top: calc(100% + 11px);
    left: 0;
    min-width: 100%;

    .mdc-list {
        color: $color-white;
    }

    .mdc-list-item {
        font-size: 0.8125rem;
        text-transform: uppercase;
    }

    a {
        color: currentColor;
        display: block;
        pointer-events: none;
        width: 100%;
    }
}

.toolbar-primary__nav-link,
.toolbar-primary__nav-button {
    &:after {
        content: "";
        position: absolute;
        left: $gutter * 2;
        bottom: -20px;
        width: calc(100% - #{$gutter * 4});
        height: 2px;
        border-bottom: 2px solid $mdc-theme-secondary;
        opacity: 0;
        transform: translateY($gutter/4);
        transition: all 0.2s $mdc-animation-acceleration-curve-timing-function;
        pointer-events: none;

        .toolbar__nav--mit-nyk & {
            bottom: 0px;
        }
    }

    &:hover,
    &:focus,
    &.is-active {
        &:after {
            opacity: 1;
            transform: none;
        }

        svg {
            opacity: 1;
        }

        @media (max-width: 500px) {
            &.is-active.hide-on-mobile {
                display: none;
            }
        }
    }

    // Remove active indicator when search is expanded.
    .search-expanded & {
        opacity: 0;
        transform: translateY(-$gutter/4);

        @include bp-above-desktop-wide {
            opacity: 100;
            transform: translateY(0px);
        }
    }
}

.toolbar-primary__nav-buttons {
    margin-right: -$gutter/2;

    @include bp-above-desktop-wide {
        margin-right: -$gutter * 2;
    }
}

.toolbar-primary__nav-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    line-height: 0;
    position: relative;
    padding: 0 $gutter/2;

    @include bp-above-desktop-wide {
        padding: 0 $gutter * 2;
    }

    * {
        pointer-events: none;
    }

    div {
        line-height: 1;
        margin-top: $gutter;
        text-transform: uppercase;
        min-width: 40px;
        text-align: center;
        margin-bottom: 0;

        @include bp-until-desktop-wide {
            display: none;
        }
    }

    .toolbar__nav-item-lang & {
        div {
            margin-top: 0;
            text-transform: none;
        }
    }

    &.lang-dotcom {

        @include bp-above-phone {
            margin-right: $gutter * 3;
        }

        @include bp-only-phone {
            margin-right: $gutter * 1.5;
        }

        @include bp-above-desktop-wide {
            margin-right: 0px;
        }
    }

    &:after {
        bottom: -15px;

        @include bp-until-desktop-wide {
            left: 0;
            width: 100%;
        }
    }
}

.toolbar-secondary__nav-link,
.toolbar-secondary__nav-toggle,
.toolbar-secondary__nav-menu .mdc-list-item {
    opacity: 0.7;
    transition: opacity 0.2s $mdc-animation-acceleration-curve-timing-function;

    &:hover,
    &:focus,
    &.is-active {
        opacity: 1;
    }

    // Remove active indicator when search is expanded.
    .search-expanded & {
        opacity: 0.5;
    }
}

.toolbar__invest-menu {
    padding-top: $mdc-toolbar-row-height;
    width: 100%;
    overflow-y: hidden;
}

.toolbar__login-wrapper {
    line-height: 0;
    width: 100%;

    @include bp-above-phone {
        position: relative;
    }

    @include bp-until-desktop-wide {
        li+li {
            padding-left: $gutter * 3;
        }
    }

    &--shrink {
        @include bp-until-desktop-wide {
            li+li {
                padding-left: $gutter;
            }
        }
    }

    ul {
        justify-content: flex-end;
    }
}

.toolbar__login-menu {
    padding-top: 125px + $mdc-toolbar-row-height;
    width: calc(100vw - #{$gutter * 2});
    max-height: none;

    @include bp-only-phone {
        max-width: none;
        min-height: 100vh;
        margin-top: $gutter * 2;
    }

    @include bp-above-phone {
        width: calc(100vw - #{$gutter * 6});
    }

    @include bp-above-tablet {
        padding-top: 170px + $mdc-toolbar-row-height;
    }

    &::before {
        position: absolute;
        content: "";
        top: $mdc-toolbar-row-height;
        left: 0;
        width: 100%;
        height: 125px;
        background: url("/assets/images/invest/menu-login.png") center center no-repeat;
        background-size: cover;

        @include bp-above-tablet {
            height: 170px;
        }
    }

    @include bp-above-tablet {
        max-width: 385px;
    }

    .mdc-list-item {
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        white-space: normal;
        padding: 16px;

        &--lg-spacing {
            padding: $gutter * 5;
        }
    }

    .mdc-list-item--lg-spacing+.mdc-list-item--lg-spacing {
        padding-top: 10px;
    }

    p {
        margin-top: 0;
        max-width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        @include bp-only-phone {
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }
}

.toolbar__invest-menu-anchor,
.toolbar__login-menu-anchor {
    position: absolute;
    top: -15px;
    right: 0;
    width: 100%;

    @include bp-above-tablet {
        width: 385px;
    }

    .mdc-list-item {
        a {
            text-decoration: none;
        }
    }
}

.toolbar__login-menu-anchor {
    .mdc-list-item a {
        padding: $gutter;

        &:not(.mdc-button) {
            text-decoration: underline;
            color: $color-turquoise;
            padding: $gutter 0;
        }
    }
}

.toolbar__invest-menu-anchor {
    width: 130%;
}

.toolbar__invest-menu-toggle {
    position: absolute;
    right: $gutter * 2;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;

    svg {
        width: 0.75rem;
        height: 0.75rem;
    }
}

.toolbar__menu-close {
    line-height: 0;
    position: absolute;
    right: $gutter * 2;
    top: ($mdc-toolbar-row-height / 2);
    transform: translateY(-50%);
}

h3.toolbar__headline {
    line-height: 0;
    position: absolute;
    left: $gutter * 2;
    top: 18px;
    transform: translateY(-50%);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    color: #07094a;
}

// Search
.toolbar__search {
    width: 0;
    z-index: 99;
    transition: all 0.3s ease;

    @include bp-until-desktop-wide {
        display: none;
    }

    &-mobile {
        position: absolute;
        right: (24px + 28px + 16px);
        top: 10px;
        z-index: 2;

        @include bp-above-desktop-wide {
            display: none;
        }

        @include bp-until-desktop-wide {
            .search-expanded & {
                width: calc(100% - 136px);

                .toolbar__search-submit,
                .toolbar__search-input,
                .toolbar__search-close {
                    display: block;
                }
            }
        }
    }

    .search-expanded & {

        .toolbar__search-submit,
        .toolbar__search-input,
        .toolbar__search-close {
            display: block;
        }
    }

    @include bp-above-desktop-wide {
        .search-expanded & {
            width: inherit;
            flex-grow: 1;
            margin-right: -65px;
            position: relative;
            right: 0;
        }
    }

    &--fullwidth {
        //transition: none;
        //display:none;
        //width:0;
        transform: scaleY(0);
        transition: transform 0.2s;
        transform-origin: top;

        .search-expanded & {
            position: absolute;
            left: 0;
            top: -5px;
            width: 100%;
            margin-right: 0;
            background-color: $color-blue;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
            display: block;
            transform: scaleY(1);
            transition: transform 0.2s;

            @include bp-until-desktop-wide {
                height: $mobile-height;
                top: 0;

                .mdc-layout-grid {
                    padding-top: 0;
                }
            }

            form {
                display: flex;
            }

            .toolbar__search-submit {
                display: none;
            }

            .toolbar__search-input {
                background-color: $color-blue;
                padding: 0;
                font-size: 32px;
                font-weight: 800;
                color: $color-white;
                box-shadow: none;
                border-bottom: solid 1px $color-white;
                appearance: none;

                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 30px $color-blue inset;
                    -webkit-text-fill-color: $color-white;
                }

                @include bp-until-desktop-wide {
                    font-size: 20px;
                }

                &::-ms-clear {
                    display: none;
                }

                &::placeholder {
                    opacity: 0.4;
                    color: $color-white;
                }

                // IE fix to allow placeholder
                &:-ms-input-placeholder {
                    opacity: 1 !important;
                    color: $color-white !important;
                }
            }

            .toolbar__search-close {
                color: $color-white;
                position: static;
            }
        }

        &__result {
            position: absolute;
            left: 0;
            background-color: $color-white;
            width: 100%;

            transform: scaleY(0);
            @include mdc-elevation(3);

            &--expanded {
                .search-expanded & {
                    overflow: hidden;
                    transition: transform 0.3s;
                    height: auto;
                    transform: scaleY(1);
                    transform-origin: top;

                    @include bp-until-desktop-wide {
                        height: calc(100vh - 82px);
                    }
                }
            }

            @include bp-until-desktop-wide {
                height: calc(100vh - 82px);
                padding-bottom: $gutter * 10;
                top: calc($mobile-height - 1);
                position: fixed;
            }

            ul {
                list-style: none;
                padding-top: $gutter;
            }

            &__item {
                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }

                &__link {
                    text-decoration: none;
                    display: block;
                    border-bottom: solid 1px $color-beige-dark;
                    padding: $gutter * 1.5 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    em {
                        font-style: normal;
                        font-weight: 700;
                    }
                }
            }

            &__help {
                width: 100%;
                font-size: 14px;
                border-top: solid 1px $color-beige-dark;

                .mdc-layout-grid {
                    padding-top: $gutter * 2;
                    padding-bottom: $gutter * 2;
                }

                @include bp-until-desktop-wide {
                    padding-top: 0;
                    position: absolute;
                    bottom: 0;
                }

                a {
                    color: $color-turquoise;
                }
            }
        }
    }
}

.toolbar__search-submit {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 12px);
    left: 10px;
    display: none;
}

.toolbar__search-input {
    @include mdc-elevation(1);
    // @include mdc-elevation-transition;
    appearance: none;
    background-color: $color-white;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    display: none;
    height: 44px;
    width: 100%;
    padding: 0 $gutter * 5;
    outline: none;
    transition: all 0.4s ease;

    &::placeholder {
        opacity: 0.5;
    }

    &:hover,
    &:focus {
        @include mdc-elevation(4);
    }
}

.toolbar__search-close {
    position: absolute;
    top: calc(50% - 10px);
    right: 10px;
    display: none;
}

.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: 0.2;
    z-index: 10000;
}

.header--search-expanded {
    position: relative;
    z-index: 10001; //above overlay
}

// TK-specific menu
.tk-toolbar {
    flex: none;
    order: 0;
}

.tk-toolbar-left-content-logo {
    @extend .tk-toolbar;
    justify-content: start;
    padding-right: 65px;
    padding-left: 0;
}

.tk-toolbar-left-content-items {
    @extend .tk-toolbar;
    justify-content: start;
    margin-right: auto;
}

.tk-toolbar-right-content {
    @extend .tk-toolbar;
    justify-content: end;
}

.tk-toolbar-right-content-divider {
    width: 1px;
    height: 24px;
    border: solid 1px #ffffff;
    margin-top: auto;
    margin-right: 16px;
}

.toolbar-language {
    &__dropdown {
        &:hover {
            .toolbar-language__dropdown-content {
                opacity: 1;
                top: 0;
                z-index: 7;
            }
        }
    }

    &__dropdown-title {
        color: $mdc-theme-tertiary;
        margin-left: 19px;
        margin-top: 25px;
        margin-bottom: 25px;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__dropdown-content {
        opacity: 0;
        right: 0;
        width: 200px;
        background-color: $mdc-theme-background;
        position: absolute;
        top: -999px;
        border-radius: 2px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transition: opacity 0.15s ease-in-out;
    }

    &__dropdown-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 11px 0;
        border-top: 1px solid $color-grey-medium;

        @include bp-until-desktop-wide {
            li+li {
                padding-left: 16px;
            }
        }
    }

    &__dropdown-item {
        min-height: 48px;
        list-style-type: none;
        color: $color-dark;
        display: flex;
        align-items: center;
        width: 100%;

        svg {
            color: transparent;
        }

        span {
            margin-left: 10px;
        }

        &--selected {
            color: $mdc-theme-tertiary;

            .toolbar-language__dropdown-item-checkmark {
                display: block;
            }
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-self: stretch;
            width: 100%;
            padding: 0;

            &:after {
                display: none;
            }
        }
    }

    &__dropdown-item-checkmark {
        margin-left: auto;
        display: none;

        svg {
            stroke: $mdc-theme-tertiary;
        }
    }
}

.NykreditDk, .Dotcom, .invest {
    .toolbar__logo {
        img {
            display: block;
            width: 130px;
            border-radius: 0px;
        }
        &--small {
            img {
                width: 105px;
            }
        }
        &--large {
            img {
                width: 136px;
                @include bp-until-desktop-wide {
                    width: 105px;
                }
            }
        }
    
        @include bp-until-desktop-wide {
            position: absolute;
            left: 50%;
            top: calc(50px - #{$gutter} * 2);
            transform: translate(-50%, -50%);
        }
    }
}