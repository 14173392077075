.footer {
  color: $color-blue-dark;
}

.footer__scroll-btn-wrapper {
  position: relative;
}

.footer__scroll-btn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);

  // Adjust for .mdc-layout-grid__inner
  @each $size in map-keys($mdc-layout-grid-columns) {
    @include mdc-layout-grid-media-query_($size) {
      margin-top: calc(-#{map-get($mdc-layout-grid-default-margin, $size)} - #{$gutter*8});
    }
  }
}

.NykreditDk, .Dotcom, .invest {
  .footer__scroll-btn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  
    // Adjust for .mdc-layout-grid__inner
    @each $size in map-keys($mdc-layout-grid-columns) {
      @include mdc-layout-grid-media-query_($size) {
        margin-top: calc(-#{map-get($mdc-layout-grid-default-margin, $size)} - #{$gutter*10});
      }
    }
  }
}

.footer__social {
  flex: 1 1 100%;
  min-width: 100%;
  width: 100%;
  margin: $gutter*4 0;
}

.footer__logo {
  display: block;
  width: 130px;
  margin-bottom: $gutter;
}

//============= DESKTOP ==============
.desktop-footer {
  @include bp-until-desktop {
    display: none;
  }
}

.desktop-footer__sections {
  display: flex;
}

.desktop-footer__section {
  flex: 0 1 calc(100% / 5);

  flex-basis: calc(100% / 5); // IE no shortands when using calc :facepalm:

  &:first-child {
    flex-grow: 1;
  }

  @include bp-until-desktop {
    flex: 1 1 50%;
    padding-bottom: $gutter*4;
  }

  @include bp-only-phone {
    flex: 1 1 100%;
  }
}

.desktop-footer__link {
  display: block;
  text-decoration: none;
  color: $color-blue-dark;
  padding: $gutter/2 0;

  &:hover {
    text-decoration: underline;
  }
}

.desktop-footer__title {
  color: $color-blue-dark;
  margin-top: 0;
  margin-bottom: $gutter;
  text-transform: uppercase;
}

.desktop-footer__address {
  p {
    margin: 0;
    white-space: pre;
  }
}

.desktop-footer__phone {
  display: block;
  text-decoration: none;
  color: $color-blue-dark;
  margin-top: $gutter*2;
  margin-bottom: $gutter*4;
}

//============= MOBILE ==============
.mobile-footer {
  display: none;

  @include bp-until-desktop {
    display: block;
  }

  .footer-link-list {
    flex: 1 1 100%;
    min-width: 100%;
    width: 100%;

    li a {
      display: block;
      font-size: 0.875rem;
      padding: $gutter 0;
      text-decoration: none;
    }
  }

  .footer-address {
    color: $color-blue-dark;
    font-size: 14px;

    &__address {
      padding: $gutter 0 $gutter*4 0;
    }
  }
}

.footer-accordion {
  flex: 1 1 100%;
  min-width: 100%;
  width: 100%;

  &__section {
    border-top: 1px solid lighten($color-olive, 15%);
    padding: $gutter*2 $gutter / 2;

    &:last-child {
      border-bottom: 1px solid lighten($color-olive, 15%);
    }

    &.open .footer-accordion__section-title svg {
      transform: rotate(45deg);
    }

    &-title {
      cursor: pointer;

      svg {
        transition: transform 0.3s ease;
        float: right;
        top: -5px;
        position: relative;

        .open & {
          transform: rotate(45deg);
        }
      }
    }

    &-content {
      transition: height 0.3s ease;
      overflow: hidden;
      height: 0;

      &--inner {
        padding-top: $gutter*2;
      }
    }
  }

  .footer-accordion__section-title > * {
    pointer-events: none;
  }
}

.footer-accordion__icon-closed {
  .is-open & {
    display: none;
  }
}

.footer-accordion__icon-opened {
  display: none;

  .is-open & {
    display: block;
  }
}
