.fund-detail {

    &__nav-secondary{
        @include bp-until-desktop {
            display: none;
        }
    }

    .fund-detail__sustainability {
        &--title {
            color: $color-blue;
            font-size: 28px;
            padding-bottom: 7px;
            font-weight: 800;
        }

        &--subtitle {
            color: #131313;
            font-size: 14px;
            font-weight: 300;

            @media (min-width: 840px) {
                max-width: 255px;
            }
        }

        &--text {
            font-size: 18px;
            margin-top: 34px;
            color: #131313;

            @media (max-width: 840px) {
                margin-right: 0;
            }
        }

        &__labels {
            &__sfdr {
                padding-top: 80px;
                padding-bottom: 80px;
                height: 100%;

                &__container {
                    padding-left: 64px;
                    padding-right: 64px;

                    @media (max-width: 640px) {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    &__title {
                        display: block;

                        @media (min-width: 840px) {
                            min-height: 100px;
                        }

                    }
                }

                &__leaf {
                    color: #009f4d;

                    @media (max-width: 640px) {
                        margin-top: 10px;
                    }

                    svg {
                        width: 27px;
                        height: 27px;
                    }

                    &__text {
                        text-align: center;
                        min-width: 80px;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 100;
                    }

                    &__number {
                        font-size: 60px;
                        line-height: 35px;
                        display: inline-block;
                        font-weight: 600;
                    }

                    &__cont {
                        text-align: center;
                        min-width: 80px;
                    }
                }
            }

            &__swan {
                padding-top: 80px !important;
                padding-bottom: 80px;
                height: 100%;

                &__container {
                    padding-left: 64px;
                    padding-right: 64px;

                    @media (max-width: 640px) {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    &__title {
                        display: block;

                        @media (min-width: 840px) {
                            min-height: 100px;
                        }
                    }
                }

                &__nordic-swan {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: -10px;

                    @media (max-width: 640px) {
                        justify-content: center;
                        margin-top: 15px;
                    }

                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }

        &__esg {
            &__textarea {
                padding-left: 60px;
                padding-right: 60px;

                @media (max-width: 840px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        &__co2 {
            padding-bottom: 0;

            &__textarea {
                padding-top: 60px;
                padding-left: 60px;
                padding-right: 60px;

                @media (max-width: 840px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            &__tab-header {
                text-align: left;
                font-weight: 800;
            }

            &__tab-sub-header {
                text-align: left;
                margin-top: -10px;
                font-weight: 200;
            }
        }

        &__sdg {
            padding-bottom: 0;

            &__chart {
                @media (max-width: 840px) {
                    display: none;
                }

                &--mobile {
                    display: none;

                    @media (max-width: 840px) {
                        display: block;
                    }
                }
            }
        }
    }

    .fund__detail-buttons {
        justify-content: center;
    }

    .fund__detail-disclaimer {
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 80px;
        @include bp-until-tablet {
            bottom: 0px;
        }
    }

    &__growth-chart-scale {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-bottom: 25px;

        .mdc-button {
            min-width: 0;
            margin-left: 0;
        }

        &--investment-fund-list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1.75rem;

            .mdc-button {
                padding: 0 0;
                height: fit-content;
                color: $color-blue-dark;
                text-transform: none;
                font-weight: 400;
            }

            .js-mdc-ripple.mdc-button--primary.mdc-button--raised.fund-detail__date-input--investment-fund-list-submit {
                padding: 0.75rem 1rem;
                width: 50%;
                background: $color-blue;
                color: $color-white;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: normal;
                font-size: 14px;
            }

            .js-mdc-ripple.mdc-button--primary.mdc-button--raised {
                background: none;
                box-shadow: none;
                color: $color-blue-dark;
                font-weight: bold;
            }

            .mdc-button.js-mdc-ripple.mdc-button--primary.mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused {

                svg {
                    transform: rotate(180deg);
                }

            }

        }
    }

    &__date-container {
        display: inline-flex;
        flex-direction: column;
        text-align: left;

        .mdc-menu {
            right: 0;
            top: 0;

            &__items {
                padding: $gutter * 2;
            }
        }

        &--investment-fund-list {

            .mdc-menu__items {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-top: 1rem;
            }

            .mdc-menu {
                left: 0;
                top: 0;
                width: fit-content;

                &__items {
                    background-color: white;
                }
            }

            label {
                font-size: 14px;
            }

            label:nth-child(8) {
                margin-top: 1.2rem;

            }

            &-dropdown-button {
                display: flex;
                justify-content: space-between;
                width: 100%;
                min-width: 16rem;
                margin-left: 0;
                border-bottom: 1px solid $color-grey-neutral;
                color: $color-blue-dark;
                text-transform: none;
                padding: 0.85rem 0.5rem;
                font-weight: 400;
                align-items: center;

                &-item {
                    display: flex;
                    justify-items: flex-start;
                    width: 100%;
                    margin-bottom: 0.15rem;

                }

                svg {
                    width: 1rem;
                    height: 1rem;
                    stroke: $color-blue-dark;

                }
            }

        }
    }

    &__date-input {
        display: block;
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;

        input {
            min-width: 255px; // width of date-picker
            padding-top: 0;
        }

        &--investment-fund-list {
            width: 100%;
            padding: 0.1rem 0;


            input {
                min-width: 100%;
                font-size: 16px;
               
            }

            .mdc-text-field__input.ng-pristine.ng-valid.ng-isolate-scope.moment-picker-input.ng-not-empty.ng-valid-min-date.ng-valid-max-date {
                color: $color-blue-dark;
                border-bottom: 1px solid rgba($color-blue-dark, .5);
            }

        }
    }

    &__growth-chart-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__chart-last-update {
        float: right;
    }

    &__holdings {
        &__header-link {
            color: inherit;
            text-decoration: inherit;
            white-space: nowrap;
        }

        &__sort-indicator {
            display: inline-block;
            border-style: solid;
            border-color: $color-white transparent;
            margin-left: $gutter;
            user-select: none;
            transform: translateY(-50%);

            &--ascending {
                border-width: 4px 4px 0;
            }

            &--descending {
                border-width: 0 4px 4px;
            }
        }

        &__legend {
            color: #333;
            font-size: 12px;
            display: inline-block;
            margin-left: 30px;
            margin-top: 10px;

            .icon {
                vertical-align: middle;
                margin-right: 3px;
            }
        }

        &__buttons {
            text-align: right;
        }

        @include bp-until-desktop {

            // Mockup of .table -- to switch between the header and body rows
            &__row {
                display: table;
                width: 100%;
            }

            &__cell {
                &:last-child>span {
                    padding-bottom: $gutter * 2;
                }
            }

            &__cell-header {
                display: table-header-group;
                background-color: $color-beige-dark;
            }

            &__cell-body {
                display: table-row-group;
            }

            &__cell>span {
                display: flex;
                justify-content: space-between;
                padding: $gutter $gutter * 2;
                align-items: center;
            }

            &__cell>span:before {
                content: attr(data-title);
                font-weight: map-get($mdc-typography-font-weight-values, bold);
                max-width: 120px;
                margin-right: $gutter * 2;
                word-wrap: break-word;
                flex-shrink: 0; //fix Safari bug (.com NYK0007-572)
                text-align: left;
            }

            &__cell-header>span:before {
                display: none;
            }

            &__cell-header>span {
                font-weight: map-get($mdc-typography-font-weight-values, bold);
            }
        }
    }

    .title {
        .tooltip img {
            position: relative;
            top: 10px;
            right: auto;
        }
    }
}