$mobile-bond-info-breakpoint : 720px;

.nyk-select-custom-input {
    width: fit-content;
    min-width: 208px;
    position: relative;
    margin: 0;

    .mdc-form-field>label {
        cursor: pointer;
        padding: 5px 0;
        width: 100%;
    }

    &__selected {
        border-radius: 2px;
        font-weight: 16px;
        background-color: white;
        padding: 10px 15px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 3.3rem;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid rgba(0, 0, 0, 0.12);
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        span {
            color: inherit;
        }


    }

    &__selected-value {
        padding-left: 10px;
    }

    &__content-container {
        padding: 5px 0;
        top: 56px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 4px 2px -2px rgba(0, 0, 0, 0.12), 0px 3px 2px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        background-color: white;
        position: absolute;
        min-width: 100%;
        width: auto;
        max-height: 1px;
        opacity: 0;
        z-index: -1;
        transition: max-height 0.15s ease-in-out, opacity 0.15s ease-in-out;

        &--selected {
            height: auto;
            z-index: 1;
            opacity: 1;
            max-height: unset;
            width: 100%;

            &-dual-range { 
                @media screen and (min-width: 480px) {
                    width: 120%;
                }

            }
        }
    }

    &__content-list-item {
        padding: 0.85rem 1.5rem;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;

        &--selected {
            color: red;
        }

        &:hover {
            color: red;
        }

        &:first-child {
            padding: 1.5rem 1.5rem 0.85rem 1.5rem;
        }

        &:last-child {
            padding: 0.85rem 1.5rem 1.5rem 1.5rem;
        }
    }

    &__chevron {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        &--rotated {
            padding-left: 0;
            transform: rotate(180deg);
        }
    }

    .mdc-form-field {
        cursor: pointer;
    }

    &__mobile-checked-button {
        font-size: 14px;
        border: 1px solid #07094A;
        padding: 12px 10px;
        margin: 4px;
        width: calc(50% - 10px);

        &--selected {
            color: white;
            background-color: #68D2DF;
            border: 1px solid #68D2DF;
        }
    }
}