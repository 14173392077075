@mixin result-width {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.mdc-floating-label {
    padding-left: 10px;
    bottom: 15px;

    //opacity:.56;
    &--float-above {
        transform: translateY(-100%) scale(0.75);
    }
}

// Comment begin: These rules are placeholders untill general redesign of form container
.varme-beregner-form {
    .theme--white {
        background-color: $color-beige;
    }

    .Form__MainBody {
        padding: 40px;
        background-color: $color-white;

        @include bp-only-phone {
            padding: 24px 16px 24px 16px;
        }
    }

    .permission-choice {
        padding: 5px 3px 5px 3px;
        margin-bottom: 10px;
        background-color: #f8f6f5
    }

    .section {
        padding-top: 40px;
    }

}

// Comment End

.varme-beregner {


    &__container {
        padding-top: 48px;

        @include bp-only-tablet {
            padding: 0 32px 0 32px;
            background-color: #f7f6f5;
        }

        @include bp-until-tablet {
            padding: 0 16px 0 16px;
            background-color: #f7f6f5;
        }

        .mdc-layout-grid__inner-phone {
            @include bp-only-phone {
                grid-gap: 8px;
            }
        }

        .mdc-select {
            height: 56px;
        }

        .mdc-text-field__affix--prefix {
            position: absolute;
            top: 6px;
            left: 3px;

            .mdc-text-field--disabled {
                color: rgba(0, 0, 0, .6);
            }
        }

        .mdc-tab::after,
        .mdc-tab::before {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        .mdc-select {
            background-image: none;
        }

        .accordion-open {
            overflow: visible;
        }

        .accordion-closed {
            display: none;
        }

        .dawa-autocomplete-suggestions {
            list-style-type: none;
        }

        .dawa-selected {
            background-color: #ede8e6;
        }

        .dawa-autocomplete-suggestion {
            padding-left: 12px;
        }

        .accordion__item--white {
            background-color: $color-white;
        }

        .nk-modal li {
            padding-left: 30px;

            &::before {
                left: 0;
            }
        }
    }

    .intro {
        @include bp-only-phone {
            margin-top: 68px;
        }
    }

    &__input-postfix {
        position: absolute;
        top: 20px;
        right: 10px;
        @include bp-until-desktop {
            position: static;
            margin-bottom: 4px;
            margin-right: 4px;
            margin-left: -10px;
            font-size: 14px;
        }
    }

    &__disclaimer-text {
        max-width: 330px;
        margin-top: 0.75rem;
        font-weight: lighter;
    }

    &__tos-button {
        color: #0f1e82;
        margin-top: 24px;
        display: flex;
        flex-direction: row-reverse;

        @include bp-only-phone {
            margin-top: 0;
            margin-bottom: 24px;

        }
    }

    &__finance-text {
        max-width: 240px;
        text-align: center;
        font-weight: lighter;
    }

    &__secondary-input-container {
        display: flex;
        justify-content: center;
    }

    &__edit-address-button {
        color: #875ba3;
        text-decoration: underline;
        cursor: pointer;
    }

    &__title {
        margin: 48px 84px 24px 99px;
        font-family: "Open Sans", Roboto, sans-serif;
        font-size: 45px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: $color-blue-dark;

        @include bp-only-phone {
            margin: 0;
            font-size: 28px;
        }
    }

    &__float-right {
        float: right;
        cursor: pointer;
    }

    &__subtitle {
        font-family: "Open Sans", Roboto, sans-serif;
        font-size: 30px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: $color-blue-dark;
        margin-top: 59px;

        @include bp-only-phone {
            font-size: 16px;
            line-height: 1.5;
        }

        .varme-beregner__tooltip {
            position: relative;
            top: -12px;
            margin-left: 12px;
            z-index: 100;

            svg {
                position: absolute;
                left: 0;
            }
        }
    }

    &__result-title {
        font-family: "Open Sans", Roboto, sans-serif;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: $color-blue-dark;

        @include bp-only-tablet {
            font-size: 20px;
            font-weight: 800;
            line-height: 1.5;
        }

        @include bp-only-phone {
            font-size: 20px;
            font-weight: 800;
            line-height: 1.5;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .h2 {
        font-family: "Open Sans", Roboto, sans-serif;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: $color-blue-dark;
    }

    &__card {
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);
        padding: 50px;
        height: fit-content;
        background-color: $color-white;

        hr {
            background-color: #ede8e6;
            height: 1px;
            border: none;
        }

        &__input-postfix {
            position: absolute;
            font-size: 14px;
            top: 6px;
            right: 5px;
        }

        @include bp-only-phone {
            padding: 24px 16px;
        }


        &__container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            row-gap: 20px;
        }

        .accordion__item--open {
            border-bottom: 0px;
        }

        .highcharts-subtitle text,
        .highcharts-yaxis text {
            color: #07094a;
            fill: #07094a;
            font-weight: 700;
        }

        &__title {
            font-family: "Open Sans", Roboto, sans-serif;
            font-size: 26px;
            font-weight: normal;
            line-height: 1.14;
            color: #000;

            @include bp-only-phone {
                margin-bottom: 20px;
            }
        }

        &__subtitle {
            font-weight: normal;
            line-height: 2;
            margin-top: 36px;
            margin-bottom: 12px;
            max-width: 320px;

            @include bp-only-phone {
                margin-bottom: 12px;
                margin-top: 5px;
            }
        }

        &__number {
            font-family: "Open Sans", Roboto, sans-serif;
            font-weight: 800;
            line-height: 0.8;
            color: #0f1e82;
            padding-right: 10px;
            margin-top: 12px;
            margin-bottom: 16px;
            font-size: 40px;

            @include bp-only-phone {
                padding-right: 5px;
                font-size: 28px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &__suffix {
            font-family: "Open Sans", Roboto, sans-serif;
            line-height: 0.8;
            color: #0f1e82;
            font-weight: normal;


        }

        &__list-item {
            display: flex;
            justify-content: space-between;
        }
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__button.mdc-button {
        width: 100%;
        // border-radius: 50px;
    }

    &__button-container {
        display: flex;
        align-items: center;
        width: 100%;

        @include bp-only-phone {
            width: unset;
        }
    }

    &__link {
        width: 100%;
    }

    &__input-container {
        padding-bottom: 20px; // Correct spacing??
        border-bottom: 1px solid #ede8e6;
    }

    &__error {
        border: 1px solid red;
        color: red;
        border-radius: 5px;
        padding-left: 12px;
        font-size: 10px;

        &--hidden {
            display: none;
        }

        &--shown {
            display: block;
        }
    }

    &__cards-container {
        justify-content: center;
        display: flex;
        margin-bottom: 40px;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;

        @include bp-only-phone {
            margin-bottom: 0
        }

        .varme-beregner__image {
            max-width: 371px;
        }
    }

    &__image {
        position: relative;
        z-index: -1;
        width: 100%;

        @include bp-only-phone {
            z-index: 1;
        }

        &--max-height {
            max-height: 72px;
        }
    }

    &__number-input {
        width: 100%;
        background-color: white;
    }

    &__address-list {
        width: 100%;
        margin-top: 0;
        z-index: 100; 
    }

    &__address-list>div:has(ul) {
        position: absolute;
        top: 100%;
        width: 100%;
        height: fit-content; 
        max-height: 250px;
        overflow-y: auto;
        background-color: white;
        outline: 2px solid #68d2df;
        border-radius: 0px 0px 4px 4px;
        z-index: 100;
    }

    &__tooltip {
        position: absolute;
        right: 0;
        top: -25%;
        z-index: 1000;
    }

    &__accordion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 0;

        &-item {
            border-top: 1px solid #ededed;
            padding: 12px 1px 12px 0;

            @include bp-only-phone {
                padding: 0 0 28px;
                border: 0;
            }
        }

        &-item-card {
            position: relative;
            z-index: 1;
        
            border-radius: 8px;
            -webkit-box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);
            box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);

            .varme-beregner__accordion-item {
                border: 0;
            }
        }

        svg {
            display: block;
            transform: rotate(-90deg);
            width: 30px;
            height: 28px;
            stroke: #595959;
        }

        .active {
            transform: rotate(180deg);
        }

        &--hidden {

            .accordion__item {
                border-top: 0;
                margin-bottom: 28px;
            }

            .varme-beregner__accordion {
                height: 0;
                padding: 0;
                visibility: hidden;
            }
        }
    }

    &__space-between {
        display: flex;
        justify-content: space-between;
    }

    &__vertical-center {
        display: flex;
        align-items: center;
    }

    &__horizontal-center {
        display: flex;
        justify-content: center;
    }

    &__input-small {
        height: 30px !important;
        width: 100px !important;
        margin-bottom: 0;

        @include bp-only-tablet {
            width: 300px !important;
        }
    }

    &__bold {
        font-weight: bold;
    }

    &__mobile-footer-button {
        padding: 30px;
        text-align: center;
    }
}

.varme-beregner-toggle {
    border-radius: 4px;
    border: solid 1px #0f1e82;
    color: #0f1e82;
    margin-bottom: 25px;

    &__container {
        display: flex;
    }

    &__item {
        color: #0f1e82;
        flex: 1 100%;
        min-height: 30px;
    }

    &__tooltip {
        padding-left: 8px;
    }

    .mdc-tab--active {
        background-color: #0f1e82;
        color: white;
    }
}

.varme-beregner-select {
    width: 100%;
    border-bottom: 0;

    &__outlined {
        height: 56px;
        margin-top: 8px !important;
        -webkit-transition: -webkit-box-shadow .15s ease-in-out;
        transition: -webkit-box-shadow .15s ease-in-out;
        transition: box-shadow .15s ease-in-out;
        transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
        border-radius: 4px;
        -webkit-box-shadow: #c9c3ba 0 0 0 1px;
        box-shadow: #c9c3ba 0 0 0 1px;
        overflow: visible;
    }

    &__outlined:hover {
        box-shadow: black 0 0 0 1px;
    }

    &__control {
        padding: 12px;
        font-family: "Open Sans", Roboto, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 400;
        letter-spacing: .00937em;
        text-decoration: inherit;
        text-transform: inherit;
        color: rgba(0, 0, 0, .87);
        width: 100%;
        border: none;
        background-color: transparent;
        white-space: nowrap;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        @include bp-until-desktop {
            padding: 0;
            padding-right: 4px;
        }
    }

    &__focused {
        border-color: transparent !important;
        -webkit-box-shadow: #68d2df 0 0 0 2px !important;
        box-shadow: #68d2df 0 0 0 2px !important;

        .varme-beregner-select__label {
            color: #68d2df !important;
            background-color: inherit;
        }
    }

    &__icon {
        display: block;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        width: 30px;
        height: 28px;
        stroke: #595959;
        position: absolute;
        right: 5px;
        top: 25%;
        z-index: -1;

        .active {
            transform: rotate(180deg);
        }
    }

    &__label {
        background-color: inherit;
        color: rgba(0, 0, 0, .6) !important;
        bottom: 44%;
        padding-left: 6px;
        padding-right: 6px;
        left: 10px;
        width: max-content !important;
        -webkit-transform: translateY(-100%) scale(.75);
        transform: translateY(-100%) scale(.75);
    }



}

.varme-beregner__mobile-back-btn {}

.varme-beregner__mobile-header {
    &-container {
        display: flex;
        align-items: center;
    }

    &-img {
        width: 66px;
        padding-right: 12px;
    }

    .varme-beregner__accordion-item {
        padding: 0 16px 30px;
    }

}