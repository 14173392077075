@import '@material/layout-grid/variables';

/*
max: 1340px
desktop-wide: 1080px
desktop: 840px
tablet: 480px
phone: 0px
*/

//================ PHONE ===================//
@mixin bp-only-phone {
  @media (max-width: map-get($mdc-layout-grid-breakpoints, tablet) - 1px) {
    @content;
  }
}
@mixin bp-above-phone {
  @media (min-width: map-get($mdc-layout-grid-breakpoints, tablet)) {
    @content;
  }
}

//================ TABLET ===================//
@mixin bp-until-tablet {
  @media (max-width: map-get($mdc-layout-grid-breakpoints, tablet) - 1px) {
    @content;
  }
}
@mixin bp-only-tablet {
  @media (min-width: map-get($mdc-layout-grid-breakpoints, tablet)) and (max-width: map-get($mdc-layout-grid-breakpoints, desktop) - 1px) {
    @content;
  }
}
@mixin bp-above-tablet {
  @media (min-width: map-get($mdc-layout-grid-breakpoints, tablet)) {
    @content;
  }
}

//================ DESKTOP ===================//
@mixin bp-until-desktop {
  @media (max-width: map-get($mdc-layout-grid-breakpoints, desktop) - 1px) {
    @content;
  }
}
@mixin bp-only-desktop {
  @media (min-width: map-get($mdc-layout-grid-breakpoints, desktop)) and (max-width: $max-width - 1px) {
    @content;
  }
}
@mixin bp-above-desktop {
  @media (min-width: map-get($mdc-layout-grid-breakpoints, desktop)) {
    @content;
  }
}

//================ DESKTOP WIDE ===================//
@mixin bp-until-desktop-wide {
  @media (max-width: $desktop-wide-width - 1px) {
    @content;
  }
}
@mixin bp-only-desktop-wide {
  @media {
    @content;
  }
}
@mixin bp-above-desktop-wide {
  @media (min-width: #{$desktop-wide-width}) {
    @content;
  }
}

//================ MAX WIDTH ===================//
@mixin bp-until-max {
  @media (max-width: $max-width - 1px) {
    @content;
  }
}
@mixin bp-above-max {
  @media (min-width: $max-width) {
    @content;
  }
}
