.nyk-burger-menu-secondary {
    .search-overlay {
        position: absolute;
        height: 150vh;
        z-index: 400;
    }
}

.nyk-burger-menu-search {
    display: none;
    position: relative;

    &--active {
        display: block;
    }

    .nyk-burger-menu-search__input-container {
        background-color: $color-white;
        border-radius: 25px;
        width: 100%;

        &__hide-after:after {
            background-size: 0 0;
        }

        &:after {
            //spyglass
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg stroke='%2307094A' stroke-width='2'%3E%3Cg%3E%3Cpath d='M20 20L13.656 13.656M16 8c0 2.858-1.525 5.5-4 6.928-2.475 1.43-5.525 1.43-8 0C1.525 13.5 0 10.858 0 8c0-2.858 1.525-5.5 4-6.928 2.475-1.43 5.525-1.43 8 0C14.475 2.5 16 5.142 16 8z' transform='translate(-338 -99) translate(339 100)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            content: '';
            position: absolute;
            width: 23px;
            height: 23px;
            background-repeat: no-repeat;
            left: 20px;
            top: 15px;

            @media (max-width:$tablet-burger-menu-breakpoint) {
                width: 18px;
                height: 18px;
                left: 15px;
                top: 12px;
            }

            @media (max-width:$mobile-burger-menu-breakpoint) {
                left: 35px;
                top: 23px;
            }
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            border-radius: 0;
            padding: 12px 20px 8px 20px;
        }
    }

    .toolbar__search {
        background-color: transparent;
        border-radius: 25px;
        top: 0;
        box-shadow: none;

        @media (max-width:$tablet-burger-menu-breakpoint) {
            height: 50px;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            position: fixed;
            border-radius: 0;
            height: 65px;
        }

        .mdc-layout-grid {
            padding: 0;
            z-index: 1003;
        }

        .mdc-layout-grid__inner {
            display: block;
        }

        .nyk-burger-menu-search__input-container {

            input {
                background-color: transparent;
                border: none;
                
                color: $color-dark;
                font-weight: 400;
                font-size: 16px;
                padding-left: 0;
                height: 50px;

                @media (max-width:$tablet-burger-menu-breakpoint) {
                    padding-left: 45px;
                    height: 40px;
                }

                @media (max-width:$mobile-burger-menu-breakpoint) {
                    background-color: $color-grey-white;
                    border-radius: 25px;
                }
            }
        }

        .toolbar__search-close {
            color: $color-dark;
            padding-right: 12px;
            padding-top: 5px;
            padding-left: 15px;
            z-index: 100;
            stroke-width: 2px;

            .icon--sm{
                stroke-width: 2px;
                height: 28px;
                width: 28px;
            }

            @media (max-width:$tablet-burger-menu-breakpoint) {
                display: none;
            }

            @media (max-width:$mobile-burger-menu-breakpoint) {
                display: block;
                padding-left: 20px;
                padding-right: 5px;
                position: absolute;
                left: 10px;
                top: 13px;
                .icon{
                    display:block;
                }
            }
        }

        .toolbar__search-clear {
            color: $color-grey-medium;
            padding-right: 12px;
            padding-top: 5px;
            padding-left: 15px;
            stroke-width: 2px;

            .icon--sm{
                stroke-width: 2px;
                height: 24px;
                width: 24px;
            }

            @media (max-width:$tablet-burger-menu-breakpoint) {
                display: none;
            }

            @media (max-width:$mobile-burger-menu-breakpoint) {
                display: block;
                padding-left: 20px;
                padding-right: 5px;
                position: absolute;
                right: 24px;
                top: 15px;

            }
        }
    }

    .toolbar__search--fullwidth__result__item__link {
        padding-left: 55px;
        padding-top: 25px;
        padding-bottom: 25px;
        transition: background-color 0.15s ease-in-out;
        border-bottom: none; 

        &:hover {
            background-color: #f7f6f4;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            padding-bottom: 0;
            padding-left: 64px;
            margin-bottom: 25px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
        }
    }

    .toolbar__search--fullwidth__result--expanded {
        position: relative;
        z-index: 1002;
        top: -50px;
        padding-top: 50px;

        .mdc-layout-grid__cell {
            width: 100%;
        }
    }

    .toolbar__search--fullwidth__result__help {
        display: none;
    }

    .toolbar__search--fullwidth__result {
        ul {
            padding-top: 0;
            padding-bottom: 0;
        }
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-radius: 25px;

        .mdc-layout-grid__inner {
            border-top: 1px solid $color-grey-light;
        }

        @media (max-width:$mobile-burger-menu-breakpoint) {
            border-radius: 0;
            height: 100vh;
        }
    }
}

.nyk-burger-menu-secondary__search-bar {
    .nyk-burger-menu-secondary__search-bar-icon-container {
        min-width: unset;
    }
}

.nyk-burger-menu-secondary__main-container {
    .toolbar-primary__nav-button div {
        display: block;
    }
}

.nyk-burger-menu-secondary__quick-links {
    .toolbar-primary__nav-button {
        opacity: 1;
        transform: none;
    }
}
