.fund-table{

    table{
        th {
            background-color: #f5f5f5;
            color: black;
        }
        td{
            padding:8px 16px;
            @include bp-above-desktop {
                padding:0;
            }
            a{
                text-decoration: none;
            }
            &.read-more{
                justify-content: flex-end !important;
                a{
                    text-decoration: underline;
                    &.fund-table__read-more-link{
                        display:none;
                        @include bp-above-desktop {
                            display:block;
                        }
                    }
                    &.fund-list__btn-details{
                        text-decoration: none;
                        padding:0 16px 0 16px;
                        display:flex;
                        @include bp-above-desktop {
                            display:none;
                        }
                    }
                }
            }
        }
    }
    
    &__row-data{
        
        border-bottom:1px solid #ededed;
        &:hover{
            @include bp-above-desktop {
				&:hover {
					@include mdc-elevation(4);
				}
			}
        }
        a{
            display:block;
            @include bp-above-desktop {
                padding:16px;
            }
            
        }
    }
    
    
}