.section--fact-box{
    .mdc-layout-grid{
        @include bp-only-phone  {
            padding:0;
            .mdc-layout-grid__cell:first-child,
            .mdc-layout-grid__cell:last-child{
                padding-left:$gutter*2;
            }
        }
    }
    
    
}
.fact-box{
    background-color:$color-beige;
    padding:$gutter*5;

    @include bp-until-desktop  {
        margin-top:40px;
        padding:$gutter*4;
    }
    .mdc-list-group{
        &:after {
            content: "";
            display: table;
            clear: both;
          }
    }
    &__list{
        padding:0;
        width:50%;
        float:left;
        color:$color-blue-dark;

        &__item{
            margin:5px 0 20px 0;
            padding:0;

            &__icon{
                width:25px;
                height:25px;
                padding-right:$gutter;
                svg.icon{
                    width:25px;
                    height:25px;
                    stroke-width:1px;
                }
            }
            
            &--unavailable{
                opacity:.4;
            }
        }
    }
    
}