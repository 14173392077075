.article__tags {
  margin-bottom: $gutter*4;

  svg {
    color: $color-turquoise;
  }
}
.article-caption {
  width: 100%;
  margin-top: -15px;
}

.font-italic {
  font-style: italic;
}

.article-image-text-padding-and-margin-top {
  padding-top: 12px !important;
  margin-top: 0px;
}

.article-image-text-margin-top {
  margin-top: 4px;
}


.article__share__link{
  margin-top: -4px;
}
.article__tags,
.article__date {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-transform: uppercase;

  span {
    line-height: 1;
    opacity: .4;

    &:not(:first-child) {
      padding-left: $gutter;
      margin-left: $gutter;
      border-left: 1px solid currentColor;
    }
  }
}

.article__share {
  margin-left: auto;
  opacity: .4;

  * {
    pointer-events: none;
  }

  span {
    margin-right: $gutter;
  }
}

.article__share-inner {
  display: flex;
  align-items: center;

  .insights-article &{
    font-weight:400;
  }
}

.article__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-light;
  padding: $gutter*3 0;
  flex-wrap: wrap;

  .insights-article &{
    align-items: flex-start;
    padding:15px 0;
  }
}

.NykreditDk, .Dotcom, .invest {
  .article__share {
    margin-left: auto;
    opacity: .4;
  
    * {
      pointer-events: none;
      stroke-width: 3px;
    }
  
    span {
      margin-right: $gutter;
    }
  }
}