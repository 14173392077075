.fund-list-page{
	/* undgå at footeren vises i toppen ind til Angular er loadet */
	min-height: 100vh;
	.update-frequency-description{
		text-align: right;
		margin-top: 12px;
	}
}
.fund-list{
	.icon--xxs {
		stroke-width: 4px;
		width: 1.6rem;
		height: 1.6rem;
	  }

	.search-box {
		&--large-text {
			font-size: 1.6rem;
		}
	}

	&__spinner{
		margin-top: $gutter*3;
	}
	&__filter{
		@include bp-until-tablet {
			align-items: center;
			flex-direction: column;
			.mdc-button{
				align-self: stretch;
			}
			&__item{
				margin-top: $gutter*2;
				margin-bottom: 0;
				&--first{
					margin-top: 0;
				}
			}
		}
		.mdc-select{
			
			.mdc-select__selected-text{
				margin:0;
				white-space: nowrap;
				overflow:hidden;
				line-height: 4.2rem;
				margin: 1.5rem 0 1rem 0;
				font-size: 2rem;
			}
			select{
				height:100%;
				max-width: unset;
			}
		}
		@include bp-above-tablet{
			.mdc-button{
				height: auto;
				flex: 0 0 auto;
			}
			&__item{
				margin-left: $gutter*2;
				&--first{
					margin-left: 0;
				}
			}
		}
	}
	&__header-link{
		color: inherit;
		text-decoration: inherit;
		position: relative;

		_:-ms-input-placeholder, :root & {
			display:inline-block;
			
		}
		

		&--sort{
			.fund-list__sort-indicator{
				display:none;
				
				&--active{
					display:block;
					bottom:5px;
				}
				&--ascending{
					
				}
				&--descending{
					bottom:6px;
				}
			}
		}

		&:hover{
			opacity:.5;
		}

		& + nyk-tooltip .tooltip{
			margin-left:26px;
		}
	}
	&__sort-indicator{
		display: inline-block;
		border-style: solid;
		border-color: $color-white transparent;
		margin-left: $gutter;
		user-select: none;
		transform: translateY(-50%);
		position:absolute;
		right:-15px;

		&--ascending{
			border-width: 4px 4px 0;
			bottom:1px;
		}
		&--descending{
			border-width: 0 4px 4px;
			bottom:9px;
		}
	}
	&__column-type{
		width: 15%;
	}
	&__column-morningstar-rating,
	&__column-risk,
	&__column-sedol,
	&__column-sedol,
	&__column-esg,
	&__column-wkn,
	&__column-valoren,
	&__column-return,
	&__column-nav{
		width: 11%;
	}
	&__column-checkbox{
		width: 4.5em;
	}
	&__column-name{
		min-width: 28em;
	}
	&__show-more.mdc-button{
		min-width: 0;
	}
	&__row-data{
		&--expandable{
			@include bp-above-desktop {
				cursor: pointer;
			}
		}
		&--collapsed{
			@include bp-above-desktop {
				//@include mdc-elevation-transition;
				
				&:hover {
					@include mdc-elevation(4);
				}
			}
		}
		@include bp-above-desktop {
			border-bottom: 0;
		}
	
	}
	.fund-list__row-data.fund-list__row-data--expandable{
		@include bp-above-desktop {
			border-bottom: 0;
		}
	}
	.fund-list__row-data.fund-list__row-data--expandable.fund-list__row-data--collapsed{
		@include bp-above-desktop {
			border-bottom: 1px solid #ededed;
		}
	}
	.table td{
		padding: $gutter*2;
	}	
	&__fund-name{
		font-weight: 700;
		a {
			text-decoration: none;
			&:focus{
				outline:none;
			}
		}
		nyk-tooltip.nordic-swan{
			a.tooltip{
				position:relative;
				margin-left:5px;

				@include bp-until-desktop {
					margin-left:10px;
				}
			}
			img.icon--nordic-swan{
		
				position: absolute;
				top:-6px;
				right:auto;
					
				@include bp-until-desktop {
					position: absolute;
					right:-5px;
					top:-7px;
				}
			}

		} 
		@include bp-until-desktop {
			font-size: 22px;
			background-color: $color-beige-dark;
			padding-top: $gutter !important; //overwrite table td first-child
			a {
				color: inherit;
			}
			
			&:before{
				display: none;	
			}
		}
	}
	&__nordic-swan-column {
		@media (max-width: 840px) {
			margin-right: 15px;
		}
		nyk-tooltip.nordic-swan{
			a.tooltip{
				position:relative;
				margin-left:0;

				@include bp-until-desktop {
					margin-left:0;
				}
			}
			img.icon--nordic-swan{
		
				position: absolute;
				top:-6px;
				right:-10px;
					
				@include bp-until-desktop {
					position: absolute;
					right:-10px;
					top:-7px;
				}
			}

		}
	}
	&__rating{
		margin-right: 3px;
		display: inline-block;
		svg.icon--solid{
				fill: $mdc-theme-primary;
		}
		&--grey{
				svg.icon--solid{
						fill: $color-grey-lighter;
				}
		}
	}
	&__morningstars{
		white-space: nowrap;
	}
	&__risk-label{
		color: $color-grey-dark;
		margin-right: 3px;
		font-size: 15px;
		&:last-child{
				margin-right: 0;
		}
		&--active{
				color: $mdc-theme-primary;
				font-weight: 800;
		}
	}
	&__profit{
		font-weight: 700;
		&--plus{
				color: $color-green-2;
		}
		&--minus{
				color: $color-red-2;
		}
	}
	.mdc-form-field{
		/* Override our MDC override to get back to default, so the checkboxes will be centered. */
		display: block;
	}
	&__btn-details-container{
		@include bp-until-desktop {
			text-align: right;
		}
		.fund-list__btn-details{
			display: flex;
			margin-top: 20px;
			margin-bottom: 15px;
			@include bp-above-desktop {
				display: none;
			}
		}
	}
	&__row-expandable{
		@include bp-until-desktop {
			display: none;
		}
		&.fund-list__row-expandable td{/*needs to be as specific as the selector that sets the default padding*/
			@include bp-until-desktop {
				&:before{
					content: none;
				}
			}
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	&__row-expandable-content{
		overflow: hidden;
		transition: height 0.25s linear;
		padding-top: $gutter*4;
		padding-bottom: $gutter*4;
		//Correct height handled by angular directive (data-collapse).

		&.collapse{
			display: none;
		}
		&.in.collapse{
			display: block;
		}
	}
	&__toggle-icon{
		@include bp-until-desktop {
			display: none;
		}
		padding-right: 1em;
		svg {
			vertical-align: middle;
			stroke-width: 1px;
			width: 24px;
			height: 24px;
		}
	}
	&__day-change{
		.table tbody tr:not(.tr--no-responsive-headers) &{
			@include bp-until-desktop {
				justify-content: flex-end;
				&::before{
					position: absolute;
					left:$gutter*2;
				}
			}
		}
	}
	&__day-change-icon {
		margin-right: 10px;
		svg {
			vertical-align: middle;
		}
		&--plus{
			color: $color-green-2;
		}
		&--minus{
			color: $color-red-2;
		}
	}
	&__graph-col{
		float: left;
		width: 55%;
	}
	&__details-col{
		float: left;
		width: 45%;
	}
	&__chart-outer{
		width: 100%;
		height: 100%;
		padding-right: $gutter*6;
		@include bp-until-desktop {
			display: none;
		}
	}
	&__chart-inner{
		min-height: 400px;
		&--loading{
			background-color: $color-beige-dark;
		}
	}
	&__chart-scale{
		text-align: right;
		margin-bottom: 25px;
		.mdc-button{
			min-width: 0;
			margin-left: 0;
		}
	}
	
}

.section--fund-list{
	.mdc-layout-grid{
		@include bp-until-desktop {
			padding-left:0;
			padding-right:0;
		}
	}
}

.fund-type {
	font-weight: 700;

	&__wrapper {
		position: relative;
		padding-left: 10px;
	}

	&__label {
		position: relative;
		text-decoration: underline;
		color: #07094a;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
		background-repeat: no-repeat;
		background-position: right;
		padding-right: 20px;
		margin-right: 12px;
	}

	&__selector {
		position: absolute;
		left: 0;
		width: 100%;
		border: none;
		outline: none;
		color: transparent;
		background-color: transparent;

		option {
			font-size: 16px;
			color: #07094a;
		}
	}
}