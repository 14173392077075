.npa-filter-modal {
    height: 100vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    inset: 0;
    z-index: 2000;

    &__top-wrapper {
        display: flex;
        border-bottom: 1px solid #DBDBDB;
    }

    &__clear-filters {
        padding-left: 20px;
        font-size: 14px;
        text-decoration: underline;
    }

    &__title {
        font-weight: 600;
        position: absolute;
        left: 50%;
        top: 25px;
        transform: translate(-50%)
    }

    &__close-modal {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        background-color: #F5F5F5;
    }

    &__show-result-button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 15px 0;
        border-top: 1px solid #DBDBDB;
        background-color: white;
        display: flex;
        justify-content: center;
        z-index: 2;
    }

    &__content-container {
        overflow: auto;
        height: calc(100% - 25px);
        padding-bottom: 100px;
    }

    .nyk-select-custom-input {
        margin: 0;
        min-width: 100%;

        &__content-container {
            position: initial;
            margin-top: -1px;
            padding: 0;
            box-shadow: none;
            overflow-y: auto;

            &--selected {
                max-height: fit-content;
                padding: 5px 20px 15px 20px;
            }
        }

        &--selected {
            border: none;
            padding: 17px 15px;
            border-bottom: 1px solid #DBDBDB;
            font-weight: 600
        }
    }

    .nyk-select-fund-display-type__selector {

        &::before {
                border: 0;
                border-left: 0;
                border-right: 0;
                border-top: 2px solid $color-blue-dark;
                border-bottom: 2px solid $color-blue-dark;
            }
    }

}