.nyk-cell-type-number-range {
    display: flex;
    list-style: none;
    gap: 3px;

    &__number {
        font-size: 16px;
        color: $color-grey-dark;

        &--bold {
            font-weight: 900;
            color: $color-blue;
        }
    }

    &--slider {
        justify-content: space-between;
    }

}