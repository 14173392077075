/* MitID-login
--------------------------------------------------------------------------------*/
$help-box-size: 385px;
$help-icon-size: 180px;
.e-id-login {
  position: relative;
  width: 100%;
  padding-bottom: 120px;

  &__mit-id {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 48px;
    max-width: 404px;
    max-height: 277px;
    margin: 0px;
    color: $color-blue-dark;

    h2 {
      margin-bottom: 12px;
    }
    .e-id-login__mit-id__description {
      flex-grow: 1;
      :first-child {
        margin-top: 0px;
      }

      p {
        line-height: 24px;
      }
    }

    a {
      width: max-content;
    }

    @include bp-until-tablet {
      padding: 30px;
    }
  }

  &__nem-id {
    padding: 0px;
  }

  &__iframe-area {
    height: 450px;
    width: 100%;
    padding: 0;
    @media (min-width: 1023px) {
      //custom breakpoint, helpbox still floating on ipad.
      height: 500px;
      width: calc(100% - #{$help-box-size} + (#{$help-icon-size}/ 2));
      .iframe-wrapper {
        max-width: 85%;
      }
    }
    @include bp-above-desktop-wide {
      height: 600px;
    }
    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &__help-box {
    padding: $gutter * 6;
    width: 100%;

    @media (min-width: 1023px) {
      //custom breakpoint, helpbox still floating on ipad.
      position: absolute;
      height: $help-box-size;
      width: $help-box-size;
      right: 0;
      top: -$gutter * 6;
    }
    &__heading-help {
      margin-bottom: 20px;
    }
    &__heading-netbank {
      margin-top: $gutter * 4;
      margin-bottom: $gutter * 2;
    }

    &__link {
      color: #fff;
      font-weight: 300;
      display: block;
      text-decoration: none;
      margin-bottom: 10px;
    }
    &__link,
    p {
      opacity: 0.7;
    }

    &__richtext {
      @include richtext($color-white);
      a {
        color: $color-blue;
      }
      p {
        line-height: 24px;
      }
      margin-bottom: 32px;
    }
  }
}

.section-login-tabs {
  margin-top: 30px;
  .mdc-tab {
    border-bottom: 2px solid transparent;
    font-weight: 400;
    height: 70px;
    color: $color-black;
  }

  .mdc-tab--active {
    border-bottom: 2px solid $color-red;
  }
}

.netbank-login {
  &--white {
    min-height: 584px;
    border-radius: 16px;
    background-color: white;
    border: solid 1px #dcdcdc;
    @media (max-width: 500px) {
      min-height: unset;
    }
  }

  &--small-grid {
    grid-gap: 8px;
  }
  &__card {
    &__title {
      margin-bottom: 12px;
    }
    h2 {
      margin-top: 0;
    }
    p {
      font-size: 16px;
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 800;
  }

  .mdc-button {
    margin-top: 24px;
  }

  &__language-container {
    margin-left: auto;
    margin-bottom: 12px;
  }

  &__language {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    line-height: 0;
    position: relative;
    padding: 0 $gutter;

    @include bp-above-desktop-wide {
      padding: 0 $gutter * 2;
    }

    * {
      pointer-events: none;
    }

    div {
      line-height: 1;
      margin-top: $gutter;
      text-transform: uppercase;
      min-width: 40px;
      text-align: center;
      margin-bottom: 0;

      @include bp-until-desktop-wide {
        display: none;
      }
    }

    .toolbar__nav-item-lang & {
      div {
        margin-top: 0;
        text-transform: none;
      }
    }

    &:after {
      bottom: -15px;

      @include bp-until-desktop-wide {
        left: 0;
        width: 100%;
      }
    }

    display: inline-block;
    &:first-child {
    }
    &:after {
      content: "";
      position: absolute;
      left: $gutter * 1;
      bottom: -16px;
      width: calc(100% - #{$gutter * 2});
      height: 2px;
      border-bottom: 2px solid $mdc-theme-secondary;
      opacity: 0;
      transform: translateY($gutter/4);
      transition: all 0.2s $mdc-animation-acceleration-curve-timing-function;
      pointer-events: none;
    }

    &:hover,
    &:focus,
    &.is-active {
      &:after {
        opacity: 1;
        transform: none;
      }

      svg {
        opacity: 1;
      }

      @media (max-width: 500px) {
        &.is-active.hide-on-mobile {
          display: none;
        }
      }
    }
  }

  &__card-container {
    height: 50%;
    position: relative;
    max-height: 288px;
    @media (max-width: 500px) {
      max-height: unset;
      height: unset;
    }

    &--dark-blue {
      background-color: #07094a;
      color: white;
    }
    &--blue {
      background-color: #0f1e82;
      color: white;
    }
    &--gray {
      background-color: #EDE8E6;
      color: #07094a;
    }
  }

  &__card {
    padding: 60px 55px 60px 80px;

    @include bp-until-tablet {
      padding: 40px 25px 40px 25px;
    }
  }

  &__accordian {
    padding: 60px 48px 60px 48px;

    @include bp-until-tablet {
      padding: 40px 25px 40px 25px;
    }

    .icon {
      margin-right: 16px;
    }

    &__title {
      margin-top: 0;
    }

    .accordion__item:first-child {
      border-top: none;
    }

    .accordion__item-content-inner {
      padding-bottom: 16px;
    }

    .accordion__item-label {
      padding-top: 6px;
      padding-bottom: 6px;

      &__title {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

.MitNykredit {
  .netbank-login {
    &--white {
      min-height: 584px;
      color: #131313;
      background-color: white;
      border: solid 1px #dcdcdc;
      @media (max-width: 500px) {
        min-height: unset;
      }
    }
  
    &--small-grid {
      grid-gap: 8px;
    }
    &__card {
      &__title {
        margin-bottom: 12px;
      }
      h2 {
        margin-top: 0;
      }
      p {
        font-size: 16px;
      }
    }
  
    h2 {
      font-size: 28px;
      font-weight: 800;
    }
  
    .mdc-button {
      margin-top: 24px;
    }
  
    &__language-container {
      margin-left: auto;
      margin-bottom: 12px;
    }
  
    &__language {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      line-height: 0;
      position: relative;
      padding: 0 $gutter;
  
      @include bp-above-desktop-wide {
        padding: 0 $gutter * 2;
      }
  
      * {
        pointer-events: none;
      }
  
      div {
        line-height: 1;
        margin-top: $gutter;
        text-transform: uppercase;
        min-width: 40px;
        text-align: center;
        margin-bottom: 0;
  
        @include bp-until-desktop-wide {
          display: none;
        }
      }
  
      .toolbar__nav-item-lang & {
        div {
          margin-top: 0;
          text-transform: none;
        }
      }
  
      &:after {
        bottom: -15px;
  
        @include bp-until-desktop-wide {
          left: 0;
          width: 100%;
        }
      }
  
      display: inline-block;
      &:first-child {
      }
      &:after {
        content: "";
        position: absolute;
        left: $gutter * 1;
        bottom: -16px;
        width: calc(100% - #{$gutter * 2});
        height: 2px;
        border-bottom: 2px solid $mdc-theme-secondary;
        opacity: 0;
        transform: translateY($gutter/4);
        transition: all 0.2s $mdc-animation-acceleration-curve-timing-function;
        pointer-events: none;
      }
  
      &:hover,
      &:focus,
      &.is-active {
        &:after {
          opacity: 1;
          transform: none;
        }
  
        svg {
          opacity: 1;
        }
  
        @media (max-width: 500px) {
          &.is-active.hide-on-mobile {
            display: none;
          }
        }
      }
    }
  
    &__card-container {
      height: 50%;
      position: relative;
      max-height: 288px;
      border-radius: 16px;
      @media (max-width: 500px) {
        max-height: unset;
        height: unset;
      }
  
      &--dark-blue {
        background-color: #07094a;
        color: white;
      }
      &--blue {
        background-color: #0f1e82;
        color: white;
      }
      &--gray {
        background-color: #EDE8E6;
        color: #07094a;
      }
    }
  
    &__card {
      padding: 60px 55px 60px 80px;
  
      @include bp-until-tablet {
        padding: 40px 25px 40px 25px;
      }
    }
  
    &__accordian {
      padding: 60px 48px 60px 48px;
  
      @include bp-until-tablet {
        padding: 40px 25px 40px 25px;
      }
  
      .icon {
        margin-right: 16px;
      }
  
      &__title {
        margin-top: 0;
      }
  
      .accordion__item:first-child {
        border-top: none;
      }
  
      .accordion__item-content-inner {
        padding-bottom: 16px;
      }
  
      .accordion__item-label {
        padding-top: 6px;
        padding-bottom: 6px;
  
        &__title {
          font-size: 18px;
          font-weight: 400;
          color: #131313;
        }
      }
    }
  }
}