.card {
  @include mdc-elevation(1);
  position: relative;
  //@include mdc-elevation-transition;
  background-color: $color-white;
  justify-content: flex-start;
  height: 100%;
  min-height: 100%;
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover { 
      box-shadow: 0 10px 15px 6px rgba(45, 45, 45, 0.2);
    }
  }

  .card__media {
    // min-height: 210px;
    position: relative;
    height: 0;
    padding-top: 100% / 16 * 9;
  }

  .card__actions {
    margin-top: auto;
  }

  &__anchor {
    position: absolute;
    top: -120px;
  }
  &__link {
    position: absolute;
    left:0; top:0; bottom:0; right:0;
    z-index: 1;
  }
}

.card__media-inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: $gutter*2;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.56) 0%, transparent 100%);
}

.card--image {
  .card__media {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.card--image,
.card--horizontal {
  &:hover {
    @include mdc-elevation(4);
  }
}

.card__media {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .mdc-card__title,
  .mdc-card__subtitle {
    color: $color-white;
    position: relative;
  }
  .mdc-card__title{
    margin-bottom:0;
  }

  .card__category {
    margin-bottom: $gutter*2;
  }
}

.card__category {
  color: $color-turquoise;
  display: flex;
  align-items: center;
  margin-bottom: $gutter;
  text-transform: uppercase;

  .icon {
    color: $color-turquoise;
    margin-right: $gutter;
  }
  .mdc-typography--body-xxs{
    margin-bottom:0;
  }
}

.card__title {
  padding: $gutter*3;
  .mdc-card__title{
    margin-bottom:0;
    padding-top:8px;
    overflow-wrap: break-word;
    word-wrap:break-word;
    
  }
}

.card__body {
  padding: $gutter $gutter*3 $gutter*3 $gutter*3;
}

.card__actions {
  padding: $gutter*2 $gutter*3;

  .mdc-button {
    margin-left: -$gutter !important;
  }
  .mdc-typography--body-xs,
  .mdc-typography--body-xxs{
    font-family: "NykreditSans-Medium", "Open Sans";
    margin-bottom:0;
  }
}

.card__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

//================ HORIZONTAL ===================//
.card--horizontal {
  .card__content {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
    }
  }

  .card__media {
    @include bp-above-desktop {
      height: 100%;
      padding-top: 0;
    }
  }
}

.card__horizontal-block {
  display: flex;
  flex-direction: column;
  position: relative;

  @include bp-above-desktop {
    flex-direction: row-reverse;

    > div {
      flex: 1 1 50%;
    }
  }

  .mdc-card__media-item {
    margin: 0;
    height: auto;
  }
}

.mdc-card__horizontal-block {
  padding-right: 0;

  > div { }
}

//================ CONTACT ===================//

/*
* Edge:
* .card-contact inherits height:100% from .card, but this results in Edge cutting off the bottom of the .card-contact
* Using height:auto fixes this, but unfortunately that causes a new issue in IE11, hence the hack below
* Consider removing hack and using height:auto when IE11 support is no longer required
*/
@supports (-ms-ime-align: auto) {
  .card--contact {
    height:auto; 
  }
}
//FF displays same behavour as Edge, so we put height:auto on that as well
_:-moz-tree-row(hover), .card--contact {
  height:auto;
}
.card--contact {
  
  .card__media {
    height: 180px;
  }

  .card__primary,
  .card__body {
    padding: $gutter*3 $gutter*3 0;
  }

  .card__primary {
    h2 {
      color: $color-blue-dark;
      text-transform: capitalize;
    }
    .mdc-card__title--large{
      padding-top:8px;
      margin-bottom:0;
    }
    .mdc-card__subtitle{
      margin:0;
    }
  }

  .card__position {
    // margin-top: $gutter/2;
    color: $color-dark;
  }

  .card__body {
    min-height: 85px;
    padding: $gutter*4 $gutter*3;

    p {
      margin: 0;
      text-transform: capitalize;
      a {
        text-transform: none;
      }
    }
  }

  .card__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
    //align-self: flex-end;
  }
}

.card__social {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-bottom: $gutter;
}

.card__social--facebook,
.card__social--twitter,
.card__social--linkedin {
  padding-right: $gutter;
}

.card__social--facebook {
  svg {
    color: $color-facebook;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-facebook, 10%);
    }
  }
}

.card__social--twitter {
  svg {
    color: $color-twitter;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-twitter, 10%);
    }
  }
}

.card__social--linkedin {
  svg {
    color: $color-linkedin;
  }

  &:hover,
  &:focus {
    svg {
      color: darken($color-linkedin, 10%);
    }
  }
}

//================ ICON  ===================//

.card__icon{
  text-align: center;
  & + .card__title{
    padding-top: 0;
  }
}


//================ Navigation  ===================//

$card-transition: all .2s;

.section--nav-blocks{
  @include bp-only-phone {
    border-bottom: solid 2px $color-grey-light;
    .mdc-layout-grid{
      padding-left:0;
      padding-right:0;
    }
  }
}

.card--nav{
  box-shadow:none;
  color:$color-blue-dark;

  &.mdc-card__primary-action{
    background:none;
  }

  

  @include bp-only-phone {

    &:before{
      content:"";
      width:100%;
      border-bottom: solid 1px $color-border-card-nav;
      position: absolute;
      top:-$gutter;
      left:0;
    }
    
  }

  @include bp-above-phone {
    margin-top:$gutter*5;
    text-align: center;
  }

  .mdc-typography--title-xs{
    margin:0;
  }

  &__wrapper{
    text-decoration:none;

    @include bp-only-phone {
      padding-left:$gutter*2;
      padding-right:$gutter*2;
      display: flex;
      align-items: center;
    }
  }

  .card__media {
    background-size:43%;
    transition: $card-transition;
    padding-top:42%;
  }
  
  &__media-wrapper{
    @include bp-only-phone {
      width:20%;

      .card__media {
        padding-top:100%;
        background-size:contain;
      }
    }
  }

  @include bp-above-phone {
    &:hover{
      .card__media{
        background-size:48%; 
      }
      .card--nav__link{
        transform:translate(0,-60px);
      }
    }
  }

  &__title{
    color:$color-blue-dark;
    padding:15px 10px 20px 10px;

    .mdc-card__title{
      color:currentColor;
      padding-top:8px;
    }
    @include bp-only-phone {
      text-align: left;
      width:90%;
      padding:24px;
    }
  }

  &__body{
    display: block;
    line-height: 1.56em;
    padding-top:0;
    padding-bottom:0;
    color:$color-blue-dark;

    @include bp-only-phone {
      display: none;
    }
  }

  &__content{
    min-height: 190px;
    overflow:hidden;

    @include bp-only-phone {
      min-height:0;
      width:80%;
      display:flex;
      align-items: center;
    }
  }
  
  &__link{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size:14px;
    color:$color-blue-dark;
    
    @include bp-only-phone {
      display: none;
    }

    @include bp-above-phone {
      position: relative;
      top: 50px;
      transition: $card-transition;
      padding:20px 0;
      display:block;
    }
  }
  
  &__center {
    text-align: center;
  }

  &__icon{
    width:10%;
    
    svg{
      float:right;
      transform: rotate(-90deg);
      color:$color-blue-dark;
    }

    
    @include bp-above-phone {
      display: none;
    }
  }
}

.mdc-card {
  .card__richtext{
    padding: 0px 25px;
    
    a { 
      z-index: 1;
      position: relative;
    }

    ul {
      li + li {
        margin-top: 5px;
      }
    }
  }
}

.cards__disclaimer{
  margin-top: 0px;
}


.NykreditDk, .Dotcom, .invest, .MitNykredit  {
  .card {
    @include mdc-elevation(1);
    position: relative;
    //@include mdc-elevation-transition;
    background-color: $color-white;
    justify-content: flex-start;
    height: 100%;
    min-height: 100%;
    text-decoration: none;
    cursor: pointer;
  
    @media (hover: hover) and (pointer: fine) {
      &:hover { 
        box-shadow: 0 10px 15px 6px rgba(45, 45, 45, 0.2);
      }
    }

    .card__title {
      padding: $gutter*3;
      .mdc-card__title{
        font-family: "NykreditSans-Medium", "Open Sans";
        margin-bottom:0;
        padding-top:8px;
        overflow-wrap: break-word;
        word-wrap:break-word;
        
      }
    }
  
    .card__media {
      // min-height: 210px;
      position: relative;
      height: 0;
      padding-top: 100% / 16 * 9;
    }
  
    .card__actions {
      margin-top: auto;
    }
  
    &__anchor {
      position: absolute;
      top: -120px;
    }
    &__link {
      position: absolute;
      left:0; top:0; bottom:0; right:0;
      z-index: 1;
    }
  }

  .mdc-card {
    .card__richtext{
      padding: 0px 25px;
      font-family: "NykreditSans-Regular";
      
      a { 
        z-index: 1;
        position: relative;
      }
  
      ul {
        li + li {
          margin-top: 5px;
        }
      }
    }
  }

  .card__media {
    // min-height: 210px;
    position: relative;
    height: 0;
    padding-top: 100% / 16 * 9;
    border-radius: 16px 16px 0 0;
  }

  .mdc-card {
    border-radius: 16px;
  }
  
  .card__actions {
    font-family: "NykreditSans-Regular";;
    color: #07094a !important;
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .mdc-button--link {
      flex-grow: 1;
      justify-content: flex-start;
      text-transform: none;
    }
    .mdc-button-circle {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
    }
    .arrow__button {
      @include fluid(width, 35, 44);
      @include fluid(height, 35, 44);
    }
  }
}