//*THEME*//
$theme-list__item-number-bgcolor: $color-turquoise !default;

.list__item-image {
  position: relative;

  img {
    max-width: 100%;
  }

  @media (max-width: 840px) {
    picture + .list__item-number {
      position: absolute;
    }

    .list__item-number {
      position: static;
    }

  }
}

.list__item-content {
  .title-with-topmargin {
    @media (max-width: 840px) {
    margin-top: 5px;
    }
  }
}

.list__item-number {
  @include fluid(width, 56, 72);
  @include fluid(height, 56, 72);
  background-color: $theme-list__item-number-bgcolor;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  .mdc-typography--title-sm {
    color: $color-white;
    margin-top: 0;
    margin-bottom:0;
  }
}

.NykreditDk, .Dotcom, .invest {
  .list__item-number {
    @include fluid(width, 56, 72);
    @include fluid(height, 56, 72);
    background-color: $theme-list__item-number-bgcolor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 16px 0 0;
  
    .mdc-typography--title-sm {
      color: $color-white;
      margin-top: 0;
      margin-bottom:0;
    }
  }
}

.list-wrap {
  padding-bottom: $gutter*2;

  .mdc-list-item__start-detail {
    margin-right: 12px;
  }
}

.mdc-list__medium-lines {
  li {
    margin-bottom: 22px;
  }
}

.mdc-list-item__link {
  color: $color-turquoise;
}

.NykreditDk, .Dotcom, .invest {
  
.list__item-image {
  position: relative;

  img {
    max-width: 100%;
    border-radius: 16px;
  }

  @media (max-width: 840px) {
    picture + .list__item-number {
      position: absolute;
    }

    .list__item-number {
      position: static;
    }

  }
}
}