﻿.iframe-wrapper {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.iframe-block {
    margin: auto;
    display: block;
}

.consent-placeholder-iframe {
    background-color: $color-grey-light;

    @media (max-width: 640px) {
        padding: 0 5px 0 5px;
    }

}