.quote {
  color: $color-blue-dark;
}

.quote__icon {
  @include fluid(width, 35, 60);
  @include fluid(height, 35, 60);
  margin-bottom: $gutter;
}

.quote__title {
  font-style: italic;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
  margin-bottom: 0;
  word-wrap: break-word;
}

.quote__author {
  opacity: .5;
  margin-top: $gutter*2;
}
