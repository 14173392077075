.archive-result-table {
	table {
		tr {
			td  {
				a {
					text-decoration: none;
				}
			}
		}
	}
}