$breakpoint-mobile: 768px;


.video {
  position: relative;
  //margin: $gutter*4 0;
  padding-bottom: calc((100% / 16) * 9);
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    opacity: 1;
    transition: opacity 0.3s ease;

    &.hide {
      pointer-events: none;
      opacity: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: '';
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
      pointer-events: none;
    }

    &-play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      display: block;
      cursor: pointer;
      overflow: visible;

      svg {
        transition: transform .3s $mdc-animation-standard-curve-timing-function;
      }

      &:hover,
      &:focus {
        svg {
          transform: scale(1.1);
          overflow: visible;
        }
      }
    }
  }
}

.NykreditDk, .Dotcom, .invest {
  .video {
    position: relative;
    //margin: $gutter*4 0;
    padding-bottom: calc((100% / 16) * 9);
    height: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 16px;
  
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 1;
      transition: opacity 0.3s ease;
  
      &.hide {
        pointer-events: none;
        opacity: 0;
      }
  
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
        pointer-events: none;
      }
  
      &-play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        display: block;
        cursor: pointer;
        overflow: visible;
  
        svg {
          transition: transform .3s $mdc-animation-standard-curve-timing-function;
        }
  
        &:hover,
        &:focus {
          svg {
            transform: scale(1.1);
            overflow: visible;
          }
        }
      }
    }
  }
}

.Dotcom {
  .video {
    position: relative;
    //margin: $gutter*4 0;
    padding-bottom: calc((100% / 16) * 9);
    height: 0;
    overflow: hidden;
    border-radius: 16px;

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
    
    .iframe-video {
      height: 100%
    }

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 1;
      transition: opacity 0.3s ease;
  
      &.hide {
        pointer-events: none;
        opacity: 0;
      }
  
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
        pointer-events: none;
      }
  
      &-play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        display: block;
        cursor: pointer;
        overflow: visible;
  
        svg {
          transition: transform .3s $mdc-animation-standard-curve-timing-function;
        }
  
        &:hover,
        &:focus {
          svg {
            transform: scale(1.1);
            overflow: visible;
          }
        }
      }
    }
  }
}

.invest {
  .video {
    position: relative;
    //margin: $gutter*4 0;
    padding-bottom: calc((100% / 16) * 9);
    height: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 16px;
  
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 1;
      transition: opacity 0.3s ease;
  
      &.hide {
        pointer-events: none;
        opacity: 0;
      }
  
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
        pointer-events: none;
      }
  
      &-play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        display: block;
        cursor: pointer;
        overflow: visible;
  
        svg {
          transition: transform .3s $mdc-animation-standard-curve-timing-function;
        }
  
        &:hover,
        &:focus {
          svg {
            transform: scale(1.1);
            overflow: visible;
          }
        }
      }
    }
  }
}

