.text-block {
  background-color: $color-white;
  box-shadow: 0 0 3px rgba(#000, .24);
  padding: $gutter*4;

  @include bp-above-tablet {
    padding: $gutter*5 $gutter*10;
  }

  @include bp-above-desktop {
    padding: $gutter*7 $gutter*20;
  }
}

.text-block__title,
.text-block__link {
  text-align: center;
}

.NykreditDk, .Dotcom, .invest {
  .text-block {
    background-color: $color-white;
    box-shadow: 0 0 3px rgba(#000, .24);
    padding: $gutter*4;
    border-radius: 16px;
  
    @include bp-above-tablet {
      padding: $gutter*5 $gutter*10;
    }
  
    @include bp-above-desktop {
      padding: $gutter*7 $gutter*20;
    }
  }
  
  .text-block__title,
  .text-block__link {
    text-align: center;
  }
}