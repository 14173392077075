//Theme colors
$theme-richtext-color: $color-dark !default;
$theme-richtext-h4-color: $color-blue-dark !default;
$theme-richtext-h2-color: $color-blue-dark !default;
$theme-richtext-a-color: $color-turquoise !default;
$theme-richtext-a-active-color: $color-red !default;
$theme-richtext-li-bullet-color: $color-red !default;

@mixin richtext($theme-richtext-color-param) {
  @extend .mdc-typography--body;
  color: $theme-richtext-color-param;

  h4 {
    color: $theme-richtext-h4-color;
  }

  h2 {
    @extend .mdc-typography--title-sm;
    color: $theme-richtext-h2-color;
  }

  h3 {
    @extend .mdc-typography--subtitle-lg;
  }

  p {
  }

  a:not(.mdc-button) {
    color: $theme-richtext-a-color;
    text-decoration-skip: ink;
    transition: color .2s $mdc-animation-acceleration-curve-timing-function;

    &:hover,
    &:active {
      color: $theme-richtext-a-active-color;
    }
  }

  b,
  strong {
    font-weight: map-get($mdc-typography-font-weight-values, bold);
  }

  i,
  em {
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  ul,
  ol {
    @extend .mdc-list;
    margin-bottom: 1em;
    padding-left:$gutter*2;
    padding-right:$gutter*2;

    > li {
      padding-left: 20px;
    }
  }

  li {
    align-items: flex-start;
    box-sizing: content-box;
    height: auto;
    position: relative;
    padding-left: 10px;

    &:not(:first-child) {
      margin-top: $gutter*3;
    }

    &::before {
      content: "";
      display: inline-block;
      background-color: $theme-richtext-li-bullet-color;
      height: 10px;
      width: 10px;
      position: absolute;
      left: -$gutter*2;
      top: $gutter;
    }
  }
}

.richtext {
  @include richtext($theme-richtext-color);

  p + h3{
    margin-top: $gutter * 4;
  }

  ol {
    counter-reset: itemCount;

    > li::before {
      counter-increment: itemCount;
      content: counter(itemCount);
      width: 25px;
      height: 25px;
    }
  }
  
  .checkmark-list > li {
    padding-left: 10px;
    
    &::before{
      background: url('/globalassets/ikoner/checkmark-icon.png');
      background-position: center;
      background-size: cover;
      height: 20px;
      width: 20px;
      top: 4px;
    }
  }    
}

.richtext__excerpt {
  padding: 16px 32px;
  background-color: rgb(244,244,244)
}

.seo-block, .Totalkredit .seo-block {
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-bottom: 0px;
    line-height: 28px;

    + p {
      margin-top: 8px;
    }
  }
  
  p {
    font-weight: 600;
  }
  
  &__read-more.mdc-button {
    padding: 0px;
    color: #005e84;
  }
}

.richtext {
  ol > li
  {
    padding-left: 35px;
    &::before {
      display: flex;
      justify-content: center;
      color: white;
      font-size: 14px;
      line-height: 10px;
      align-items: center;
      top: 0px;
  }
  }
}


// Exceptions
.article__lead {
  padding-bottom: 0;
  margin-bottom: 0;
}

.grid-no-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.NykreditDk, .Dotcom, .invest {
  .richtext {
    @include richtext($theme-richtext-color);
    font-family: "NykreditSans-Regular", "Open Sans";

    p + h3{
      margin-top: $gutter * 4;
    }
  
    ol {
      counter-reset: itemCount;
  
      > li::before {
        counter-increment: itemCount;
        content: counter(itemCount);
        width: 25px;
        height: 25px;
      }
    }
    
    .checkmark-list > li {
      padding-left: 10px;
      
      &::before{
        background: url('/globalassets/ikoner/checkmark-icon.png');
        background-position: center;
        background-size: cover;
        height: 20px;
        width: 20px;
        top: 4px;
      }
    }    
  }

  .richtext {
    ol > li
    {
      font-family: "NykreditSans-Regular", "Open Sans";
      padding-left: 35px;
      &::before {
        display: flex;
        justify-content: center;
        color: white;
        font-size: 14px;
        line-height: 10px;
        align-items: center;
        top: 0px;
      }
    }

    ul > li {
      font-family: "NykreditSans-Regular", "Open Sans";
    }
  }
}