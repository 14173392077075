$tablet-breakpoint: 1024px;
$mobile-breakpoint: 600px;

.npa-association-overview {
    width: 100%;

    &__loading-spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    h1 {
        text-align: center;
    }

    &__table-wrapper {
        margin-top: 20px;
    }

    &__table-top-details {
        display: flex;
        align-items: center;
    }

    &__total-amount-index-funds {
        font-weight: bold;
        padding-right: 0.35rem;
    }

    &__spacer {
        color: #dbdbdb;
    }

    &__more-table-entries-container {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }

    &__filter-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;
        -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        padding-bottom: 1rem;
        align-items: center;

        &-selected {
            display: flex;
            flex-wrap: wrap;
            gap: 0.55rem;
        }

        &--see-all-filters {
            width: 100%;
            padding-bottom: 1.5rem;
        }
    }

    &__filter-wrapper {
        margin: 0;
    }

    &__filter-tag {
        display: flex;
        background-color: #68D2DF;
        border-radius: 50px;
        width: fit-content;
        align-items: center;
        justify-content: center;
        padding-left: 13px;
        padding-right: 5px;
        height: 30px;
        font-size: 14px;

        p {
            margin: 0;
        }
    }

    &__filter-tag-button {
        margin-left: 5px;
        width: 25px;
        height: 25px;

        &:active,
        &:focus,
        &:focus-visible {
            outline: 2px auto #1e486c;
        }

        &--remove-filters {
            margin: 0;
            text-decoration: underline;
            font-size: 14px;
            width: fit-content;
        }

        &--see-all-filters {
            background-color: $color-blue;
            color: $color-white;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 2px;
            margin: 0 0 0 0;
            letter-spacing: 0.1rem;
            width: fit-content;
            height: fit-content;
        }
    }

    &__search-input {
        width: 100%;
        height: 44px;
        background-color: white;
        border-radius: 50px;
        border: none;
        z-index: 1;
        padding: 0 15px 0 45px;
        font-size: 18px;

        &::placeholder {
            color: #07094a;
        }
    }

    &__search-input-wrapper {
        position: relative;
        max-width: 350px;
        width: 100%;

        &:after {
            position: absolute;
            left: 8px;
            top: 8px;
            z-index: 2;
            content: "";
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIxOF80OTg2KSI+CjxwYXRoIGQ9Ik0xNi4zMTI0IDE2LjMxMjRMMTEuNjg3IDExLjY4NyIgc3Ryb2tlPSIjMDcwOTRBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8cGF0aCBkPSJNNy4zMTI1IDEzLjVDMTAuNzI5OCAxMy41IDEzLjUgMTAuNzI5OCAxMy41IDcuMzEyNUMxMy41IDMuODk1MjQgMTAuNzI5OCAxLjEyNSA3LjMxMjUgMS4xMjVDMy44OTUyNCAxLjEyNSAxLjEyNSAzLjg5NTI0IDEuMTI1IDcuMzEyNUMxLjEyNSAxMC43Mjk4IDMuODk1MjQgMTMuNSA3LjMxMjUgMTMuNVoiIHN0cm9rZT0iIzA3MDk0QSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMThfNDk4NiI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K") no-repeat center center;
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: center;
            height: 1.8rem;
            width: 1.8rem;
        }

        @media (max-width: 768px) {
            margin: 0 50px;
        }
    }

    &__search-input-container {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        @media (max-width: 768px) {
            margin-bottom: 50px;
        }
    }
}

.npa-association-overview-wrapper {
    h1 {
        text-align: center;
    }
}

.npa-association-banner {
    border-radius: 0px !important;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    max-width: 900px;
    max-height: 200px;

    @media (max-width: $mobile-breakpoint) {
      width: 90vw;
    }

    @media (max-width: $tablet-breakpoint) {
        width: inherit;
    }
  }