.nyk-investment-fund-list-card {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: $color-white;
    height: fit-content;

    //fixed widths for card?
    min-width: 335px;
    max-width: 500px;

    &__mobile-toggle-bigcard{
        @media (min-width: 780px) {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        margin-top: 1rem;
        width: 100%;   
    }

    &__title-icon {
        width: 25px;
        height: 25px;
        margin-top: -9px;
    }

    &__cards-wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1.5rem;
        justify-content: center;
        margin-top: 20px;

        @media (max-width: 780px) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }
    }

    &__content-container {
        padding: 0 1.5rem 1rem 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        text-align: center;
        background-color: $color-white;
        position: relative;
    }
    

    &__portfolio-select {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        background-color: $color-grey-light;

        &-text {
            font-weight: 400;
            font-size: 12;
        }
    }

    &__above-title {
        font-weight: 300;
        text-transform: uppercase;
        margin-top: 0.8rem;
        margin-bottom: 0;
        font-size: 16px;
        opacity: 0;

        &-container {
            display: flex;
            align-items: center;
        }
    }

    &__title {
        margin: 0;
        font-size: 20px;

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
    }
    &__below-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-circle {
            min-height: 10rem;
            min-width: 10rem;

            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &-value {
            font-size: 28px;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-subtitle{
                font-size: 12px;
                font-weight: 400;
                color: $color-blue-dark;
            }
        }    
    }

    &__big-card-content {
        width: 100%;
        height: 100%;

        &-list-container{
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &-list-item {
            display: flex;
            gap: 1rem;
            justify-content: space-between;
            text-align: left;

            &-span {
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;

                &-overview {
                    width: 100%;
                }

                & > .nyk-cell-type-eu-sustainability-sfdr {
                    flex-direction: row;
                    gap: 0.2rem;
                   

                    & > .nyk-cell-type-eu-sustainability-sfdr__article{
                        font-size: 16px;
                        font-weight: 600;
                        word-spacing: 0.01rem;
                    }

                    & > .nyk-cell-type-eu-sustainability-sfdr__subtitle {
                        font-size: 16px;

                        & > .nyk-cell-type-eu-sustainability-sfdr__icon {

                            svg {
                                width: 13px;
                                height: 13px;
                
                            } 
                           
                        }
                    }
                }
                
                &-value {
                    font-weight: 400;
                }
            }
        }

    }

    &__button {
        background-color: $color-blue;
        color: $color-white;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        margin-right: 0;
        letter-spacing: 0.1rem;
        text-decoration: none;

        &--white {
            background-color: $color-white;
            color: $color-blue;
        }

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.75rem;
            padding: 0 5rem;
            width: 100%;
        }
    }

    &__footer{

        &-text {
            font-weight: 400;
            margin: 0;
            font-size: 1.6rem;
        }
    }

    &__fund-brief{
        padding-top: 0.7rem;
		height: 9rem;
        font-size: 1.6rem;
        line-height: normal;
	}

}