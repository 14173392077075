@import 'flexloan-beregnere-scss/_nykredit-beregner-loader';

//job page heading float
.float-container {
  border: 3px solid #fff;
  padding-top: 56px;
}

.float-child-back {
  width: 15%;
  float: left;
}

.float-child {
  width: 85%;
  float: left;
}
//job page buttons float
.float-container-button {
  padding-top: 8px;
}

.float-child-button-right {
  width: 50%;
  float: left;
}

.float-child-button-left {
  width: 20%;
  float: left;
}

.jobpostings {
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 40px;
    tr {
      border-bottom: 1px solid #ededed;
    }

    thead td {
      color: #07094a;
      background-color: #ede8e6;
      text-align: left;
      text-transform: uppercase;
      padding: 11px 15px 12px 15px;

      font-family: "Open Sans", Roboto, sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 23px;
      letter-spacing: normal;
    }

    tbody td {
      text-align: left;
      padding: 31px 15px 31px 15px;
      a {
        text-decoration: none;
      }
      border-bottom: 1px;
    }
  }
}

.upload-button {
  display: inline-block;
  position: relative;
  padding-top: 8px;
  margin: 0 0 0 0;
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68d2df;
  cursor: pointer;
}

.upload_label {
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(19, 19, 19, 0.5);
}

.filters {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.joblist-button {
  height: 28px;
  margin: 0 8px 0 0;
  padding: 0 11px 0 12px;
  border-radius: 14px;
  background-color: #ede8e6;
  text-align: center;
  color: rgba(19, 19, 19, 0.6);
}

.selected {
  background-color: #0f1e82;
  color: #ffffff;
}

.job-validation-error {
  @include mdc-typography(body-xxxs);
  color: red;
  margin: 0;
  line-height: 1rem;
  width: 100%;
}

.job-application-spinner{
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  &--hide{
    display: none;
  }
}

.job-application-spinner-text {
    background-color: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &--error{
      background-color: red;
    }
}

.application__confirmation--success {
  background-color: #79b9a3;
  color: white;
  padding: 10px 20px;
  margin-bottom: 60px;
  background-color: #79b9a3;
  color: #fff;
}

.job-hero-image {
  img {
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
.totalkredit-orange {
  color: white;
  background-color: #ff8300;
}
.forenetkredit-color {
  color: white;
  background-color: #500778;
}
