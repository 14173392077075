.rr-selector-bar {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4rem;
    background-color: $color-blue-dark;


    @media screen and (max-width: 760px) {
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        gap: 1rem;
        padding: 1rem;
    }

    &__select {
        padding: 0.5rem 1rem;
        min-width: 10rem;
        border-radius: 4px;

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            color: $color-white;

            @media screen and (max-width: 760px) {
                width: 100%;
            }
        }

        &-option {
            color: $color-blue-dark;

            &--disabled {
                background-color: $color-beige-dark;
                color: $color-grey-dark;
            }
        }

    }
}

.Sparinvest {
    .rr-selector-bar {

        background-color: $color-teal;
        &__select {
            &-wrapper {
                color: $color-blue-dark;
            }
        }
    }
}