.nk-button {
    height: 100%;
    padding: 13px 24px;
    border-radius: 24px;
    background-color: $color-blue;
    font-size: 16px;
    letter-spacing: 1.07px;
    text-align: center;
    color: $color-white;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    min-height: 44px;

    &__spinner-is-active {
        padding: 0px 24px;
        min-width: 116px;
    }

    &:hover {
        background-color: $color-blue-dark;
    }

    @include bp-only-phone {
        width: 100%;
    }

    .tk-loader {
        height: 24px;
    }
}


.nk-link {
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;
    color: $color-blue-dark;
    text-decoration: underline;
    text-transform: uppercase;
}


.nk-sticky-floating-button {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    text-align: center;
    background: #0f1e82;
    position: fixed;
    right: calc(40vw - 35vw);
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    z-index: 100;

    @media (max-width:1400px) {
        display: none !important;
    }

}