.nav-onpage {
    background-color: $color-bg-nav-onpage-primary;
    border-bottom: $border-nav-onpage;
    border-top: $border-nav-onpage;
    min-height: 67px;

    .mdc-toolbar {
        max-width: 1340px;
        margin: 0 auto;
        background-color: $color-bg-nav-onpage-primary;
    }

    &.sticky-element {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    &.fixed-element {
        border-top: none;
        //box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        @include bp-until-desktop-wide {
            top: 58px;
        }

        .nav-onpage__section {
            padding: $gutter * 2 $gutter * 2;
        }
    }

    &__row {
        padding: 0;
        background-color: $color-bg-nav-onpage-primary;

        &.fixed-element {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            border-bottom: $border-nav-onpage;

            @include bp-until-desktop-wide {
                .fixed-element {
                    top: 58px;
                }
            }
        }

        &--top {
            top: 0;
        }
    }

    &__section {
        padding: $gutter * 3 $gutter * 2;
        overflow-x: auto;
        margin: 0 auto;

        &--overflowing {
            justify-content: left;
        }

        @include bp-until-desktop {
            justify-content: left;
        }
    }

    &__link {
        color: $color-text-nav-onpage-primary;
        text-decoration: none;
        font-size: 14px;
        padding: 0 20px;
        white-space: nowrap;

        &:first-child {
            padding-left: 0;
        }

        @include bp-above-desktop {
        }
        &--active {
            font-weight: bold;
        }
    }

    &--npa {
        .nav-onpage__link {
            color: initial!important;
        }

        .nav-onpage__link--active {
            color: currentcolor!important;
        }

        .nav-onpage__section {
            color: #07094a;
        }
    }

    &__as-mobile-dropdown {

        .nav-onpage__row--as-mobile-dropdown {
            display: none;
            
            @include bp-until-desktop {
                display: block;
            }
        }

        .nyk-select-text {
            display: none;
            a {
                width: 100%;
                display: block;
            }
            
            @include bp-until-desktop {
                display: block;
            }
        }

        @include bp-until-desktop {
            min-height: 0;

            .nyk-select-text {
                color: initial; 
                margin: 0;
                max-width: 100%;
            }
    
            .js-dropdown-as-sub-nav {
                width: 100%;
            }
    
            .nav-onpage__row {
                min-height: auto;
            }
    
            .nyk-select-text__select-button {
                height: 62px;
                text-transform: uppercase;
    
                p {
                    font-weight: bold;
                }
            }
    
            .nyk-select-text__content {
                top: 61px;
            }
        }

        .nav-onpage__section {
            display: flex;

            @include bp-until-desktop {
                display: none;
            }
        }
    }
}



.NykreditDk, .Dotcom, .invest {
    .nav-onpage {
        background-color: $color-bg-nav-onpage-primary;
        border-bottom: $border-nav-onpage;
        border-top: $border-nav-onpage;
        min-height: 67px;
    
        .mdc-toolbar {
            max-width: 1340px;
            margin: 0 auto;
            background-color: $color-bg-nav-onpage-primary;
        }
    
        &.sticky-element {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    
        &.fixed-element {
            border-top: none;
            //box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            @include bp-until-desktop-wide {
                top: 58px;
            }
    
            .nav-onpage__section {
                padding: $gutter * 2 $gutter * 2;
            }
        }
    
        &__row {
            padding: 0;
            background-color: $color-bg-nav-onpage-primary;
    
            &.fixed-element {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2;
                border-bottom: $border-nav-onpage;
    
                @include bp-until-desktop-wide {
                    .fixed-element {
                        top: 58px;
                    }
                }
            }
    
            &--top {
                top: 0;
            }
        }
    
        &__section {
            padding: $gutter * 3 $gutter * 2;
            overflow-x: auto;
            margin: 0 auto;
            background-color: #f7f6f5;
    
            &--overflowing {
                justify-content: left;
            }
    
            @include bp-until-desktop {
                justify-content: left;
            }
        }
    
        &__link {
            color: $color-text-nav-onpage-primary;
            text-decoration: none;
            font-size: 14px;
            padding: 0 20px;
            white-space: nowrap;
    
            &:first-child {
                padding-left: 0;
            }
    
            @include bp-above-desktop {
            }
            &--active {
                font-weight: bold;
            }
        }
    
        &--npa {
            .nav-onpage__link {
                color: initial!important;
            }
    
            .nav-onpage__link--active {
                color: currentcolor!important;
            }
    
            .nav-onpage__section {
                color: #07094a;
            }
        }
    
        &__as-mobile-dropdown {
    
            .nav-onpage__row--as-mobile-dropdown {
                display: none;
                
                @include bp-until-desktop {
                    display: block;
                }
            }
    
            .nyk-select-text {
                display: none;
                a {
                    width: 100%;
                    display: block;
                }
                
                @include bp-until-desktop {
                    display: block;
                }
            }
    
            @include bp-until-desktop {
                min-height: 0;
    
                .nyk-select-text {
                    color: initial; 
                    margin: 0;
                    max-width: 100%;
                }
        
                .js-dropdown-as-sub-nav {
                    width: 100%;
                }
        
                .nav-onpage__row {
                    min-height: auto;
                }
        
                .nyk-select-text__select-button {
                    height: 62px;
                    text-transform: uppercase;
        
                    p {
                        font-weight: bold;
                    }
                }
        
                .nyk-select-text__content {
                    top: 61px;
                }
            }
    
            .nav-onpage__section {
                display: flex;
    
                @include bp-until-desktop {
                    display: none;
                }
            }
        }
    }
}

