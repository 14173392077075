//*THEME*//

$theme-image__caption-bg-color: $color-turquoise !default;

.image {
  position: relative;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
}

.image--centered {
  img {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

.image__caption {
  background-color: $theme-image__caption-bg-color;
  color: $color-white;
  //display: flex;
  font-size: 100%;
  font-style: normal;
  //justify-content: space-between;
  //flex-direction: column;
  //align-items: flex-start;
  padding: $gutter*4;

  p {
    margin: 0;
  }

  @include bp-above-tablet {
    position: absolute;
    right: 0;

    width: 340px;
    height: 340px;
  }
}

.image--with-caption {
  @include bp-above-tablet {
    img {
      padding-right: $gutter*11;
    }
  }
}

.image--caption-top {
  @include bp-above-tablet {
    margin-top: $gutter*11;

    .image__caption {
      top: -$gutter*11;
    }
  }
}

.image--caption-bottom {
  @include bp-above-tablet {
    margin-bottom: $gutter*11;

    .image__caption {
      bottom: -$gutter*11;
    }
  }
}

.NykreditDk, .Dotcom, .invest {

  .icon {
    border-radius: 0;
  }

  .image {
    position: relative;
  
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      border-radius: 16px;
    }
  }
  
  .image--rounded {
    img {
      border-radius: 16px;
    }
  }
}