.find-contact-grid {
    padding: 0;

    .find-contact-grid-inner {
        grid-gap: 0;

        .find-contact-top {
            .find-contact-top-image {
                width: 100%;
                height: 250px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position-y: bottom;
            }
            .find-contact-top-card {
                text-align: center;
                font-size: 14px;
                font-weight: 800;
                text-transform: uppercase;
                letter-spacing: 0.91px;
                color: $color-blue-dark;
                margin-bottom: 32px;

                .find-contact-top-card-back {
                    position: absolute;
                    left: 50%;
                    margin-left: -140px;
                    background-color: white;
                    margin-top: -40px;
                    height: 40px;
                    width: 280px;
                }
            }
        }
    }
}

.find-centers-title {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 800;
    color: $color-blue-dark;
}

.find-map-and-list {
    display: flex;
    height: 520px;

    .find-map-and-list-map {
        width: 100%;
        height: 100%;
    }
}

.find-centers-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: #ece9e6 1px;
    border-right: 0;
    border-style: solid;

    scrollbar-track-color: $color-beige;
    scrollbar-base-color: $color-blue;
    scrollbar-face-color: $color-blue-dark;
    scrollbar-highlight-color: $color-blue-dark;

    .find-centers-list__entry {
        width: 100%;
        padding: 19px 36px;
        border: 0;
        border-bottom: #ece9e6 1px;
        border-style: solid;
        display: block;
        text-decoration: none;
        transition: box-shadow ease-in-out 0.1s;

        .find-centers-list__entry__title {
            color: $color-blue-dark;
            font-size: 18px;
            font-weight: 800;
            letter-spacing: 0.75px;
            margin: 0;
            padding: 0;
            word-break: break-word;
            white-space: normal;
        }

        .find-centers-list__entry__text {
            color: $color-grey-dark;
            font-size: 14px;
            margin: 0;
            padding: 0;
            word-break: break-word;
        }
    }

    .find-centers-list__entry-highlight {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12);
    }

    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }

    &::-webkit-scrollbar-track {
        background: $color-beige;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-blue;
        border-radius: 3px;

        &:hover {
            background: $color-blue-dark;
        }
    }
}
.find-centers-list-closed {
    width: 0%;
    min-width: 0;
}

.find-centers-list-open {
    width: 40%;
    min-width: 300px;
}

.find-centers-map-title {
    text-align: center;
    color: $mdc-theme-primary;
}
.find-centers-map-search {
    width: 100%;
    height: 100%;
    background-color: white;
    color: #212121;
    padding: 18px 64px 18px 32px;
    box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.04);
    border: 0;

    &__icon {
        position: absolute;
        top: 15px;
        display: inline-block;
        right: 20px;
    }
}

.find-centers-contactpersons-grid-container {
    grid-gap: 26px;
}

.find-centers-filter-select-container {
    overflow: visible;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 0;
    box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.04);
}

.find-centers-grid {
    padding: 0;

    .find-centers-grid__inner {
        margin-bottom: 5%;

        .find-centers-grid_inner__cell {
            height: 60px;
        }
    }
}

.find-information-grid {
    padding: 0;
    margin: 96px auto;

    .find-information-grid-inner {
        grid-gap: 0;

        .find-information-grid-inner-cell {
            padding: 48px 48px 12px 48px;
            margin: 0;

            .find-information-title {
                color: $color-blue-dark;
                margin-bottom: 12px;
            }

            .find-information-address {
                margin-bottom: 12px;

                .find-information-address-text {
                    color: #212121;
                    font-size: 16px;
                    margin-bottom: 4px;
                }
            }

            .find-information-meeting {
                margin-bottom: 32px;
            }

            .find-information-phone {
                margin-bottom: 32px;
                display: flex;

                .find-information-phone-left {
                    margin-right: 16px;
                }

                .find-information-phone-text {
                    color: #212121;
                    font-size: 16px;
                    margin-bottom: 4px;
                }
            }

            .find-information-subtitle {
                color: $color-blue-dark;
                font-size: 20px;
                margin-bottom: 12px;
                line-height: 24px;
                font-weight: 800;
            }

            .find-information-openingperiods {
                display: flex;

                .find-information-openingperiods-left {
                    margin-right: 16px;
                }

                .find-information-openingperiods-text {
                    color: #212121;
                    font-size: 16px;
                    margin-bottom: 4px;
                }
            }

            .find-information-category {
                display: flex;
                font-size: 16px;

                .find-information-category-name {
                    margin-left: 12px;
                    text-align: left;
                }

                .find-information-category-name-active {
                    color: $color-blue-dark;
                }

                .find-information-category-name-inactive {
                    color: $color-blue-dark;
                }

                .highlighted {
                    font-weight: bold;
                }

                .deactivatedPerm {
                    color: $color-dark;
                    opacity: 0.4;
                }
            }
        }

        .find-information-grid-inner-coloredcell {
            background-color: $color-beige;
        }
    }
}

.find-contactpersons-outergrid {
    padding: 0px 0px 20px 0px;

    .find-contactpersons-outergrid-inner {
        grid-gap: 0;
    }
}

.find-contactpersons-grid {
    padding: 0;

    .find-contactpersons-grid-inner {
        .find-contactpersons-card {
            padding: 0;
            -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
            border-radius: 16px;

            .find-contactpersons-card-image {
                width: 100%;
                height: auto;
                object-position: 50% 25%;
                object-fit: cover;
            }

            .findcontactpersons-card-info {
                min-height: 240px;
                padding: 8px 18px 60px 18px;

                .find-contactpersons-card-info-title {
                    color: $color-turquoise;
                    text-transform: uppercase;
                    letter-spacing: 0.3px;
                    font-size: 16px;
                    margin-bottom: 0;
                    line-height: 24px;
                    font-weight: 400;
                }

                .find-contactpersons-card-info-name {
                    color: $color-blue-dark;
                    font-size: 20px;
                    font-weight: 800;
                    margin: 8px 0px 12px 0px;
                }

                .find-contactpersons-card-info-phone {
                    color: #333333;
                    font-size: 16px;
                    line-height: 1.75;
                }

                .find-contactpersons-card-info-mailbutton {
                    color: #333333;
                    font-size: 14px;
                    line-height: 1.3;
                    text-transform: uppercase;
                    font-weight: 800;
                    text-decoration: none;
                    letter-spacing: 1.5px;

                    position: absolute;
                    bottom: 32px;
                }
            }
        }
    }
}

.find-contactpersons-title {
    color: $color-blue-dark;
    text-align: center;
    margin-bottom: 48px;
}

.mdc-typography--title-lg.find-contactpersons-subtitle {
    color: $color-blue-dark;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 32px;
}

@include bp-until-desktop {
    .find-map-and-list {
        height: auto;
        flex-direction: column;

        .find-map-and-list-map {
            height: 400px;
        }

        .find-centers-list {
            order: 1;
            width: 100%;
            border-right: #ece9e6 1px;
        }
    }
}

@include bp-above-phone {
    .show__phone {
        display: none;
    }

    .find-centers-list {
        transition: width 1s, min-width 1s;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .find-contactpersons-grid {
        .find-contactpersons-grid-inner {
            grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
        }
    }
}

@include bp-only-phone {
    .find-centers-list {
        width: 100%;
    }

    .hide__phone {
        display: none;
    }

    .find-information-grid {
        margin: 0;
        margin-top: 60px;

        .find-information-grid-inner {
            padding: 0px 32px;

            .find-information-grid-inner-cell {
                padding: 0;
                margin-bottom: 60px;
            }

            .find-information-grid-inner-coloredcell {
                padding: 32px;
            }
        }
    }

    .find-contactpersons-outergrid {
        padding: 0 32px;
    }

    .find-contact-grid {
        .find-contact-grid-inner {
            .find-contact-grid-inner-intro {
                padding: 0px 32px;
            }
        }
    }

    .find-contactpersons-title {
        text-align: center;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .find-centers-grid {
        height: auto;

        .find-centers-grid__inner {
            padding: 0px 16px;
        }

        .find-centers-title {
            padding: 0px 16px;
            margin-bottom: 24px;
        }
    }

    .find-contactpersons-grid {
        .find-contactpersons-grid-inner {
            .find-contactpersons-card {
                .find-contactpersons-card-image {
                    height: 80vw;
                }

                .findcontactpersons-card-info {
                    .find-contactpersons-card-info-title {
                        font-size: 12px;
                    }

                    .find-contactpersons-card-info-phone {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .nopadding__phone {
        padding: 0 !important;
    }

    .onlybottompadding__phone {
        padding: 0 0 16px 0 !important;
    }
}

/* ---------- FILTERSELECT ---------- */

.filter-select {
    width: 100%;
    height: 100%;
    background-color: $color-white;
    border: 1px solid $color-grey-lighter;
    border-radius: $corner-radius;
    flex: 1 1 auto;
    position: relative;
    border: 0;
}

.filter-select__toggle {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: $gutter * 2;
    position: relative;
    z-index: 10; //put on top of dd
    background: #fff;

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
}

.filter-select__remove {
    height: 100%;
    position: absolute;
    padding-right: $gutter * 2;
    padding-left: $gutter;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    right: -4px;
    font-size: 12px;
}

.filter-select__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filter-select--with-selection & {
        width: calc(100% - #{$gutter * 5});
    }
}

.filter-select__text {
    overflow: hidden;
}

.filter-select__title,
.filter-select__selction,
.filter-select__noselction {
    line-height: 1.2;
}

.filter-select__title {
    margin: 0; //0 0  $gutter/2 0;
    color: $color-dark;
}

.filter-select__selction {
    color: $color-turquoise;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
}

.filter-select__noselction {
    color: $color-grey-dark;
    margin: 0;
}

.filter-select__menu {
    width: 100%;
    left: 0;
    top: calc(100% - 4px);
    min-width: 0;
    overflow-y: hidden;

    &.mdc-menu--open {
        z-index: 1;
    }

    .mdc-checkbox.mdc-checkbox--disabled + label {
        text-decoration: line-through;
        color: $color-grey-medium;
    }

    ul.mdc-menu__items {
        &.mdc-list {
            padding: $gutter + 1 0;
            max-height: 350px;
        }
    }

    li.mdc-list-item {
        .mdc-form-field {
            z-index: 2;
            width: 100%;
            /* The mdc-menu select handler does not fire the MDCSimpleMenu:selected event when a child element of a menu item is clicked. We work around that by setting pointer-events: none on those child elements. */
            pointer-events: none;

            label {
                cursor: pointer;
                word-break: break-word;
                white-space: pre-line;
            }
        }
    }
}

.filter-select__button {
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 0px;
}

.NykreditDk, .Dotcom, .invest {
    #mapcontainer img {
      border-radius: 0px;
    }

    .find-information-address-text {
        color: #212121;
    }

    .find-information-subtitle {
        font-weight: 500;
        font-family: "NykreditSans-Regular", "Open Sans";
    }

    .find-contactpersons-outergrid {
        overflow: hidden;
        padding-bottom: 32px;
    }
  }