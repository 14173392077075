/* Hero
--------------------------------------------------------------------------------*/

//Colored box
$box-size-dt:100px;
$box-size-m:74px;
//*THEME*//
$theme-hero-background-color: $color-beige !default; 

// modifier for kundekroner
.hero__image.hero__image--tk-kundekroner{
  @include bp-until-desktop {
    margin-top: 0px;
  }
}


.hero {
  background-color: $theme-hero-background-color;
  border-bottom: 1px solid $color-grey-light;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
  overflow: hidden;

  @include bp-above-desktop {
    max-height: 780px;
    height: 60vh;
    justify-content: center;
  }

  > .mdc-layout-grid {
    height: auto;
  }
}

_:-ms-input-placeholder, :root .hero {
  @include bp-above-desktop {
    display:block;
  }
}

.hero__image {
  background-repeat: no-repeat;
  
  @include bp-until-desktop {
    margin-top: 58px;
  }

  @include bp-above-desktop {
    background-position: center center;
  }
}
.hero__tk-background{
  background: #F4BE58;
  @include bp-above-desktop {
    //Value provided by Mikkel Ulrich from TK as part of Kundekroner Kampagne 11-05-2023
    max-width: 632px;
  }
}

.hero__big-hero{
  @include bp-above-desktop {
    //Value provided by Janne Rounborg from TK as part of Kundekroner Kampagne 11-05-2023
    min-height: 600px;
  }
  @include bp-until-desktop {
    margin-top: 0px;
    padding-top: 0px;
  }

}

.hero--default {
  background-image: url('/assets/images/hero-bg.png');

  .hero__image {
    background-size: contain;
    height: 50vw;
    width: 100%;

    @include bp-above-desktop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .hero__content {
    @include bp-above-tablet {
      padding: $gutter*16 0;
    }
  }
}

.hero--half {

  @include bp-above-desktop {
    max-height: 400px;
  }
  
  .hero__image {
    background-size: cover;
    height: 50vw;
    width: 100%;
    
    overflow: hidden;
    position: relative;

    @include bp-above-desktop {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
    }

    .hero__image--tk-kundekroner {
      @media (max-width: 800px) {
        overflow: visible;
        height: auto;
        margin-top: 0px;
      } 
    }

  }
}

.hero--full {
  .hero__image {
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
  }
  .hero__image--shadow {
    &::after {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.56) 0%, transparent 100%);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .hero__content {
    color: $color-white;
  }
}

.hero-illu{
  background-color:transparent;
  color:$color-blue-dark;

  .hero__image {
    background-size: contain;
    background-position: top center;
    height: 100%;
    width:100%;

    &--desktop{
      @include bp-until-tablet {
        display:none;
      }
    }
    &--mobile{
      margin-top:0px;
      @include bp-above-tablet {
        display:none;
      }
    }
    

    @include bp-above-desktop {
     
    }
  }

  .hero__title{
    @include bp-above-desktop {
      margin-top:$gutter*10;
    }
  }
  
}

.hero__content {
  position: relative;
  padding-bottom: $gutter*2;

  @include bp-above-tablet {
    padding: $gutter*4 0;
  }
}

/* Hero box
--------------------------------------------------------------------------------*/
.hero-box {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include bp-above-desktop {
    padding-bottom: 96px + 64px;
    padding-top: 64px;

  }

  .mdc-layout-grid {

    &:after{
      content:"";
      display:table;
      clear:both;
    }

    @include bp-until-desktop {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.hero-box__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;

  @include bp-until-desktop {
    max-height: 350px;

    .hero-box--frontpage &{
      height: 200px;
      border-radius: 16px;
    }
  }

  @include bp-above-desktop {
    max-width: 60vw;
    height: 550px;
    position: absolute;
    right: #{map-get($mdc-layout-grid-default-margin, desktop)};
    top: 70px;
    width: 100%;

    .hero-box--frontpage &{
      height: 470px;
      border-radius: 16px;
    }
  }

  @include bp-above-desktop-wide {
    max-width: 910px;
    .hero-box--frontpage &{
      max-width:903px;
    }
  }
}

.hero-box__inner-content {
  overflow: hidden;
  height: 100%;
}

.hero-box__content {
  background-color: $mdc-theme-primary;
  color: $color-white;
  padding: $gutter*4;
  position: relative;

  .hero-box--frontpage &{
    background-color: $color-blue-dark;
    width:calc(100% - #{$box-size-m});
    padding:$gutter*3;
    float:right;

    @include bp-above-desktop {
      width:390px;
      height: 390px;
      padding:$gutter*4 $gutter*5;
      min-height:0;
      top:calc(90px + #{$box-size-dt});
      left:$box-size-dt;
      float:none;
    }

    &:before{
      content:"";
      position: absolute;
      top:-$box-size-m;
      left:-$box-size-m;
      width:$box-size-m;
      height:$box-size-m;
      background-color:$color-turquoise;

      @include bp-above-desktop {
        top:-$box-size-dt;
        left:-$box-size-dt;
        width:$box-size-dt;
        height:$box-size-dt;
      }
    }

    &--secondary{
      &:before{
        background-color: $mdc-theme-secondary;
      }
    }
  }

  @include bp-above-desktop {
    padding: $gutter*6;
    width: 500px;
    height: 500px;
  }
}

.hero-box__title {
  &[class*='mdc-typography--title'] {
    color: $color-white;
    line-height: 1.3;

    .hero-box--frontpage &{
      line-height: 1.24;
      margin-bottom:25px;
      letter-spacing: 1.2px;
    }
  }

  div {
    display: inline;
    margin: 0;
    padding: 0 $gutter 0 0;
  }

  &--less-margin {
    margin-bottom: 10px;
  }
}

.hero__title,
.hero-box__title {
  .highlight-text {
    color: $color-white;
    background-color: $mdc-theme-secondary;
    box-shadow: $gutter/2 0 0 $mdc-theme-secondary, -$gutter/2 0 0 $mdc-theme-secondary;
    line-height: 1.5;
    position: relative;
    left: $gutter;
    margin-right: $gutter;
    padding: 0 $gutter/2;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-box {
    &__link {
      color: currentColor;
      text-decoration: none;
      text-transform: uppercase;
      position: absolute;
      bottom: $gutter*6;
      position:static;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
      .hero-box--frontpage &{
        
        @include bp-above-desktop {
          font-size: 16px;
        }
      }

      
    }
    &__content {
	  .mdc-typography--subtitle {
      margin-bottom: 48px;
      
      .hero-box--frontpage &{
        line-height:1.78;
        margin-bottom: 30px;
        font-size:16px;
        @include bp-above-desktop {
          font-size: 18px;
        }
      }
	  }
	}
}

/* Hero with subject
--------------------------------------------------------------------------------*/
.hero-topic{

  text-align:center;
  color:$color-blue-dark;

  &__image{
    min-height:40vw;
    width:100%;
    background-size:cover;
    background-position: top center;
    background-repeat:no-repeat;
    

    @include bp-above-desktop {
      min-height: 0;
      height:277px;
      max-width:1100px;
      margin:0 auto;
    }
  }
  &__topic{
    text-transform: uppercase;
    position: relative;

    span{
      padding:$gutter*2 $gutter*5 0 $gutter*5;
      background-color: #fff;
      font-weight:700;
      font-size:12px;
      position: relative;
      top:-65px;
      display: inline-block;
      min-height: 60px;

      @include bp-above-desktop {
        padding:$gutter*5 $gutter*7 0 $gutter*7;
        font-size:14px;
      }

    }
  }
  &__title{
    max-width:649px;
    margin:$gutter auto $gutter*2;
    line-height:normal;
  }

  &__body{
    max-width:873px;
    line-height:1.5;
  }

  .mdc-layout-grid {
    @include bp-until-desktop {
      padding-top: 0;
    }
  }
}

.NykreditDk, .Dotcom, .invest {
  .hero-box__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;

    &--rounded {
      border-radius: 16px;
    }
  
    @include bp-until-desktop {
      max-height: 350px;
  
      .hero-box--frontpage &{
        height: 200px;
        border-radius: 16px;
      }
    }
  
    @include bp-above-desktop {
      max-width: 60vw;
      height: 550px;
      position: absolute;
      right: #{map-get($mdc-layout-grid-default-margin, desktop)};
      top: 70px;
      width: 100%;
  
      .hero-box--frontpage &{
        height: 470px;
        border-radius: 16px;
      }
    }
  
    @include bp-above-desktop-wide {
      max-width: 910px;
      .hero-box--frontpage &{
        max-width:903px;
      }
    }
  }

  .hero-box__inner-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    height: unset;

    &--grid {
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;
    }
  }

  .hero-box__middle-content {
    flex-grow: 1;
    padding: 10px;
    word-wrap: break-word;

    &--less-margin {
      overflow: hidden;
      margin-bottom: 20px;
    }
  }

  .hero-box__content {
    background-color: $mdc-theme-primary;
    color: $color-white;
    padding: $gutter*4;
    position: relative;
    margin-bottom: 10px;
    border-radius: 16px;
    
    &__link-box-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 600px;
    }

    &__link-box-text-wrapper {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__link-box--content {
      color: #fff;
      text-decoration: none;
    }

    &__link-box--button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      background-color: transparent;
      border: none;
    }

    .hero-box--frontpage &{
      background-color: $color-blue-dark;
      width:calc(100% - #{$box-size-m});
      padding:$gutter*3;
      float:right;
  
      @include bp-above-desktop {
        width:390px;
        height: 390px;
        padding:$gutter*4 $gutter*5;
        min-height:0;
        top:calc(90px + #{$box-size-dt});
        left:$box-size-dt;
        float:none;
      }
  
      &:before{
        content:"";
        position: absolute;
        top:-$box-size-m;
        left:-$box-size-m;
        width:$box-size-m;
        height:$box-size-m;
        background-color:$color-turquoise;
        border-radius: 16px;
  
        @include bp-above-desktop {
          top:-$box-size-dt;
          left:-$box-size-dt;
          width:$box-size-dt;
          height:$box-size-dt;
        }
      }
  
      &--secondary{
        &:before{
          background-color: $mdc-theme-secondary;
        }
      }
    }
  
    @include bp-above-desktop {
      padding: $gutter*6;
      width: 500px;
      height: 500px;
    }
  }
}