@import "components/beregnere-tooltip";

$desktop-bond-info-breakpoint : 1440px;
$tablet-bond-info-breakpoint : 1080px;
$mobile-bond-info-breakpoint : 720px;


.nyk-generic-table {
    width: 100%;
    border-collapse: collapse; 
    margin: 20px 0;
    position: relative;

    &__favorite-button {
        color: #68d2df;
    }

    &__tooltip {
        margin-left: 15px;
    }

    &__spacer {
        position: absolute;
        height: 44px;
        background-color: $color-beige-dark;
        width: 100%;
    }

    CAPTION {
        position: absolute;
        left: -999999px;
        z-index: -1;
    }

    &__caption, & TH button {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    TH {
        text-align: center;

        .tk-tooltip {
            cursor: pointer;
        }

        &:first-child .nyk-generic-table__cell-sort-button {
            display: none;
        }
    }

    TR {

        border-bottom: 1px solid $color-grey-light;

        @media screen and (max-width: $mobile-bond-info-breakpoint) {
            border-bottom: none;
        }

        TD:nth-child(2),
        TH:nth-child(2) {
            text-align: left;
        }

        TD:first-child {
            width: 40px;
            padding: 25px 0 25px 15px;
        }

        TD:nth-child(2) {
            a {
                font-weight: bold;
                text-decoration: none;
                color: inherit;
            }
        }
    }

    TR:nth-child(2) TD {
        padding-top: 70px;
    }

    &__cell-sort-arrow-container {
        width: 20px;
        height: 17px;
        display: inline-block;
        position: absolute;
        right: 0;

        .fund-list__sort-indicator {
            right: 0;
        }
    }

    &__cell-sort-button {
        position: relative;
        padding-right: 17px;

        span {
            border-color: $color-blue-dark transparent;
        }
    }

    &__cell { 
        padding: 25px 15px;
        font-size: 14px;
        text-align: center;

        @media screen and (max-width: $mobile-bond-info-breakpoint) {
            height: fit-content;
            display: flex;
            justify-content: space-between;
            text-align: left;
            padding: 10px 20px;
        }

        &--header {
            padding-top: 10px;
            padding-bottom: 15px;
            vertical-align: top;
            text-transform: uppercase;
        }

        &--disabled {
            opacity: 0.4;
        }

        &--hide {
            display: none;
        }

        a {
            color: $color-turquoise;
        }
    }
}