.latest-news {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: $color-white;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid $color-grey-lighter;

  @include bp-above-desktop {
    flex-direction: row;
  }
}

.latest-news__top {
  padding: $gutter*2 $gutter*3;
}
.latest-news__content {
  padding: 0 $gutter*3;
  margin: $gutter*2 0;
}

.latest-news-no-border__content {
  padding: 0 $gutter*3;
  margin: $gutter*2 0;
}

.latest-news__content {
  padding: 0 $gutter*3;
  margin: $gutter*2 0;
}

.latest-news__top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $color-grey-lighter;

  h4 {
    padding-left: $gutter*3;

    &.no-padding-left{
      padding-left: 0px;
    }
  }

  svg {
    color: $mdc-theme-primary;
  }

  @include bp-above-desktop {
    border-bottom: none;
    padding-right: 0;

    h4 {
      padding-right: $gutter*3;
    }

    .latest-news__date {
      white-space: nowrap;
    }
  }
}

.latest-news__content {
  @include bp-above-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
  }
}

.latest-news__date {
  margin: 0;
}

.latest-news__news {
  font-weight: map-get($mdc-typography-font-weight-values, regular);
  margin: 0 0 $gutter 0;

  @include bp-above-desktop {
    margin: 0;
    flex: 1;
  }
}

.news-level-1{

  svg {
    color: $color-warning !important;
  }

  p {
    color: $color-grey-dark;
  }
  .latest-new__learnmore {
    color: $color-dark;
  }

  h4{
    color: $color-grey-dark;
  }
}

.news-level-2{
  background-color: $color-warning;

  svg {
    color: $color-white !important;
  }

  p {
    color: $color-white;
  }

  h4{
    color: $color-white;
  }

  .latest-new__learnmore {
    color: $color-white;
  }
}

.news-level-3{
  background-color: $color-alert;

  svg {
    color: $color-white !important; 
  }

  p {
    color: $color-white;
  }

  h4{
    color: $color-white;
  }
  
  .latest-new__learnmore {
    color: $color-white;
  }
}