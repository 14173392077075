//simple tooltip
.nk-tooltip{
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    height: 16px;
    width: 16px;
    color: #000;
    display: inline-block;
    font-weight: 700;
    top: 3px;

    &--alternative {
        background-color: transparent;
        
        svg {
            height: 16px;
            width: 16px;
            
            @include bp-only-phone {
                height: 22px;
                width: 22px;
                display: inline;
            }
        }
    }

    &:before{
        content: '';
        position: absolute;
        top: -10px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $color-white;
        display: none;
        z-index: 4;
    }
    &:after{
        content: attr(data-tooltip);
        position: absolute;
        bottom: calc(100% + 10px);
        background-color: $color-white;
        color: $color-dark;
        padding: $gutter*3;
        min-width: 280px;
        text-align: left;
        border-radius: 2px;
        left: calc(-45px + 50%);
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        line-height:1.46;
        display: none;
        z-index: 3;
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.24);

        @include bp-only-phone {
            left: calc(-75px + 50%);
        }
    }
    &--open{
        &:after, &:before{
            display: block;
        }
    }
}