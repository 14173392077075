.nyk-cell-type-star-range {
    display: flex;
    list-style: none;
    gap: 4px;

    &--slider {
        justify-content: space-between;
        width: 100%;
    }

    &__star {
        svg {
            width: 19px;
            height: 19px;

            path {
                stroke: $color-grey-lighter;
                fill: $color-grey-lighter;

            }
        }

        &--filled {
            svg {
                width: 19px;
                height: 19px;

                path {
                    fill: $color-blue;
                }
            }

        }
    }

}