

.nyk-select-text {
    position: relative; 
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    margin-left: auto;

    @media (max-width:840px) {
        margin-left: 0;
        margin: 20px 0;
    }
    
    svg {
        fill: currentcolor;
    }

    &__label {
        font-weight: bold;
    }

    &__select-button {
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 16px;
        align-items: center;
        text-align: left;

        p {
            padding-right: 10px;
        }
    }

    &__content {
        display: block;
        box-shadow: 0px 2px 4px 0px #0000002B;
        position: absolute;
        top: 72px;
        background-color: white;
        width: 100%;
        z-index: 2;

        &--hidden {
            display: none;
        }
    }   

    &__list {
        list-style-type: none;
        max-height: 500px;
        overflow-y: scroll;
    }

    &__list-item {
        cursor: pointer;
        padding: 12px;
        color: initial;

        &--selected {
            font-weight: bold;
        }

        &:hover {
            background-color: #ededed;
        }
    }
}