$arrow-svg-color: #948d86;


.no-scroll {
    overflow: hidden;
}

.nyk-burger-menu-aside {
    .toolbar-language__background {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 800;
        background-color: transparent;
        width: 210vh;
        height: 100vh;

        &--active {
            display: block;

            @media (max-width: $tablet-burger-menu-breakpoint) {
                transition: background-color 0.2s ease-in-out;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    .toolbar-language__dropdown-content {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        width: 250px;

        &--active {
            opacity: 1;
            top: 7px;
            z-index: 900;
            right: -20px;

            @media (max-width: $tablet-burger-menu-breakpoint) {
                top: -2px;
                width: 100vw;
                left: -46px;
            }
        }
    }

    .toolbar-language__dropdown-content-close-button {
        height: 66px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        font-size: 18px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding-left: 25px;
            margin-left: unset;
            font-size: 16px;
        }
    }

    .toolbar-language__dropdown-content-wrapper {
        padding: 0;
    }

    .toolbar-language__dropdown-item {
        min-height: 66px;

        a {
            font-size: 18px;
            text-decoration: none;
            color: #07094a;
            display: flex;
            align-items: center;

            @media (max-width: $tablet-burger-menu-breakpoint) {
                font-size: 16px;
            }
        }
    }

    .mdc-typography--body-lg {
        font-size: 16px;
    }

    ul {
        list-style-type: none;
    }

    &__nav-container {
        height: 100%;
    }
    &__nav-container.allow-scroll {
        overflow-y: auto;
    }

    &__background-on-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        transition: z-index 0.0s ease-in;
        transition-delay: 0.25s ;

        &--active {
            z-index: 1001;
            transition-delay: 0s ;
        }

        &__background-shade{
            height: 100%;
            width: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.3);
            transition: opacity 0.25s ease-in-out;

            &--active {
                opacity: 1;
            }

        }
    }

    &__foundation-layer {
        z-index: 0;
        position: fixed;
        top: 0;
        left: -480px;
        width: 100%;
        max-width: 480px;
        height: 100vh;
        background-color: $color-white;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        padding-top: 70px;
        transition: opacity 0s ease-in-out, left 0.25s ease-in-out;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            max-width: 100%;
        }

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding-top: 0;
        }

        &--active {
            z-index: 1005;
            opacity: 1;
            left: 0;
        }

        .nyk-burger-menu-aside__data-aside-nav {
            display: block;
        }
    }

    &__foundation-layer-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        align-items: center;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding: 0 0 25px 45px;
            flex-direction: row-reverse;
            align-items: flex-end;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            border-bottom: 1px solid #eee;
            padding-bottom:  0px;
            margin-bottom: 25px;
        }

        .nyk-burger-menu-secondary__burger-button {
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: $color-grey-light;
            }
        }
    }

    &__foundation-wrapper {
        position: relative;
        top: 7px;

        @media (max-width: $mobile-burger-menu-breakpoint) {
            top: 20px;
        }
    }

    &__level-1-list {
        padding-left: 80px;
        padding-top: 20px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding-left: 45px;
            padding-top: 12px;
        }
    }

    &__level-1-item {
        margin: 28px 0;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            margin: 20px 0;
        }
    }

    &__level-1-link {
        color: $color-blue-dark;
        text-decoration: none;
        font-size: 28px;
        position: relative;
        line-height: 32px;

        @media (max-width: $mobile-burger-menu-breakpoint) {
            font-size: 24px;
        }

        &__arrow{
            width: 25px;
            height: 25px;
            stroke: $arrow-svg-color;
            transform: rotate(180deg);
            float: right;
            margin-right: 45px;
            margin-top: 5px;
            
            @media (max-width: $mobile-burger-menu-breakpoint) {
                margin-right: 10px;
            }
        } 
    }

    .nyk-burger-menu-aside__level-1-item {
        .nyk-burger-menu-aside__level-1-link {
            &--selected {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__level-2-container {
        padding-top: 48px;
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-white;
        z-index: 800;

        &--shown {
            display: block;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding-top: 32px;
        }
    }

    &__level-2-container.allow-scroll {
        overflow-y: auto;
    }

    &__level-2-item,
    &__level-3-item {
        margin: 14px 0;
        padding-right: 36px;

        &-placeholder {
            visibility: hidden;
        }

        &-divider:before
        {
            background-color: #ededed;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: baseline;
            width: 95%; 
        } 
               
        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding-right: 8px;
        }
    }

    &__level-1-link {
        &:after {
            background: $color-red;
            position: absolute;
            transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
            transform: translateY(2px);
            content: "";
            height: 2px;
            bottom: -7px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 100%;
            opacity: 0;
        }

        &:hover {
            &:after {
                transform: none;
                @media (min-width: $tablet-burger-menu-breakpoint) {
                    opacity: 1;
                }
            }
        }
    }

    &__level-2-link,
    &__level-3-link {
        &:after {
            background: $color-red;
            position: absolute;
            transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
            transform: translateY(2px);
            content: "";
            height: 2px;
            bottom: -7px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 100%;
            opacity: 0;
        }

        &:hover {
            &:after {
                transform: none;
                opacity: 1;
                @media (max-width: $mobile-burger-menu-breakpoint) {
                    opacity: 0;
                }
            }
        }

        &__arrow{
            width: 26px;
            height: 26px;
            stroke: $arrow-svg-color;
            transform: rotate(180deg);
            float: right;
            margin-right: 5px;
            margin-top: 5px;
        }
    }

    &__level-2-link {
        text-decoration: none;
        position: relative;
        color: $color-blue-dark;

        &--selected {
            .nyk-burger-menu-aside__level-2-link__arrow{
                stroke: $color-blue-dark;
            }
            
            &:after {
                opacity: 1;
                transform: none;
                @media (max-width: $mobile-burger-menu-breakpoint) {
                    opacity: 0;
                }

            }

            &:before {
                //chevron
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke-width='1.5' fill='none' stroke='%23948d86' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpolyline points='15 6 9 12 15 18' /%3E%3C/svg%3E");
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                left: 334px;
                top: 1px;
            }
        }
    }

    &__level-2-header {
        padding-left: 20px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding-left: 10px;
        }
        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding-left: 0px;

            button {
                margin: 0px 9px 0px 10px;
            }

            span {
                padding-left: 0px;
                font-size: 24px;
            }
        }
    }

    &__level-2-header,
    &__level-3-header {
        display: flex;
        align-items: center;
    }

    &__level-2-icon-list {
        padding: 15px 0 100px 81px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            min-width: 180px;
            &__icon {
                margin-bottom: 5px;
            }
        }
        &__quick-link-text.mdc-typography--body-lg {
            font-size: 18px;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding: 15px 0 100px 63px;
        }
    }

    &__level-2-list {
        padding: 15px 0 100px 81px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding: 15px 0 200px 63px;
        }
        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding: 15px 0 200px 48px;
        }
    }

    &__level-2-title {
        font-size: 28px;
        line-height: 32px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            font-size: 28px;
        }
    }

    &__level-2-return-button,
    &__level-3-return-button {
        height: 26px;
        width: 26px;
        margin: 0px 7px;
        svg {
            stroke:  $color-blue-dark;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            margin: 0 9px 0px 10px
        }
    }

    &__level-3-return-button {
        display: none;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            display: block;
        }
    }

    &__level-3-container {
        padding: 220px 0 100px 60px;
        display: none;
        position: absolute;
        left: 480px;
        top: -150px;
        max-width: 480px;
        width: 100%;
        background-color: $color-white;
        height: 100vh;
        border-left: 1px solid $color-grey-white;
        overflow-y: auto;
        z-index: 1000;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            left: 0;
            max-width: 100%;
            top: 0;
            padding: 60px 0 125px 60px;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding: 35px 0 125px 62px;
        }

        &--shown {
            display: block;
        }
    }

    &__level-2-title,
    &__level-3-title {
        color: $color-blue-dark;
        display: block;
        margin: 0px;
        padding-left: 20px;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            font-size: 24px;
        }

        @media (max-width: $mobile-burger-menu-breakpoint) {
            font-size: 24px;
        }
    }

    &__level-3-title {
        padding-left: 0;
    }

    &__level-3-link {
        text-decoration: none;
        position: relative;
        color: $color-blue-dark;
    }

    &__level-3-list {
        padding: 24px 0 100px 0;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding: 24px 0 200px 0;
            margin-left: -16px;
        }
        @media (max-width: $mobile-burger-menu-breakpoint) {
            padding: 17px 0 200px 0;
            margin-left: -16px;
        }
    }

    &__level-3-header {
        @media (min-width: $tablet-burger-menu-breakpoint) {
            height: 20px;
        }
        @media (max-width: $tablet-burger-menu-breakpoint) {
            padding-left: 0;
            margin-left: -63px;
        }
    }

    &__data-aside-nav {
        display: none;
        position: relative;
        height: 100%;
    }

    &__logo {
        height: 20px;
        width: auto;
        border-radius: 0;

        img {
            height: 100%;
            width: auto;
        }

        @media (max-width: $tablet-burger-menu-breakpoint) {
            height: 16px;
        }
    }

    &__logo-container {
        display: flex;
        justify-content: flex-end;

        @media (max-width: $tablet-burger-menu-breakpoint) {
            justify-content: flex-start;
        }
    }

    &__support-link-container {
        margin: 14px 0;
        width: max-content;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
        }
    }

    &__support-link {
        font-size: 18px;
        padding-left: 17px;
        color: $color-blue-dark;
        margin: 0;
        transition: text-decoration 0.15s ease-in;

        &:hover {
            text-decoration: underline;
        }
    }

    &__support-link-icon-container {
        max-height: 20px;
        max-width: 20px;

        .icon--sm {
            stroke-width: 2px;
            max-height: 20px;
            max-width: 20px;
        }
    }

    &__support-link-list {
        height: 100%;
        max-height: 550px;
        margin-top: 80px;
        padding-left: 81px;

        @media (max-width: $mobile-burger-menu-breakpoint) {
            margin-top: 60px;
            padding-left: 45px;
        }
    }
}
