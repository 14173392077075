
$left:$gutter*6;
$background-color: $color-beige !default; 
$link-color:#bababa;

.search-autocomplete{
    background-color:$background-color;
    text-align: center;
    margin-left:$gutter*2;
    font-size:18px;

    [class*='mdc-typography--title']{
        font-size:34px;
        line-height:normal;
    }
    p{
        font-size:16px;
        line-height: 1.75;
    }

    &__wrapper{
        margin-top:40px; 
        position: relative;
        width: 100%;

        @include bp-above-desktop-wide {
            margin-left: $gutter*2;
        }
        
        &__textfield{
            width:100%;
            padding-left:$left;

            &.mdc-text-field--with-trailing-icon{
                .mdc-text-field__input{
                    padding:8px 0;
                }
                .mdc-text-field__icon{
                    right:0;
                    bottom:2px;
                    cursor:pointer;
                }
            }
        }
    }

    &-form{
        width:100%;
    }
    &-input {
        @include mdc-elevation(1);
        //@include mdc-elevation-transition;
        appearance: none;
        background-color: $color-white;
        border-radius: $corner-radius;
        border: none;
        display: block;
        height: 70px;
        width: 100%;
        padding: 0 $gutter*6 0 $gutter*2;
        outline: none;
      
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      
        @include bp-until-desktop {
          padding-right: 65px;
        }
      
        &::placeholder {
          opacity: 0.5;
        }
        &:-ms-input-placeholder{
            opacity:1;
            color:$color-grey-lighter;
        }
      
        &:hover,
        &:focus {
          @include mdc-elevation(4);
        }
        &::-ms-clear {
            display: none;
        }
        &:focus + button{
            opacity:1;
        }
    }

    &-icon {
        position: absolute;
        top: 50%;
        right: $gutter*3;
        transform: translateY(-50%);
        transition: opacity 0.2s $mdc-animation-acceleration-curve-timing-function;
        opacity: 0.2;
      
        &:hover,
        &:focus,
        &--active {
          opacity: 1;
        }
        .icon--sm{
            width:2rem;
            height:2rem;
        }
    }

    &__result{
        display:none;
        width:100%;
        position: absolute;
        z-index:1;
        top:68px;
        text-align:left;
        background-color:$color-white;
        border-top:solid 1px $color-grey-lighter;        
        @include mdc-elevation(5);        
        
        ul{
            list-style:none;
        }
        &__link{
            display:block;
            color:$link-color;
            padding:10px $gutter*4;
            line-height:1.75;
            text-decoration:none;
            overflow: hidden; 
            text-overflow: ellipsis;
            //white-space: nowrap;

            &--active,
            &:focus,
            &:hover{
                background-color: $background-color;
                outline:0;
            }
           

            @include bp-until-desktop {
                padding:10px $gutter*2;
                white-space: normal;
            }

            em{
                font-weight: normal;
                font-style: normal;
                color:$color-dark;
            }
        }
        &--expanded{
            display: block;
        }
    }

    &__missing-result,
    &__show-more{
        display:block;
        padding:10px $gutter*4;
        @include bp-until-desktop {
            padding:10px $gutter*2;
        }
        &:disabled {
            display: none;
        }
    }
    &__missing-result{
        color:$color-turquoise;
        padding-bottom:20px;

        &--active,
        &:focus,
        &:hover{
            background-color: $background-color;
            outline:0;
        }
        
    }
    &__show-more{
        font-size:14px;
        font-weight:bold;
        text-decoration: none;
        text-align:center;
        text-transform:uppercase;
        background: $color-beige;
        color:$color-dark;
        border-top:solid 1px $color-grey-lighter;    
        padding:20px 0 5px 0;
        width:100%;

        &[disabled],
        &:disabled{
            opacity: .5;
            cursor:default;
        }

        span{
            display:block;
            padding-bottom: 2px;
        }
    }
}
