//================ FILTERS ===================//
.table__filters {
  margin-bottom: $gutter * 2;

  @include bp-only-phone {
    overflow-y: hidden;
    height: 60px;
  }
}

.table__filters--inner {
  display: flex;

  @include bp-only-phone {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 73px;
  }
}

.table__filter {
  position: relative;
  padding: $gutter * 2 $gutter * 3;
  background-color: transparent;
  font-weight: map-get($mdc-typography-font-weight-values, bold);
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s $mdc-animation-standard-curve-timing-function;

  @include bp-only-phone {
    display: inline-block;
  }

  @include bp-until-desktop {
    &:first-child {
      margin-left: $gutter * 2;
    }
  }

  &:after {
    transition: transform 0.3s $mdc-animation-standard-curve-timing-function;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: $mdc-theme-secondary;
    width: 100%;
    transform: scaleX(0);
    z-index: 1;
  }

  &:hover,
  &:focus,
  &.is-active {
    opacity: 1;

    &:after {
      transform: scaleX(1);
    }
  }
}

//================ SORT ===================//
.js-table-sort {
  th[role="columnheader"]:not(.no-sort) {
    cursor: pointer;
  }

  th[role="columnheader"]:not([data-no-sort]):after {
    @include bp-above-desktop {
      content: "";
      display: inline-block;
      border-width: 0 4px 4px;
      border-style: solid;
      border-color: $color-white transparent;
      visibility: hidden;
      margin-left: $gutter;
      user-select: none;
      transform: translateY(-50%);
    }
  }

  th[aria-sort="ascending"]:not([data-no-sort]):after {
    border-bottom: none;
    border-width: 4px 4px 0;
  }

  th[aria-sort]:not([data-no-sort]):after {
    visibility: visible;
  }
}

//================ TABLE ===================//

// Special case if table is within an accordion on small devices
.accordion {
  .table {
    @include bp-until-desktop {
      width: 100%;
    }
  }
}

.table--cash-exchange {
  td {
    img {
      min-width: 20px;
      max-width: 20px;
      @include bp-until-desktop {
        min-width: auto;
        max-width: 100px;
      }
    }
  }
}

.table {
  width: 100%;

  // Full screen width on small devices
  @include bp-until-desktop {
    margin-left: -#{map-get($mdc-layout-grid-default-margin, "tablet")};
    width: 100vw;
  }
  @include bp-only-phone {
    margin-left: 0;
  }

  .fund-table &,
  .fund-list & {
    @include bp-until-desktop {
      margin-left: 0;
      width: 100%;
    }
    @include bp-only-phone {
      margin-left: 0;
    }

    &__current-return {
      th,
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          text-align: right;
        }
      }
    }
  }

  @include bp-only-phone {
    margin-left: -#{map-get($mdc-layout-grid-default-margin, "phone")};
  }
  &--scrollable {
    @include bp-until-desktop {
      overflow-x: scroll;
      box-shadow: inset -7px -5px 15px -10px rgba(0, 0, 0, 0.5);
    }
  }

  &--data {
    @include bp-until-desktop {
      table {
        thead {
          display: table-header-group;
        }
        th {
          text-align: left;
        }
        tbody tr td {
          display: table-cell !important;
          text-align: left;
          &:first-child {
            padding-top: 8px !important;
          }
          &:last-child {
            padding-bottom: 8px !important;
          }

          &[data-title]:not([data-title=""]):before {
            display: none !important;
          }
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    width: 100% !important; // Override width set by EPI server.
  }

  thead {
    font-size: 0.75rem;
    @include bp-until-desktop {
      display: none;
    }

    th {
      text-align: left;
      text-transform: uppercase;
    }
  }

  th {
    border: none;
    font-weight: map-get($mdc-typography-font-weight-values, bold);
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: $gutter $gutter * 2;
  }

  th,
  td {
    position: relative;
    text-align: left;
    @include bp-until-desktop {
      text-align: right;
    }

    &[align="center"] {
      text-align: center;
    }

    &[align="right"] {
      text-align: right;
    }
  }

  td {
    padding: $gutter * 4 $gutter * 2;
  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $color-grey-light;
      }
    }

    th {
      padding: $gutter $gutter * 2;
      background-color: $color-beige-dark;
    }
    td {
      font-size: 1.4rem;
    }
    tr:not(.tr--no-responsive-headers) td {
      @include bp-until-desktop {
        display: flex;
        justify-content: space-between;
        padding: $gutter $gutter * 2;

        &:first-child {
          padding-top: $gutter * 2;
        }
        &:last-child {
          padding-bottom: $gutter * 2;
        }

        &[data-title]:not([data-title=""]):before {
          content: attr(data-title);
          font-weight: map-get($mdc-typography-font-weight-values, bold);
          max-width: 120px;
          margin-right: $gutter * 2;
          word-wrap: break-word;
          flex-shrink: 0; //fix Safari bug (.com NYK0007-572)
          text-align: left;
        }
      }
      &.long-title {
        @include bp-until-desktop {
          &:before {
            max-width: 200px;
          }
        }
      }
      .sfdr-column {
        &__container {
          &__text {
            color: #009a44;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
          }
          &__leaf {
            display: block;
            font-size: 12px;
            color: #80cca1;
            text-align: center;
            white-space: nowrap;

            @include bp-until-desktop {
              padding-left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .opacity {
    opacity: 0.4;
  }
}

.table--boxed,
.table--data {
  thead {
    th {
      background-color: $mdc-theme-primary;
      color: $color-white;
      padding-top: $gutter * 3;
      padding-bottom: $gutter * 3;

      &:first-child {
        border-top-left-radius: $corner-radius;
      }

      &:last-child {
        border-top-right-radius: $corner-radius;
      }
    }
    &:hover {
      + tbody {
        border-top-color: transparent;
      }
    }
  }

  tbody {
    border: solid $color-grey-light;
    border-width: 1px 1px 1px 1px;
  }
}

.table__link {
  @include bp-until-desktop {
    &:before {
      content: "";
    }
  }

  a {
    color: $color-blue-dark;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
  }

  svg {
    flex: 0 0 auto;
    margin-left: $gutter * 2;

    @include bp-above-desktop {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.table--data {
  table.keepsize {
    @include bp-until-desktop {
      width: auto !important;
    }
  }

  tr {
    //@include mdc-elevation-transition;

    &:hover {
      @include mdc-elevation(4);
    }
  }
}

.table--clickable-rows {
  tr {
    cursor: pointer;
    //@include mdc-elevation-transition;

    &:hover {
      @include mdc-elevation(4);
    }
    @include bp-above-desktop {
      td,
      th {
        &:first-child {
          padding-left: $gutter * 4;
        }
        &:last-child {
          padding-right: $gutter * 4;
        }
      }
    }
  }
}

.fake-mobile-thead {
  //adds a thin blue bar for mobile
  @include bp-above-desktop {
    display: none;
  }
  height: 40px;
  background-color: $mdc-theme-primary;
  width: 100%;
}
