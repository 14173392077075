.drawer__header {
  background: url("/assets/images/drawer-header.png") no-repeat;
  background-size: cover;
}

.drawer__toggle {
  position: relative;
  width: calc(100% - 32px);

  svg {
    position: absolute;
    pointer-events: none;
    right: $gutter*2;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .3s $mdc-animation-standard-curve-timing-function;
  }
  
  svg.icon-opened {
	display: none;
  }
  
  svg.icon-closed {
	display: block;
  }

  &.is-active {
	svg.icon-closed {
		display: none;
	}
	svg.icon-opened {
		display: block;
	}
  }
}

.drawer__submenu {
  display: none;
  

  .is-active + & {
    display: block;
	}
	.mdc-list-item{
		padding-left: $gutter*4;
		
	}
}


.mdc-drawer{
	body:not(.Totalkredit) &{
		.mdc-drawer__content{
			.mdc-list-item{
				border-left:solid 2px transparent;
			}
			.mdc-drawer--temporary--selected{
				background:transparent;
				color:$color-blue-dark;
				border-color:$color-red;
				
			}
			> .mdc-list{
				> .mdc-drawer--temporary--selected{
						background: $color-blue-dark;
						color:$color-white;
						border-color:transparent;
				}
			}
		}
	}
}

.mdc-drawer--temporary {
	z-index: 17;
}


.mdc-drawer--temporary.mdc-drawer--open.fixed-drawer{
	--mdc-temporary-drawer-opacity:1 !important;
	.mdc-drawer__drawer{
		transform:none !important;
	}
}

.mdc-drawer--temporary__content {
	.mdc-list {
		&.toolbar-icon_list {
			border-top: 1px solid $color-grey-light;
			margin-top: $gutter*3;
			padding-top: $gutter*3;
			
			.mdc-list-item__icon {
				padding-right: $gutter*3;
				
				svg {
					vertical-align: middle;
				}
			}
		}
		
		.mdc-list-item {
			&.mdc-ripple-upgraded {
				box-sizing: border-box;
			}
		}
	}
}